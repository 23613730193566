import createAsyncReducers from 'utils/create-async-reducers'
import { createSlice } from '@reduxjs/toolkit'
import { IMetricData } from 'services/metric/metric.type'
import { getUserMetrics } from './metric.actions'

interface Metrics {
	userMetrics: IMetricData | null
	metricLoading: boolean
}

const initialState: Metrics = {
	userMetrics: null,
	metricLoading: false,
}

const { reducer, actions } = createSlice({
	name: 'metric',
	initialState,
	reducers: {},
	extraReducers: builder => {
		createAsyncReducers(
			builder,
			getUserMetrics,
			'metricLoading',
			(state, action) => {
				state.userMetrics = action.payload
			}
		)
	},
})

export default reducer

export const metricActions = {
	...actions,
	getUserMetrics,
}
