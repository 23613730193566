import { createAsyncThunk } from '@reduxjs/toolkit'
import userService from 'services/user'

export const getUser = createAsyncThunk('user/:id', userService.getUser)
export const getUserByToken = createAsyncThunk(
	'user/:token',
	userService.getUserByToken
)
export const updateQuestionaireStep = createAsyncThunk(
	'user/update/questionaire-step',
	userService.updateQuestionaireStep
)
