import { gql } from '@apollo/client'
import ApiService from 'services/api'
// Models
import {
	IImage,
	UploadImageMutation,
	UploadFileMutation,
	PurgeFileMutation,
	IFile,
	ImageFields,
	IAttachMessageImage,
	AttachMessageImageFields,
	PurgeFile,
	AttachSignatureFields,
} from 'services/upload-files/upload-files.types'
// GQL
import { IMAGE, ATTACH_IMAGE, FILE } from './upload-files.gpl'
import { Organization } from 'types/api/organization.model'

class UploadFilesService {
	private UPLOAD_IMAGE = gql`
		mutation uploadImage($input: UploadImageInput!){
			uploadImage(input: $input) {
				${IMAGE}
			}
		}
	`
	private UPLOAD_FILE = gql`
		mutation uploadFile($input: UploadFileInput!){
			uploadFile(input: $input) {
				${FILE}
			}
		}
	`

	private PURGE_FILE = gql`
		mutation PurgeFile($input: PurgeFileInput!) {
			purgeFile(input: $input) {
				result
			}
		}
	`

	private ATTACH_MESSAGE_IMAGE = gql`
		mutation attachMessageImage($input: AttachMessageImageInput!){
			attachMessageImage(input: $input) {
				${ATTACH_IMAGE}
			}
		}
	`

	private ATTACH_SIGNATURE = gql`
		mutation attachSignature($input: AttachFinraLetterInput!) {
			attachSignature(input: $input) {
				organization {
					name
				}
			}
		}
	`

	uploadImage = async (fields: UploadImageMutation): Promise<IImage> => {
		return await ApiService.client
			.mutate<{ uploadImage: IImage }, ImageFields>({
				mutation: this.UPLOAD_IMAGE,
				variables: fields.imageFields,
			})
			.then(async res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}

				await this.uploadFileBlob(fields.file, res.data!.uploadImage.url)
				return res.data!.uploadImage
			})
	}

	attachSignature = async (
		fields: AttachSignatureFields
	): Promise<Organization> => {
		return await ApiService.client
			.mutate<{ attachSignature: Organization }, string>({
				mutation: this.ATTACH_SIGNATURE,
				variables: fields.blobId,
			})
			.then(async res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}

				return res.data!.attachSignature
			})
	}

	uploadFile = async (fields: UploadFileMutation): Promise<IFile> => {
		return await ApiService.client
			.mutate<{ uploadFile: IFile }, ImageFields>({
				mutation: this.UPLOAD_FILE,
				variables: fields.imageFields,
			})
			.then(async res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}

				await this.uploadFileBlob(fields.file, res.data!.uploadFile.url)
				return res.data!.uploadFile
			})
	}

	purgeFile = async (fields: PurgeFileMutation): Promise<PurgeFile> => {
		return await ApiService.client
			.mutate<{ purgeFile: PurgeFile }, { input: PurgeFileMutation }>({
				mutation: this.PURGE_FILE,
				variables: { input: fields },
			})
			.then(async res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data!.purgeFile
			})
	}

	private uploadFileBlob = async (file: File, url: string): Promise<number> => {
		const formData = new FormData()
		formData.append('file', file)
		return await fetch(url, {
			method: 'PUT',
			body: formData,
		}).then(res => {
			if (res.status !== 200) {
				throw new Error()
			}
			return res.status
		})
	}

	attachMessageImage = async (
		fields: AttachMessageImageFields
	): Promise<IAttachMessageImage> => {
		return await ApiService.client
			.mutate<
				{ attachMessageImage: IAttachMessageImage },
				AttachMessageImageFields
			>({
				mutation: this.ATTACH_MESSAGE_IMAGE,
				variables: fields,
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data!.attachMessageImage
			})
	}
}

export default new UploadFilesService()
