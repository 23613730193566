import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { SENTRY_DSN } from '@constants/envs'

Sentry.init({
	dsn: SENTRY_DSN,
	integrations: [new Integrations.BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
})

ReactDOM.render(<App />, document.getElementById('root'))
