import { gql } from '@apollo/client'
import ApiService from 'services/api'
// Models
import { SystenNumberResponse, SystemNumberQuery } from './system-number.types'

// GPL
import { SYSTEM_NUMBER, PHONE_NUMBER } from './system-number.gpl'

class SystemNumberService {
	private GET_SYSTEM_NUMBERS = gql`
    query conversations($limit: Int,$page: Int, $order: String) {
		getPhoneNumbers(limit: $limit,page:$page, order: $order){
            ${SYSTEM_NUMBER}
		}
	}
	`
	private GET_PHONE_NUMBERS = gql`
    query getPhoneNumbers($limit: Int, $areaCode: Int!) {
			getPhoneNumbers(limit: $limit, areaCode:$areaCode) {
				${PHONE_NUMBER}
			}
		}
	`

	getSystemNumber = async (
		body: SystemNumberQuery
	): Promise<SystenNumberResponse[]> => {
		return await ApiService.client
			.query<{ getPhoneNumbers: SystenNumberResponse[] }, SystemNumberQuery>({
				query: this.GET_SYSTEM_NUMBERS,
				variables: body,
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.getPhoneNumbers
			})
	}
}

export default new SystemNumberService()
