import { createAsyncThunk } from '@reduxjs/toolkit'

import { CspCampaign } from 'types/api/csp-campaign.modal'
import {
	CspCampaignCreateFields,
	CspCampaignUpdateFields,
	CspCampaignGetFields,
} from 'services/csp-campaign/csp-campaign.types'
import cspCampaignService from 'services/csp-campaign/csp-campaign.service'

export const createCspCampaign = createAsyncThunk<
	CspCampaign,
	CspCampaignCreateFields
>('cspCampaign/create', cspCampaignService.createCspCampaign)

export const getCspCampaign = createAsyncThunk<
	CspCampaign,
	CspCampaignGetFields
>('cspCampaign/get', cspCampaignService.getCspCampaign)

export const updateCspCampaign = createAsyncThunk<
	CspCampaign,
	CspCampaignUpdateFields
>('cspCampaign/update', cspCampaignService.updateCspCampaign)
