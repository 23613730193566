import styled from 'styled-components'

export const MessageMeterGroup = styled.div`
	align-items: center;
	display: flex;
	flex-shrink: 0;
`

export const MessageMeterItemContainer = styled.span`
	align-items: center;
	color: ${({ theme }) => theme.colors.fontSecondary};
	display: flex;
	flex-shrink: 0;
	font-size: 0.75rem;

	svg {
		margin-right: 0.25rem;
		width: 1rem;
	}
`
