import toast from 'cogo-toast'
import { useAppDispatch } from 'store'
import { unwrapResult } from '@reduxjs/toolkit'
import { featureFlagsActions } from 'store/slices/feature-flags'
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

interface IProps {
	children: React.ReactChild
}

type AppContextType = {
	isUnifiedDashboard?: boolean
	refreshFeatureFlags: () => Promise<void>
}

const AppContext = createContext<AppContextType>({
	isUnifiedDashboard: undefined,
	refreshFeatureFlags: async () => {},
})

export default ({ children }: IProps) => {
	const dispatch = useAppDispatch()
	const [isUnifiedDashboard, setIsUnifiedDashboard] = useState<boolean>()

	useEffect(() => {
		dispatch(featureFlagsActions.getFeatureFlags())
			.then(unwrapResult)
			.then(data => {
				const unifiedDashboardFlag = data?.find(flag => {
					return flag?.name === 'marketing_unified_dashboard'
				})
				setIsUnifiedDashboard(unifiedDashboardFlag?.enabled)
			})
			.catch(error => toast.error(error.message))
	}, [dispatch])

	const refreshFeatureFlags = async () => {
		try {
			const result = await dispatch(featureFlagsActions.getFeatureFlags())
			const response = unwrapResult(result)
			const unifiedDashboardFlag = response?.find(flag => {
				return flag?.name === 'marketing_unified_dashboard'
			})
			setIsUnifiedDashboard(unifiedDashboardFlag?.enabled)
			console.info('DEBUG:: ~ refreshFeatureFlags ~ response:', response)
		} catch (error) {
			console.error('DEBUG:: ~ refreshFeatureFlags ~ error:', error)
		}
	}

	const contextValue = useMemo(
		() => ({
			isUnifiedDashboard,
			refreshFeatureFlags,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isUnifiedDashboard]
	)

	return (
		<AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
	)
}

export const useAppContext = () => useContext(AppContext)
