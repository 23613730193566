import { createSlice } from '@reduxjs/toolkit'
import createAsyncReducers from 'utils/create-async-reducers'
import {
	getSubscription,
	createSubscription,
	updateSubscription,
	cancelSubscription,
	getSubscriptionEstimate,
} from './subscription.actions'
import {
	Subscription,
	SubscriptionEstimate,
} from 'types/api/subscription.model'

export interface IState {
	subcription?: Subscription | null
	subscriptionEstimate?: SubscriptionEstimate | null
	loading: boolean
}

const initialState: IState = {
	subcription: null,
	subscriptionEstimate: null,
	loading: false,
}

const { reducer, actions } = createSlice({
	name: 'subcription',
	initialState,
	reducers: {},
	extraReducers: builder => {
		createAsyncReducers(
			builder,
			getSubscription,
			'loading',
			(state, action) => {
				state.subcription = action.payload
			}
		)
		createAsyncReducers(
			builder,
			createSubscription,
			'loading',
			(state, action) => {
				state.subcription = action.payload
			}
		)
		createAsyncReducers(
			builder,
			updateSubscription,
			'loading',
			(state, action) => {
				state.subcription = action.payload
			}
		)
		createAsyncReducers(
			builder,
			cancelSubscription,
			'loading',
			(state, action) => {}
		)
		createAsyncReducers(
			builder,
			getSubscriptionEstimate,
			'loading',
			(state, action) => {
				state.subscriptionEstimate = action.payload
			}
		)
	},
})

export default reducer

export const subcriptionActions = {
	...actions,
	getSubscription,
	createSubscription,
	updateSubscription,
	cancelSubscription,
	getSubscriptionEstimate,
}
