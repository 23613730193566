import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { mainFont } from 'utils/styled'

export const AppMenuItemContainer = styled(Link)<{
	$isActive: boolean
	$disabled: boolean
	$hasChildren?: boolean
	$child?: boolean
	$isSetupFlow?: boolean
}>`
	${mainFont};
	align-items: center;
	border-radius: 34px;
	color: ${({ $isActive, theme }) =>
		$isActive ? theme.colors.accentPrimary : theme.colors.fontSecondary};
	display: flex;
	height: ${({ $isSetupFlow }) => ($isSetupFlow ? 'auto' : '44px')};
	padding: ${({ $isSetupFlow }) => ($isSetupFlow ? '6px' : '8px 10px')};
	white-space: nowrap;
	width: 100%;
	justify-content: space-between;

	&:hover,
	&.active {
		color: ${({ $isSetupFlow, theme }) =>
			$isSetupFlow ? theme.colors.dangerHover : theme.colors.accentPrimary};
	}

	${({ $isActive, theme }) =>
		$isActive &&
		`
		background-color: ${theme.colors.accentBackground};
	`}

	${({ $disabled, theme }) =>
		$disabled &&
		`
		color: ${theme.colors.disabled};
		cursor: default;
		pointer-events: none;
		&:hover,
		&:active {
			color: ${theme.colors.disabled};
		}
	`}

	${({ $hasChildren }) =>
		$hasChildren &&
		`
		margin-bottom: 5px;
	`}
	
	${({ $child }) =>
		$child &&
		`
		height: 2.125rem;
	`}
	 ${({ $isSetupFlow, $isActive, theme }) =>
		$isSetupFlow &&
		`
		color: ${$isActive ? theme.colors.dangerHover : theme.colors.fontSecondary};
		background-color: ${
			$isActive ? theme.colors.activeDangerBackground : 'transparent'
		};
	`}
`

export const AppMenuItemIcon = styled.div<{ badge?: boolean; child?: boolean }>`
	flex-shrink: 0;
	height: 1.5rem;
	margin-right: 0.5rem;
	position: relative;
	width: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;

	${({ badge, theme }) =>
		badge &&
		`
	  &:before {
			background: ${theme.colors.accentPrimary};
			border-radius: 50%;
			border: 2px solid white;
			content: ' ';
			height: 0.75rem;
			left: -4px;
			position: absolute;
			top: -2px;
			width: 0.75rem;
		}
	`}

	svg {
		width: 100%;
	}
`

export const AppMenuItemLastIcon = styled(AppMenuItemIcon)`
	margin-right: 0;
`

export const AppMenuLabel = styled.div<{ child?: boolean }>`
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.25;
	letter-spacing: -0.01em;

	${({ child }) =>
		child &&
		`
		font-size: 0.75rem;
	`}
`

export const AppMenuParentContainer = styled.div<{ hasChildren?: boolean }>`
	display: flex;
	align-items: center;
`
