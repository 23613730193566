import styled from 'styled-components'
import { Text } from 'components/Text'

export const HeroAreaContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.white};
	align-items: center;
	min-height: 20vh;
`

export const HeroAreaTitle = styled(Text)`
	line-height: 1.25;
	font-size: 4.938rem;
	-webkit-letter-spacing: -2.37px;
	-moz-letter-spacing: -2.37px;
	-ms-letter-spacing: -2.37px;
	letter-spacing: -2.37px;
	text-align: left;
	font-weight: normal;
	color: ${({ theme }) => theme.colors.fontMain};
`
export const HeroAreaSubTitle = styled(Text)`
	line-height: 1.4rem;
	color: ${({ theme }) => theme.colors.fontMain};
	margin: 0.5rem 0;
	max-width: 34rem;
	font-size: 1rem;
`

export const HeroAreaIcon = styled.img`
	padding: 5px;
`
