import styled, { css } from 'styled-components'

const defaultCss = css`
	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@-webkit-keyframes rotate {
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
	@-webkit-keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
`

export const StyledSvg = styled.svg`
	${defaultCss}
	-webkit-animation: rotate 2s linear infinite;
	animation: rotate 2s linear infinite;
	z-index: 2;
	width: 45px;
	height: 45px;
	margin: 100px auto;
	display: block;
`

export const StyledCircle = styled.circle`
	stroke-dasharray: 1, 150;
	stroke-dashoffset: 0;
	stroke: ${({ theme }) => theme.colors.accentText};
	stroke-linecap: round;
	-webkit-animation: dash 1.5s ease-in-out infinite;
	animation: dash 1.5s ease-in-out infinite;
`
