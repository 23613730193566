import React from 'react'

//UTILS
import { formatUSNumber } from 'utils/format-number'

//PROPS TYPE
import { AppBannerStatusProps } from './AppBannerStatus.props'

//ASSETS

//STYLES
import {
	AppBannerStatusContainer,
	AppBannerStatusContent,
	AppBannerStatusText,
} from './AppBannerStatus.styled'
import { useHistory } from 'react-router-dom'

const AppBannerStatus = ({ currentUser }: AppBannerStatusProps) => {
	const history = useHistory()
	const isError =
		currentUser &&
		(currentUser.phoneTransferStatus === 'phone_transfer_error' ||
			currentUser.phoneTransferStatus === null)

	const isAddressError =
		currentUser?.organizations?.[0]?.address?.validationStatus ===
		'address_verification_error'

	const isPending =
		currentUser && currentUser.phoneTransferStatus === 'phone_transfer_pending'

	return (
		<AppBannerStatusContainer
			status={
				isError || isAddressError
					? 'phone_transfer_error'
					: 'phone_transfer_pending'
			}
		>
			<AppBannerStatusContent>
				{!isAddressError && isPending && (
					<AppBannerStatusText color="white">
						Your Phone Number,{' '}
						{currentUser?.organizations?.[0]?.phone &&
							formatUSNumber(currentUser.organizations[0].phone)}
						{''}, is being text-enabled with Convos. We will notify you when
						text enablement has been completed.
					</AppBannerStatusText>
				)}
				{!isAddressError && isError && (
					<AppBannerStatusText color="white">
						Something went wrong when we tried to text-enable your phone number,
						{'  '}
						{currentUser?.organizations?.[0]?.phone &&
							formatUSNumber(currentUser.organizations[0].phone)}
						.&nbsp;<strong>Please try again.</strong>
					</AppBannerStatusText>
				)}
				{isAddressError && (
					<AppBannerStatusText color="white">
						Your organization address is invalid. Try updating it from&nbsp;
						<strong onClick={() => history.push('/settings/my-phone-number')}>
							here
						</strong>
						.
					</AppBannerStatusText>
				)}
			</AppBannerStatusContent>
		</AppBannerStatusContainer>
	)
}

export default AppBannerStatus
