import { SK_AUTH_URL } from '@constants/envs'
import { authActions } from 'store/slices/auth'
import { useAppDispatch, useAppState } from 'store'
import { API_URL } from '@constants'
import { useEffect } from 'react'
import { useAppContext } from 'modules/App/AppContext'
import { onboardingActions } from 'store/slices/onboarding'

export function useInitApp() {
	const dispatch = useAppDispatch()
	const { isUnifiedDashboard, refreshFeatureFlags } = useAppContext()
	const { authorized } = useAppState(state => state.auth)

	const deleteCookies = async () => {
		try {
			const response = await fetch(`${API_URL}/auth/logout`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			})

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`)
			}

			const responseData = await response.json()

			console.log('Response:', responseData)
		} catch (error) {
			console.error('Error making POST request:', error)
		}
	}
	const init = async () => {
		if (authorized) {
			const res = await dispatch(authActions.getMe())
			console.log(`CHECKING ~ init ~ res:`, res)
			if (res.meta.requestStatus !== 'fulfilled') {
				deleteCookies()
				dispatch({ type: 'LOG_OUT' })
				await refreshFeatureFlags()
				isUnifiedDashboard && window.location.replace(`${SK_AUTH_URL}/?convos`)
			}
		}
	}

	useEffect(() => {
		init()
		if (authorized) {
			dispatch(onboardingActions.getOnboardingStages({}))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authorized, dispatch])

	return { authorized, init }
}
