import { gql } from '@apollo/client'
import ApiService from 'services/api'
import { CspCampaign } from 'types/api/csp-campaign.modal'
import {
	CspCampaignCreateFields,
	CspCampaignUpdateFields,
	CspCampaignGetFields,
} from './csp-campaign.types'
import { CSP_CAMPAIGN } from './csp-campaign.gpl'

export class CspCampaignService {
	private CREATE_CSP_CAMPAIGN = gql`
        mutation CreateCspCampaign($input: CreateCspCampaignInput!) {
            createCspCampaign(input: $input) {
                ${CSP_CAMPAIGN}
            }
        }
    `

	private GET_CSP_CAMPAIGN = gql`
        query GetCspCampaign($brandId: String!) {
            getCspCampaign(brandId: $brandId) {
                ${CSP_CAMPAIGN}
            }
        }
    `

	private UPDATE_CSP_CAMPAIGN = gql`
		mutation UpdateCspCampaign($input: UpdateCspCampaignInput!) {
			updateCspCampaign(input: $input) {
			${CSP_CAMPAIGN}
			}
		}
	`

	createCspCampaign = async (
		fields: CspCampaignCreateFields
	): Promise<CspCampaign> => {
		return await ApiService.client
			.mutate<
				{ createCspCampaign: CspCampaign },
				{ input: CspCampaignCreateFields }
			>({
				mutation: this.CREATE_CSP_CAMPAIGN,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.createCspCampaign
			})
	}

	getCspCampaign = async (
		fields: CspCampaignGetFields
	): Promise<CspCampaign> => {
		return await ApiService.client
			.query({
				query: this.GET_CSP_CAMPAIGN,
				variables: fields,
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw new Error()
				}
				return response.data && response.data.getCspCampaign
			})
	}

	updateCspCampaign = async (
		fields: CspCampaignUpdateFields
	): Promise<CspCampaign> => {
		return await ApiService.client
			.mutate<
				{ updateCspCampaign: CspCampaign },
				{ input: CspCampaignUpdateFields }
			>({
				mutation: this.UPDATE_CSP_CAMPAIGN,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.updateCspCampaign
			})
	}
}

export default new CspCampaignService()
