import { RefObject, useEffect, useState } from 'react'

const useOutsideClick = (ref: RefObject<HTMLElement>) => {
	const [open, setOpen] = useState(false)

	const handleClose = () => {
		setOpen(false)
	}

	const handleOpen = () => {
		setOpen(!open)
	}

	useEffect(() => {
		const handleCloseListener = (evt: MouseEvent) => {
			if (ref.current && !ref.current.contains(evt.target as Node)) {
				setOpen(false)
			}
		}
		if (open) document.addEventListener('click', handleCloseListener)
		return () => {
			document.removeEventListener('click', handleCloseListener)
		}
	}, [open, ref])

	return { open, handleOpen, handleClose }
}

export default useOutsideClick
