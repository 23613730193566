import api from 'services/api'
import { gql } from '@apollo/client'
import {
	AddPaymentMethodMutation,
	CreatePaymentMethodMutation,
	GetPaymentMethodsMutation,
} from './payments.types'
import { PAYMENT_METHOD_CHARGEBEE } from 'services/gpl/payment-method'
import { PaymentMethodChargebee } from 'types/api/payment-method'

export class PaymentService {
	private CREATE_PAYMENT_METHOD = gql`
		mutation CreatePaymentMethod($input: CreatePaymentMethodInput!) {
			createPaymentMethod(input: $input) {
				${PAYMENT_METHOD_CHARGEBEE}
			}
		}	
	`

	private GET_PAYMENT_METHODS = gql`
		mutation listOrgPaymentMethods($input: ListOrgPaymentMethodsInput!) {
			listOrgPaymentMethods(input: $input) {
				${PAYMENT_METHOD_CHARGEBEE}
			}
		}	
	`
	createPaymentMethod = async (
		fields: CreatePaymentMethodMutation
	): Promise<PaymentMethodChargebee> => {
		return api.client
			.mutate<
				{ createPaymentMethod: PaymentMethodChargebee },
				{ input: CreatePaymentMethodMutation }
			>({
				mutation: this.CREATE_PAYMENT_METHOD,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.createPaymentMethod
			})
	}

	getPaymentMethods = async (
		body: GetPaymentMethodsMutation
	): Promise<PaymentMethodChargebee[]> => {
		return await api.client
			.mutate<
				{ listOrgPaymentMethods: PaymentMethodChargebee[] },
				{ input: GetPaymentMethodsMutation }
			>({
				mutation: this.GET_PAYMENT_METHODS,
				variables: { input: body },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.listOrgPaymentMethods
			})
	}
}

export default new PaymentService()
