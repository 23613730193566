import { gql } from '@apollo/client'
import ApiService from 'services/api'
// Types
import { Invoice } from 'types/api/invoice.model'
// GPL
import { INVOICES } from './invoices.gpl'

class InvoicesService {
	private GET_INVOICES = gql`
		query {
			invoices {
				${INVOICES}
			}
		}
	`

	getInvoices = async (): Promise<Invoice[]> => {
		return await ApiService.client
			.query({
				query: this.GET_INVOICES,
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.invoices
			})
	}
}

export default new InvoicesService()
