import { createAsyncThunk } from '@reduxjs/toolkit'
import OnboardingService from 'services/onboarding'

export const getOnboardingStages = createAsyncThunk(
	'organization/get/onboarding-stages',
	OnboardingService.getOnboardingStages
)

export const updateOnboardingStage = createAsyncThunk(
	'organization/update/onboarding-stage',
	OnboardingService.updateOnboardingStage
)
