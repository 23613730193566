import { createAsyncThunk } from '@reduxjs/toolkit'
import paymentService, { CreatePaymentMethodMutation } from 'services/payments'
import { RootState } from 'store'
import { PaymentMethodChargebee } from 'types/api/payment-method'

export const createPaymentMethod = createAsyncThunk<
	PaymentMethodChargebee,
	CreatePaymentMethodMutation
>('payment/create-method', (query, thunkAPI) => {
	return paymentService.createPaymentMethod({
		...query,
	})
})

export const getPaymentMethods = createAsyncThunk(
	'payment/get-paymentList',
	paymentService.getPaymentMethods
)
