import { createAsyncThunk } from '@reduxjs/toolkit'
import SubcriptionService from 'services/subscription'

export const getSubscription = createAsyncThunk(
	'subcription/get',
	SubcriptionService.getSubscription
)
export const createSubscription = createAsyncThunk(
	'subcription/create/subcription',
	SubcriptionService.createSubscription
)
export const updateSubscription = createAsyncThunk(
	'subcription/update/subcription',
	SubcriptionService.updateSubscription
)
export const cancelSubscription = createAsyncThunk(
	'subcription/cancel/subcription',
	SubcriptionService.cancelSubscription
)
export const getSubscriptionEstimate = createAsyncThunk(
	'subcription/getEstimate',
	SubcriptionService.getSubscriptionEstimate
)
