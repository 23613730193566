import { createSlice } from '@reduxjs/toolkit'
import { CspCampaign } from 'types/api/csp-campaign.modal'
import createAsyncReducers from 'utils/create-async-reducers'

import {
	createCspCampaign,
	getCspCampaign,
	updateCspCampaign,
} from './csp-campaign.action'

interface CspCampaignState {
	cspCampaign: CspCampaign | null
	cspCampaignLoading: boolean
	error: string | null
}

const initialState: CspCampaignState = {
	cspCampaign: null,
	cspCampaignLoading: false,
	error: null,
}

const { reducer, actions } = createSlice({
	name: 'cspCampaign',
	initialState,
	reducers: {},
	extraReducers: builder => {
		createAsyncReducers(
			builder,
			createCspCampaign,
			'cspCampaignLoading',
			(state, action) => {
				state.cspCampaign = action.payload
			}
		)

		createAsyncReducers(
			builder,
			getCspCampaign,
			'cspCampaignLoading',
			(state, action) => {
				state.cspCampaign = action.payload
			}
		)

		createAsyncReducers(
			builder,
			updateCspCampaign,
			'cspCampaignLoading',
			(state, action) => {
				state.cspCampaign = action.payload
			}
		)
	},
})

export default reducer

export const cspCampaignActions = {
	...actions,
	createCspCampaign,
	getCspCampaign,
	updateCspCampaign,
}
