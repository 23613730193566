import { gql } from '@apollo/client'
import ApiService from 'services/api'
// Models
import { Contact } from 'types/api/contact.model'
import { IContactGroup, IContactTag } from 'types/api/contact'
// Contact Types
import {
	CreateContactMutation,
	ContactGroupFieldsQuery,
	ContactTagFieldsQuery,
	GroupFieldsQuery,
	TagFieldsToRemoveMutation,
	GroupFieldsToRemoveMutation,
	AddGroupContactFieldsMutation,
	UpdateContactMutation,
	ContactLNCFieldsQuery,
	BulkUploadContactsMutation,
	BulkUploadContactsResponse,
	GetNumBulkContactsResponse,
	SearchContactListQuery,
} from 'services/contact/contact.types'
// GQL
import { CONTACT, GROUP_CONTACT, TAG_CONTACT } from '../gpl/contact'
import { ContactList } from 'types/api/contacts-list'

export class ContactService {
	private CREATE_CONTACT = gql`
		mutation createContact($input: CreateContactInput!) {
			createContact(input: $input) {
				${CONTACT}
			}
		}
	`

	private SEARCH_CONTACTS = gql`
		query SearchContacts($searchQuery: String!) {
			searchContacts(searchQuery: $searchQuery) {
				contacts{
					${CONTACT}
				}
				totalContacts
			}
		}
	`

	private CREATE_CONTACT_GROUP = gql`
		mutation createGroup($input: CreateGroupInput!) {
			createGroup(input: $input) {
				${GROUP_CONTACT}
			}
		}
	`

	private UPDATE_CONTACT_GROUP = gql`
		mutation updateGroup($input: UpdateGroupInput!) {
			updateGroup(input: $input) {
				${GROUP_CONTACT}
			}
		}
	`

	private CREATE_CONTACT_TAG = gql`
		mutation createTag($input: CreateTagInput!) {
			createTag(input: $input) {
				${TAG_CONTACT}
			}
		}
	`
	private UPDATE_CONTACT_TAG = gql`
		mutation createTag($input: UpdateTagInput!) {
			updateTag(input: $input) {
				${TAG_CONTACT}
			}
		}
	`

	private ADD_GROUP_CONTACT = gql`
		mutation addGroupContact($input: AddGroupContactInput!){
			addGroupContact(input: $input){
				${CONTACT}
			}
		}
	`

	private GET_CONTACT = gql`
		query ($id: ID, $phone: ID) {
			getContact(id: $id, phone: $phone) {
				${CONTACT}
			}
		}
	`

	private GET_CONTACTS = gql`
	query ListContacts($query: String, $lncStart: Int, $lncEnd: Int, $group: String, $tag: String, $limit: Int, $offset: Int){
		listContacts(
			query: $query
			lncStart: $lncStart,
			lncEnd: $lncEnd,
			group: $group,
			tag: $tag
			limit: $limit,
			offset: $offset
		  ) {
			${CONTACT}
		}
		totalContacts(query: $query,
					lncStart: $lncStart,
					lncEnd: $lncEnd,
					group: $group,
					tag: $tag)
	}
	`

	private GET_CONTACTS_IN_CAMPAIGN = gql`
		query ListCampaignContacts {
			listCampaignContatcs {
				id
				firstName
				lastName
				phone
				tags {
					id
				}
				contactGroups {
					id
				}
				status
			}
			totalContacts
		}
	`

	private GET_GROUP_CONTACTS = gql`
		query ListGroups {
			listGroups {
				${GROUP_CONTACT}
  			}
		}
	`

	private GET_TAGS_CONTACTS = gql`
		query ListTags {
			listTags {
				${TAG_CONTACT}
  			}
		}
	`
	private GET_CONTACTS_COUNT = gql`
		query getContactsCount {
			getContactsCount
		}
	`
	private GET_LATEST_CONTACTS = gql`
		query getLatestContact {
			getLatestContact{
				${CONTACT}
			}
		}
	`
	private GET_OUTBOUND_SMS_COUNT = gql`
		query getOutboundSmsCount {
			getOutboundSmsCount
		}
	`

	private UPDATE_CONTACT = gql`
		mutation updateContact($input: UpdateContactInput!){
			updateContact(input: $input) {
				${CONTACT}
			}
		}
	`

	private DELETE_CONTACT = gql`
		mutation deleteContact($input: DeleteContactInput!){
  		deleteContact(input: $input){
				${CONTACT}
  		}
		}
	`

	private DELETE_CONTACT_TAG = gql`
		mutation deleteTag($input: DeleteTagInput!){
  		deleteTag(input: $input){
				${TAG_CONTACT}
  		}
		}
	`

	private DELETE_CONTACT_GROUP = gql`
		mutation deleteGroup($input: DeleteGroupInput!){
  		deleteGroup(input: $input){
				${GROUP_CONTACT}
  		}
		}
	`

	private BULK_UPLOAD_CONTACTS = gql`
		mutation bulkUploadContacts($input: BulkUploadContactsInput!) {
			bulkUploadContacts(input: $input) {
				numUploaded
				numDuplicates
				numFailures
				contacts {
					id
				}
			}
		}
	`

	private GET_NUM_BULK_CONTACTS = gql`
		query getNumBulkContacts($fileContent: String!) {
			getNumBulkContacts(fileContent: $fileContent) {
				numUploaded
				numDuplicates
				numFailures
				contacts {
					id
				}
			}
		}
	`

	createContact = async (fields: CreateContactMutation): Promise<Contact> => {
		return await ApiService.client
			.mutate<{ createContact: Contact }, CreateContactMutation>({
				mutation: this.CREATE_CONTACT,
				variables: fields,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data!.createContact
			})
	}

	searchContacts = async (
		fields: SearchContactListQuery
	): Promise<ContactList> => {
		return await ApiService.client
			.mutate<{ searchContacts: ContactList }, SearchContactListQuery>({
				mutation: this.SEARCH_CONTACTS,
				variables: fields,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data!.searchContacts
			})
	}

	addContactGroup = async (
		fields: AddGroupContactFieldsMutation
	): Promise<Contact> => {
		return await ApiService.client
			.mutate<{ addContactGroup: Contact }, AddGroupContactFieldsMutation>({
				mutation: this.ADD_GROUP_CONTACT,
				variables: fields,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data!.addContactGroup
			})
	}

	createContactGroup = async (
		fields: ContactGroupFieldsQuery
	): Promise<IContactGroup> => {
		return await ApiService.client
			.mutate<{ createGroup: IContactGroup }, ContactGroupFieldsQuery>({
				mutation: this.CREATE_CONTACT_GROUP,
				variables: fields,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data!.createGroup
			})
	}
	updateContactGroup = async (
		fields: ContactGroupFieldsQuery
	): Promise<IContactGroup> => {
		return await ApiService.client
			.mutate<{ updateGroup: IContactGroup }, ContactGroupFieldsQuery>({
				mutation: this.UPDATE_CONTACT_GROUP,
				variables: fields,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data!.updateGroup
			})
	}

	createContactTag = async (
		fields: ContactTagFieldsQuery
	): Promise<IContactTag> => {
		return await ApiService.client
			.mutate<{ createTag: IContactTag }, ContactTagFieldsQuery>({
				mutation: this.CREATE_CONTACT_TAG,
				variables: fields,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data!.createTag
			})
	}
	updateContactTag = async (
		fields: ContactTagFieldsQuery
	): Promise<IContactTag> => {
		return await ApiService.client
			.mutate<{ updateTag: IContactTag }, ContactTagFieldsQuery>({
				mutation: this.UPDATE_CONTACT_TAG,
				variables: fields,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data!.updateTag
			})
	}

	updateContact = async (input: UpdateContactMutation): Promise<Contact> => {
		return await ApiService.client
			.mutate<{ updateContact: Contact }, { input: UpdateContactMutation }>({
				mutation: this.UPDATE_CONTACT,
				variables: { input },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data!.updateContact
			})
	}

	getContact = async ({
		id,
		phone,
	}: {
		id?: string
		phone?: string
	}): Promise<Contact> => {
		return await ApiService.client
			.query({
				query: this.GET_CONTACT,
				variables: id ? { id } : { phone },
			})
			.then(response => {
				if (!response || response.error || !response.data) {
					throw new Error()
				}
				return response.data && response.data.getContact
			})
	}

	getContacts = async (
		fields?: ContactLNCFieldsQuery
	): Promise<ContactList> => {
		return await ApiService.client
			.query({
				query: this.GET_CONTACTS,
				variables: { ...fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return {
					contacts: response.data!.listContacts,
					totalContacts: response.data!.totalContacts,
				}
			})
	}

	getContactsInCampaign = async (
		fields?: ContactLNCFieldsQuery
	): Promise<ContactList> => {
		return await ApiService.client
			.query({
				query: this.GET_CONTACTS_IN_CAMPAIGN,
				variables: { ...fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return {
					contacts: response.data!.listCampaignContatcs,
					totalContacts: response.data!.totalContacts,
				}
			})
	}

	getGroupContact = async (): Promise<IContactGroup[]> => {
		return await ApiService.client
			.query({
				query: this.GET_GROUP_CONTACTS,
			})
			.then(response => {
				console.log(`CHECKING ~ getGroupContact= ~ response:`, response)

				if (!response || !response.data) {
					throw new Error()
				}
				return response.data!.listGroups
			})
	}

	getTagsContact = async (): Promise<IContactTag[]> => {
		return await ApiService.client
			.query({
				query: this.GET_TAGS_CONTACTS,
			})
			.then(response => {
				console.log(`CHECKING ~ getTagsContact= ~ response:`, response)

				if (!response || !response.data) {
					throw new Error()
				}
				return response.data!.listTags
			})
	}

	getContactsCount = async () => {
		return await ApiService.client
			.query({
				query: this.GET_CONTACTS_COUNT,
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data
			})
	}
	getLatestContact = async () => {
		return await ApiService.client
			.query({
				query: this.GET_LATEST_CONTACTS,
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data.getLatestContact
			})
	}
	getOutboundSmsCount = async () => {
		return await ApiService.client
			.query({
				query: this.GET_OUTBOUND_SMS_COUNT,
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data.getOutboundSmsCount
			})
	}

	deleteContact = async (fields: { id: string }): Promise<Contact> => {
		return await ApiService.client
			.mutate<{ deleteContact: Contact }>({
				mutation: this.DELETE_CONTACT,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data!.deleteContact
			})
	}

	deleteContactTag = async (
		fields: TagFieldsToRemoveMutation
	): Promise<IContactTag> => {
		return await ApiService.client
			.mutate<{ deleteTag: IContactTag }, TagFieldsToRemoveMutation>({
				mutation: this.DELETE_CONTACT_TAG,
				variables: fields,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data!.deleteTag
			})
	}

	deleteContactGroup = async (
		fields: GroupFieldsToRemoveMutation
	): Promise<IContactGroup> => {
		return await ApiService.client
			.mutate<{ deleteGroup: IContactGroup }, GroupFieldsToRemoveMutation>({
				mutation: this.DELETE_CONTACT_GROUP,
				variables: fields,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data!.deleteGroup
			})
	}

	bulkUploadContacts = async (
		fields: BulkUploadContactsMutation
	): Promise<BulkUploadContactsResponse> => {
		return await ApiService.client
			.mutate<
				{ bulkUploadContacts: BulkUploadContactsResponse },
				{ input: BulkUploadContactsMutation }
			>({
				mutation: this.BULK_UPLOAD_CONTACTS,
				variables: { input: fields },
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					const graphQLError = response?.errors
						? response.errors.map(e => e.message).join('; ')
						: 'Undefined Server Error'
					throw new Error(graphQLError)
				}
				return response.data && response.data.bulkUploadContacts
			})
	}

	getNumBulkContacts = async ({
		fileContent,
	}: {
		fileContent: string
	}): Promise<GetNumBulkContactsResponse> => {
		return await ApiService.client
			.query({
				query: this.GET_NUM_BULK_CONTACTS,
				variables: { fileContent },
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					const graphQLError = response?.errors
						? response.errors.map(e => e.message).join('; ')
						: 'Undefined Server Error'
					throw new Error(graphQLError)
				}
				return response.data && response.data.getNumBulkContacts
			})
	}
}

export default new ContactService()
