// user: User!
// transactions: [Transaction!]

export const PAYMENT_METHOD_CHARGEBEE_CARD = `
  card {
    brand
    expiryMonth
    expiryYear
    firstName
    fundingType
    last4
    lastName
    maskedNumber
  }
`
export const PAYMENT_METHOD_CHARGEBEE = `
  referenceId
  deleted
  status
  ${PAYMENT_METHOD_CHARGEBEE_CARD}
`
