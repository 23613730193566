import { createAsyncThunk } from '@reduxjs/toolkit'
import giphyService from 'services/giphy'

export const getTrendingGif = createAsyncThunk(
	'giphy/trending',
	giphyService.getTrendingGif
)
export const searchGif = createAsyncThunk(
	'giphy/search',
	giphyService.searchGif
)
