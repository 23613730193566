import { createSlice } from '@reduxjs/toolkit'
import { createPaymentMethod, getPaymentMethods } from './payments.actions'
import createAsyncReducers from 'utils/create-async-reducers'
import { PaymentMethodChargebee } from 'types/api/payment-method'

export interface IState {
	loading: boolean
	paymentMethods: PaymentMethodChargebee[]
}

const initialState: IState = {
	loading: false,
	paymentMethods: [],
}

const { reducer, actions } = createSlice({
	name: 'payment',
	initialState,
	reducers: {},
	extraReducers: builder => {
		createAsyncReducers(
			builder,
			createPaymentMethod,
			'loading',
			(state, action) => {
				const newPaymentMethod: PaymentMethodChargebee = action.payload
				state.paymentMethods.push(newPaymentMethod)
			}
		)
		createAsyncReducers(
			builder,
			getPaymentMethods,
			'loading',
			(state, action) => {
				state.paymentMethods = action.payload
			}
		)
	},
})

export default reducer
export const paymentActions = {
	...actions,
	createPaymentMethod,
	getPaymentMethods,
}
