import {
	ActionReducerMapBuilder,
	AsyncThunk,
	Draft,
	PayloadAction,
	SerializedError,
} from '@reduxjs/toolkit'

function createAsyncReducers<State, Returned, Args = void>(
	builder: ActionReducerMapBuilder<State>,
	action: AsyncThunk<Returned, Args, Record<string, unknown>>,
	statusKey: keyof Draft<State>,
	fulfilled?: (state: Draft<State>, action: PayloadAction<Returned>) => void,
	rejected?: (
		state: Draft<State>,
		action: PayloadAction<
			unknown,
			string,
			{
				arg: Args
				requestId: string
				rejectedWithValue: boolean
				requestStatus: 'rejected'
				aborted: boolean
				condition: boolean
			},
			SerializedError
		>
	) => void
) {
	builder.addCase(action.pending, state => {
		state[statusKey] = true as Draft<State>[keyof Draft<State>]
	})
	builder.addCase(action.fulfilled, (state, action) => {
		state[statusKey] = false as Draft<State>[keyof Draft<State>]
		if (fulfilled) fulfilled(state, action)
	})
	builder.addCase(action.rejected, (state, action) => {
		state[statusKey] = false as Draft<State>[keyof Draft<State>]
		if (rejected) rejected(state, action)
	})
}

export default createAsyncReducers
