import ApiService from 'services/api'
import api from 'services/api'
import { gql } from '@apollo/client'
import { User } from 'types/api/user.model'
import { USER } from 'services/gpl/user'
import {
	GetUserByTokenResponse,
	UpdateQuestionaireStepType,
} from './user.types'

export class UserService {
	private GET_USER = gql`
		query GetUser($id: ID!) {
			getUser(id: $id) {
				${USER}
			}
		}	
	`

	getUser = async (id: string): Promise<User> => {
		return api.client
			.query<{ getUser: User }, { id: string }>({
				query: this.GET_USER,
				variables: { id },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data.getUser
			})
	}

	private GET_USER_BY_TOKEN = gql`
		query GetUserByResetPasswordToken($token: ID!) {
			getUserByResetPasswordToken(token: $token) {
				id
				email
				skVesselId
			}
		}
	`
	getUserByToken = async (token: string): Promise<GetUserByTokenResponse> => {
		return api.client
			.query<
				{ getUserByResetPasswordToken: GetUserByTokenResponse },
				{ token: string }
			>({
				query: this.GET_USER_BY_TOKEN,
				variables: { token },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data.getUserByResetPasswordToken
			})
	}

	private UPDATE_QUESTIONAIRE_STEP = gql`
		mutation UpdateQuestionaireStep($input: UpdateQuestionaireStepInput!) {
			updateQuestionaireStep(input: $input) {
				${USER}
			}
		}
	`
	updateQuestionaireStep = async (
		input: UpdateQuestionaireStepType
	): Promise<User> => {
		return await ApiService.client
			.mutate<
				{ updateOnboardingSteps: User },
				{ input: UpdateQuestionaireStepType }
			>({
				mutation: this.UPDATE_QUESTIONAIRE_STEP,
				variables: { input },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data.updateOnboardingSteps
			})
	}
}

export default new UserService()
