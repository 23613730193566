import { createAsyncThunk } from '@reduxjs/toolkit'
import tagService from 'services/tag'

export const getTags = createAsyncThunk('tag/get', tagService.getTags)
export const createTag = createAsyncThunk('tag/create', tagService.createTag)
export const deleteTag = createAsyncThunk('tag/delete', tagService.deleteTag)
export const createContactGroupTag = createAsyncThunk(
	'tag/createContactGroupTag',
	tagService.createContactGroupTag
)
