import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IGettingStartedFlowState {
	complete: boolean
	disable: boolean
}

export interface IState {
	phone: IGettingStartedFlowState
	contacts: IGettingStartedFlowState
	optinFlow: IGettingStartedFlowState
	firstText: IGettingStartedFlowState
}

const initialState: IState = {
	phone: {
		complete: false,
		disable: false,
	},
	contacts: {
		complete: false,
		disable: false,
	},
	optinFlow: {
		complete: false,
		disable: false,
	},
	firstText: {
		complete: false,
		disable: false,
	},
}

const { reducer, actions } = createSlice({
	name: 'gettingStartedFlow',
	initialState,
	reducers: {
		setPhoneComplete: (state, action: PayloadAction<boolean>) => {
			state.phone.complete = action.payload
		},
		setPhoneDisable: (state, action: PayloadAction<boolean>) => {
			state.phone.disable = action.payload
		},
		setContactsComplete: (state, action: PayloadAction<boolean>) => {
			state.contacts.complete = action.payload
		},
		setContactsDisable: (state, action: PayloadAction<boolean>) => {
			state.contacts.disable = action.payload
		},
		setOptinFlowComplete: (state, action: PayloadAction<boolean>) => {
			state.optinFlow.complete = action.payload
		},
		setOptinFlowDisable: (state, action: PayloadAction<boolean>) => {
			state.optinFlow.disable = action.payload
		},
		setFirstTextComplete: (state, action: PayloadAction<boolean>) => {
			state.firstText.complete = action.payload
		},
		setFirstTextDisable: (state, action: PayloadAction<boolean>) => {
			state.firstText.disable = action.payload
		},
	},
})

export default reducer
export const gettingStartedFlowActions = {
	...actions,
}
