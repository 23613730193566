import { createAsyncThunk } from '@reduxjs/toolkit'
import contactServices from 'services/contact'

export const createContact = createAsyncThunk(
	'contact/create',
	contactServices.createContact
)

export const searchContacts = createAsyncThunk(
	'search/contacts',
	contactServices.searchContacts
)

export const addContactGroup = createAsyncThunk(
	'contact/add/contact/group',
	contactServices.addContactGroup
)

export const updateContact = createAsyncThunk(
	'contact/update',
	contactServices.updateContact
)

export const getContact = createAsyncThunk(
	'contact/get/id',
	contactServices.getContact
)

export const getContacts = createAsyncThunk(
	'contact/get',
	contactServices.getContacts
)
export const getContactsInCampaign = createAsyncThunk(
	'contact/campaign/get',
	contactServices.getContactsInCampaign
)

export const getGroupContact = createAsyncThunk(
	'contact/group/get',
	contactServices.getGroupContact
)

export const getTagsContact = createAsyncThunk(
	'contact/tags/get',
	contactServices.getTagsContact
)

export const createContactGroup = createAsyncThunk(
	'contact/group/create',
	contactServices.createContactGroup
)

export const updateContactGroup = createAsyncThunk(
	'contact/group/update',
	contactServices.updateContactGroup
)

export const createContactTag = createAsyncThunk(
	'contact/tag/create',
	contactServices.createContactTag
)

export const updateContactTag = createAsyncThunk(
	'contact/tag/update',
	contactServices.updateContactTag
)

export const deleteContact = createAsyncThunk(
	'contact/delete',
	contactServices.deleteContact
)

export const deleteContactTag = createAsyncThunk(
	'contact/tag/delete',
	contactServices.deleteContactTag
)

export const deleteContactGroup = createAsyncThunk(
	'contact/group/delete',
	contactServices.deleteContactGroup
)

export const listContacts = createAsyncThunk(
	'contact/list',
	contactServices.getContacts
)

export const bulkUploadContacts = createAsyncThunk(
	'contact/bulk/upload',
	contactServices.bulkUploadContacts
)

export const getNumBulkContacts = createAsyncThunk(
	'contact/bulk/getNumValid',
	contactServices.getNumBulkContacts
)
export const getContactsCount = createAsyncThunk(
	'contact/contacts/count',
	contactServices.getContactsCount
)
export const getLatestContact = createAsyncThunk(
	'contact/latest/contacts',
	contactServices.getLatestContact
)
export const getOutboundSmsCount = createAsyncThunk(
	'contact/sms/count',
	contactServices.getOutboundSmsCount
)
