import { createSlice } from '@reduxjs/toolkit'
import {
	createOrganization,
	createOrganizationUser,
	toggleOptinFlow,
	toggleNotificationSettings,
	getOrganizations,
	updateOrganizationBilling,
	getOrganizationsByParentId,
	updateOrganizationsOptinKeyword,
	toggleOptinByFirstSms,
	toggleOptinByOrgOptinKeyword,
	toggleRestrictBulkMsgs,
	toggleRestrictAllMsgs,
	toggleAutosendOutboundMsgs,
	updateOrganization,
	updateOrganizationLogo,
	updateOrganizationName,
	updateOrganizationAdvisorName,
} from './organization.actions'
import createAsyncReducers from 'utils/create-async-reducers'
import { Organization } from 'types/api/organization.model'

export interface IState {
	organization: Organization | null
	organizations: Organization[]
	organizationsChild: Organization[]
	loading: boolean
	loadingToggle: boolean
}

const initialState: IState = {
	organization: null,
	organizations: [],
	organizationsChild: [],
	loading: false,
	loadingToggle: false,
}

const { reducer, actions } = createSlice({
	name: 'organization',
	initialState,
	reducers: {},
	extraReducers: builder => {
		createAsyncReducers(
			builder,
			createOrganization,
			'loading',
			(state, action) => {
				state.organization = action.payload
			}
		)
		createAsyncReducers(
			builder,
			updateOrganization,
			'loading',
			(state, action) => {
				state.organization = action.payload
			}
		)
		createAsyncReducers(
			builder,
			updateOrganizationLogo,
			'loading',
			(state, action) => {
				state.organization = action.payload
			}
		)
		createAsyncReducers(
			builder,
			updateOrganizationBilling,
			'loading',
			(state, action) => {
				state.organization = action.payload
			}
		)
		createAsyncReducers(builder, toggleOptinFlow, 'loadingToggle')
		createAsyncReducers(builder, createOrganizationUser, 'loading')
		createAsyncReducers(
			builder,
			getOrganizations,
			'loading',
			(state, action) => {
				state.organizations = action.payload
			}
		)
		createAsyncReducers(
			builder,
			getOrganizationsByParentId,
			'loading',
			(state, action) => {
				state.organizationsChild = action.payload
			}
		)
		createAsyncReducers(
			builder,
			updateOrganizationsOptinKeyword,
			'loading',
			(state, action) => {
				state.organization = action.payload
			}
		)
		createAsyncReducers(
			builder,
			toggleOptinByOrgOptinKeyword,
			'loading',
			(state, action) => {
				state.organization = action.payload
			}
		)
		createAsyncReducers(
			builder,
			toggleNotificationSettings,
			'loading',
			(state, action) => {
				state.organization = action.payload
			}
		)
		createAsyncReducers(
			builder,
			toggleOptinByFirstSms,
			'loading',
			(state, action) => {
				state.organization = action.payload
			}
		)
		createAsyncReducers(
			builder,
			toggleRestrictBulkMsgs,
			'loading',
			(state, action) => {
				state.organization = action.payload
			}
		)
		createAsyncReducers(
			builder,
			toggleRestrictAllMsgs,
			'loading',
			(state, action) => {
				state.organization = action.payload
			}
		)
		createAsyncReducers(
			builder,
			toggleAutosendOutboundMsgs,
			'loading',
			(state, action) => {
				state.organization = action.payload
			}
		)
		createAsyncReducers(
			builder,
			updateOrganizationName,
			'loading',
			(state, action) => {
				state.organization = action.payload
			}
		)
		createAsyncReducers(
			builder,
			updateOrganizationAdvisorName,
			'loading',
			(state, action) => {
				state.organization = action.payload
			}
		)
	},
})

export default reducer
export const organizationActions = {
	...actions,
	createOrganization,
	createOrganizationUser,
	toggleOptinFlow,
	toggleNotificationSettings,
	getOrganizations,
	updateOrganizationBilling,
	getOrganizationsByParentId,
	updateOrganizationsOptinKeyword,
	toggleOptinByFirstSms,
	toggleOptinByOrgOptinKeyword,
	toggleRestrictBulkMsgs,
	toggleRestrictAllMsgs,
	toggleAutosendOutboundMsgs,
	updateOrganizationLogo,
	updateOrganizationName,
	updateOrganizationAdvisorName,
}
