import styled, { CSSProperties } from 'styled-components'
import { mainFont } from 'utils/styled'
import { defaultButtonStyle, defaultIconStyle } from './Button.styled'
import { ButtonSizes } from './Button.props'

const BUTTON_PRESETS = [
	'accent',
	'primary',
	'secondary',
	'gradient',
	'danger',
	'inactive',
] as const
export type ButtonPresets = typeof BUTTON_PRESETS[number]
export function isButtonPreset(key: string): key is ButtonPresets {
	return BUTTON_PRESETS.includes(key as ButtonPresets)
}

// Gradient styles
const GradientButton = styled.button`
	${defaultButtonStyle}
	${mainFont}
  background: transparent linear-gradient(96deg, #39B54A 0%, #00A79D 100%) 0% 0% no-repeat padding-box;
	box-shadow: 0px 5px 20px -15px rgba(0, 0, 0, 0.3);
	color: ${({ theme }) => theme.colors.white};

	transition: opacity 0.2s;
	&:hover {
		opacity: 0.9;
	}
`
const GradientRightIcon = (
	theme: any,
	disabled: boolean,
	style: CSSProperties
) => ({
	color: theme.colors.white,
	marginLeft: 12,

	...defaultIconStyle,
	...style,
})
const GradientLeftIcon = (
	theme: any,
	disabled: boolean,
	style: CSSProperties
) => ({
	color: theme.colors.white,
	marginRight: 12,

	...defaultIconStyle,
	...style,
})

// Gradient Danger styles
const DangerButton = styled.button`
	${defaultButtonStyle}
	${mainFont}
	background: ${({ theme, disabled }) =>
		disabled
			? theme.colors.border
			: theme.colors.danger} 0% 0% no-repeat padding-box;
	color: ${({ theme, disabled }) =>
		disabled ? theme.colors.fontSecondary : theme.colors.error};

	transition: opacity 0.2s ease-in;
	&:hover {
		background: ${({ theme, disabled }) =>
				disabled ? theme.colors.border : theme.colors.dangerHover}
			0% 0% no-repeat padding-box;
		color: ${({ theme, disabled }) =>
			disabled ? theme.colors.fontSecondary : theme.colors.white};
	}
	&:focus {
		background: ${({ theme, disabled }) =>
				disabled ? theme.colors.border : theme.colors.dangerClick}
			0% 0% no-repeat padding-box;
		color: ${({ theme, disabled }) =>
			disabled ? theme.colors.fontSecondary : theme.colors.white};
	}
`
const DangerRightIcon = (
	theme: any,
	disabled: boolean,
	style: CSSProperties
) => ({
	color: theme.colors.white,
	marginLeft: 12,

	...defaultIconStyle,
	...style,
})
const DangerLeftIcon = (
	theme: any,
	disabled: boolean,
	style: CSSProperties
) => ({
	color: theme.colors.white,
	marginRight: 12,

	...defaultIconStyle,
	...style,
})

// Primary button styles
const PrimaryButton = styled.button<{
	disabled: boolean
	fullWidth: boolean
	size: ButtonSizes
	opacity?: boolean
}>`
	${defaultButtonStyle}
	${mainFont};
	background: ${({ theme, disabled }) =>
			disabled ? theme.colors.border : theme.colors.white}
		0% 0% no-repeat padding-box;
	border: 2px solid ${({ theme }) => theme.colors.border};
	color: ${({ theme, disabled }) =>
		disabled ? theme.colors.fontSecondary : theme.colors.fontMain};

	${({ disabled, opacity }) =>
		!disabled &&
		`
    transition: opacity 0.2s;
    &:hover {
      opacity: ${opacity ? '0.6' : '1'};
    }
  `}
`
const AccentButton = styled.button<{
	disabled: boolean
	fullWidth: boolean
	size: ButtonSizes
	opacity?: boolean
}>`
	${defaultButtonStyle}
	${mainFont};
	background: ${({ theme, disabled }) =>
			disabled ? theme.colors.border : theme.colors.accentPrimary}
		0% 0% no-repeat padding-box;
	color: ${({ theme, disabled }) =>
		disabled ? theme.colors.fontSecondary : theme.colors.white};
	font-weight: 600;
	fill: ${({ theme, disabled }) =>
		disabled ? theme.colors.fontPlaceholder : theme.colors.white};
	stroke: ${({ theme, disabled }) =>
		disabled ? theme.colors.fontPlaceholder : theme.colors.white};

	${({ disabled, theme }) =>
		!disabled &&
		`
    transition: opacity 0.2s;
    &:hover {
      background-color: ${theme.colors.accentPrimaryHover};
    }
    &:active {
      background-color: ${theme.colors.accentPrimaryPressDown};
    }
  `}
`

const PrimaryRightIcon = (
	theme: any,
	disabled: boolean,
	style: CSSProperties
) => ({
	color: disabled ? theme.colors.fontSecondary : theme.colors.fontMain,
	marginLeft: 12,

	...defaultIconStyle,
	...style,
})
const PrimaryLeftIcon = (
	theme: any,
	disabled: boolean,
	style: CSSProperties
) => ({
	color: disabled ? theme.colors.fontSecondary : theme.colors.fontMain,
	marginRight: 12,

	...defaultIconStyle,
	...style,
})

// Secondary butotn styles
const SecondaryButton = styled.button`
	${defaultButtonStyle}
	${mainFont}
	-webkit-transition: all 50ms cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: all 50ms cubic-bezier(0.25, 0.1, 0.25, 1);
	will-change: transform, -webkit-transform, -ms-transform;
	opacity: 1;
	transition: opacity 0.2s ease 0s;
	background: ${({ theme, disabled }) =>
			disabled ? theme.colors.border : theme.colors.white}
		0% 0% no-repeat padding-box;
	border: 1.3px solid ${({ theme }) => theme.colors.borderInput};
	color: ${({ theme }) => theme.colors.fontMain};
	fill: ${({ theme }) => theme.colors.fontMain};
	stroke: ${({ theme }) => theme.colors.fontMain};

	&:hover {
		background-color: ${({ theme }) => theme.colors.white};
		border: 1.3px solid ${({ theme }) => theme.colors.borderInputSolid};
		color: ${({ theme }) => theme.colors.accentText};
		opacity: 1;
		svg {
			fill: ${({ theme }) => theme.colors.accentText};
			stroke: ${({ theme }) => theme.colors.accentText};
		}
		-webkit-transition: box-shadow 150ms cubic-bezier(0.25, 0.1, 0.25, 1),
			-webkit-transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
		transition: box-shadow 150ms cubic-bezier(0.25, 0.1, 0.25, 1),
			-webkit-transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
		transition: box-shadow 150ms cubic-bezier(0.25, 0.1, 0.25, 1),
			transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
		transition: box-shadow 150ms cubic-bezier(0.25, 0.1, 0.25, 1),
			transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
			-webkit-transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
		-webkit-transform: scale(1.01);
		-ms-transform: scale(1.01);
		transform: scale(1.01);
		box-shadow: 0px 6.5px 9px rgb(0 0 0 / 1%), 0px 4.5px 5.52px rgb(0 0 0 / 2%),
			0px 2.935px 3.435px rgb(0 0 0 / 2%), 0px 1.756px 1.9625px rgb(0 0 0 / 3%),
			0px 0.092px 1.085px rgb(0 0 0 / 3%), 0px 0.38px 0.605px rgb(0 0 0 / 4%),
			0px 0.85px 0.31px rgb(0 0 0 / 5%);
	}

	&:active {
		opacity: 1;
		border: 1.3px solid ${({ theme }) => theme.colors.borderInputSolid};
		color: ${({ theme }) => theme.colors.accentPrimaryPressDown};
		svg {
			fill: ${({ theme }) => theme.colors.accentPrimaryPressDown};
			stroke: ${({ theme }) => theme.colors.accentPrimaryPressDown};
		}
		box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.colors.backgroundMain};
	}

	${({ disabled }) =>
		!disabled &&
		`
		opacity: 1;
    transition: opacity 0.2s;
  `}
`
const SecondaryRightIcon = (
	theme: any,
	disabled: boolean,
	style: CSSProperties
) => ({
	color: theme.colors.fontSecondary,
	marginLeft: 12,
	...defaultIconStyle,
	...style,
})
const SecondaryLeftIcon = (
	theme: any,
	disabled: boolean,
	style: CSSProperties
) => ({
	color: theme.colors.fontSecondary,
	marginRight: 12,
	...defaultIconStyle,
	...style,
})

// Inactive button styles
const InactiveButton = styled.button`
	${defaultButtonStyle}
	${mainFont}

  background: ${({ theme, disabled }) =>
		disabled
			? theme.colors.border
			: theme.colors.border} 0% 0% no-repeat padding-box;
	opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
	border: 2px solid ${({ theme }) => theme.colors.border};
	color: ${({ theme }) => theme.colors.fontSecondary};
	font-weight: normal;

	${({ disabled }) =>
		!disabled &&
		`
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
    }
  `}
`

// Export all presets
export const buttonPresets: Record<
	ButtonPresets,
	{ button: any; rightIcon: any; leftIcon: any }
> = {
	accent: {
		button: AccentButton,
		rightIcon: SecondaryRightIcon,
		leftIcon: SecondaryLeftIcon,
	},
	primary: {
		button: PrimaryButton,
		rightIcon: PrimaryRightIcon,
		leftIcon: PrimaryLeftIcon,
		// mainIcon: Icon
	},
	gradient: {
		button: GradientButton,
		rightIcon: GradientRightIcon,
		leftIcon: GradientLeftIcon,
		// mainIcon: Icon
	},
	danger: {
		button: DangerButton,
		rightIcon: DangerRightIcon,
		leftIcon: DangerLeftIcon,
		// mainIcon: Icon
	},
	secondary: {
		button: SecondaryButton,
		rightIcon: SecondaryRightIcon,
		leftIcon: SecondaryLeftIcon,
		// mainIcon: Icon
	},
	inactive: {
		button: InactiveButton,
		rightIcon: SecondaryRightIcon,
		leftIcon: SecondaryLeftIcon,
		// mainIcon: Icon
	},
}
