import { createAsyncThunk } from '@reduxjs/toolkit'
import campaignService from 'services/campaign/campaign.service'
import {
	CampaignCreateFields,
	CampaignCreateOptinFlowMessageFields,
	CampaignUpdateOptinFlowMessageFields,
	CampaignRecipientMutation,
	UpdateCreateFields,
	CampaignCreateKeywordFields,
	CampaignDeleteOptinFlowMessageFields,
	ArchiveFields,
} from 'services/campaign/campaign.types'
import {
	Campaign,
	CampaignOptinFlowInboundMessage,
	CampaignOptinFlowMessage,
} from 'types/api/campaign.model'

export const createCampaign = createAsyncThunk<Campaign, CampaignCreateFields>(
	'campaign/create',
	campaignService.createCampaign
)

export const createKeywordCampaign = createAsyncThunk<
	Campaign,
	CampaignCreateKeywordFields
>('campaign/createKeyword', campaignService.createKeyWordCampaign)
export const newKeywordCampaign = createAsyncThunk<
	Campaign,
	CampaignCreateKeywordFields
>('campaign/newKeyword', campaignService.newKeywordCampaign)
export const createCampaignRecipient = createAsyncThunk<
	Campaign,
	CampaignRecipientMutation
>('campaign/create-recipient', campaignService.createCampaignRecipient)

export const createCamapignOptinFlowOutboundMessage = createAsyncThunk<
	CampaignOptinFlowMessage,
	CampaignCreateOptinFlowMessageFields
>(
	'campaign/create-optin-flow-outbound-message',
	campaignService.createOptinFlowOutboundMessage
)

export const updateCamapignOptinFlowOutboundMessage = createAsyncThunk<
	CampaignOptinFlowMessage,
	CampaignUpdateOptinFlowMessageFields
>(
	'campaign/update-optin-flow-outbound-message',
	campaignService.updateOptinFlowOutboundMessage
)

export const deleteCamapignOptinFlowOutboundMessage = createAsyncThunk<
	CampaignOptinFlowMessage,
	CampaignDeleteOptinFlowMessageFields
>(
	'campaign/delete-optin-flow-outbound-message',
	campaignService.deleteOptinFlowOutboundMessage
)

export const createCamapignOptinFlowInboundMessage = createAsyncThunk<
	CampaignOptinFlowInboundMessage,
	CampaignCreateOptinFlowMessageFields
>(
	'campaign/create-optin-flow-inbound-message',
	campaignService.createOptinFlowInboundMessage
)

export const updateCamapignOptinFlowInboundMessage = createAsyncThunk<
	CampaignOptinFlowInboundMessage,
	CampaignUpdateOptinFlowMessageFields
>(
	'campaign/update-optin-flow-inbound-message',
	campaignService.updateOptinFlowInboundMessage
)

export const deleteCamapignOptinFlowInboundMessage = createAsyncThunk<
	CampaignOptinFlowInboundMessage,
	CampaignDeleteOptinFlowMessageFields
>(
	'campaign/delete-optin-flow-inbound-message',
	campaignService.deleteOptinFlowInboundMessage
)

export const updateCampaign = createAsyncThunk<Campaign, UpdateCreateFields>(
	'campaign/update',
	campaignService.updateCampaign
)

export const archiveCampaign = createAsyncThunk<Campaign, ArchiveFields>(
	'campaign/archive',
	campaignService.archiveCampaign
)

export const getCampaign = createAsyncThunk(
	'campaign/get-campaign',
	campaignService.getCampaign
)

export const getCampaings = createAsyncThunk(
	'campaing/get',
	campaignService.getCampaigns
)

export const getCampaingsCollections = createAsyncThunk(
	'campaing/get-collections',
	campaignService.getcollectionCampaigns
)

export const sendCampaign = createAsyncThunk(
	'campaing/send',
	campaignService.sendCampaign
)
export const startOptinFlowCampaign = createAsyncThunk(
	'campaing/start-optin-flow-campaign',
	campaignService.startOptinFlowCampaign
)
export const optInContactCampaign = createAsyncThunk(
	'campaing/optin-contact-campaign',
	campaignService.optInContactCampaign
)

export const attachCampaignImage = createAsyncThunk(
	'campaing/attach-image',
	campaignService.attachCampaignImage
)

export const getCampaignMetrics = createAsyncThunk(
	'campaing/metrics',
	campaignService.getCampaignMetrics
)
export const getCampaignMetricsIndividual = createAsyncThunk(
	'campaing/metrics-individual',
	campaignService.getCampaignMetrics
)

export const fetchCampaignConversations = createAsyncThunk(
	'campaing/get/conversations',
	campaignService.getCampaignConversations
)

export const listCampaignConversations = createAsyncThunk(
	'campaing/get/listCampaignConversations',
	campaignService.listCampaignConversations
)

export const getCampaignConversation = createAsyncThunk(
	'campaing/get/conversation',
	campaignService.getCampaignConversation
)

export const listBroadcastResponses = createAsyncThunk(
	'campaign/get/BroadcastResponses',
	campaignService.listBroadcastResponses
)

export const getCamapignOptinFlowOutboundMessages = createAsyncThunk(
	'campaing/get/optinflowoutboundmessages',
	campaignService.getCamapignOptinFlowOutboundMessages
)

export const getCamapignOptinFlowInboundMessages = createAsyncThunk(
	'campaing/get/optinflowinboundmessages',
	campaignService.getCamapignOptinFlowInboundMessages
)

export const duplicateCampaign = createAsyncThunk(
	'campaing/duplicate',
	campaignService.duplicateCampaign
)

export const listTemplates = createAsyncThunk(
	'campaing/list-templates',
	campaignService.listTemplates
)

export const listGoals = createAsyncThunk(
	'campaing/list-goals',
	campaignService.listGoals
)

export const createCampaignTemplate = createAsyncThunk(
	'campaing/create-campaign-template',
	campaignService.createCampaignTemplate
)

export const createKeywordCampaignTemplate = createAsyncThunk(
	'campaing/create-keyword-campaign-template',
	campaignService.createKeywordCampaignTemplate
)
