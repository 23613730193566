import styled from 'styled-components'
import { atMobile, atTabletLarge } from '../../../utils/styled'
import {
	SIDEBR_WIDTH,
	SIDEBR_COLLAPSED_WIDTH,
	SIDEBR_TRANSITION,
} from '../../../@constants/layout'
import {
	AppMenuLabel,
	AppMenuItemContainer,
} from '../AppMenuItem/AppMenuItem.styled'
import {
	PhoneNumberStatusContent,
	PhoneNumberStatusHash,
	PhoneNumberStatusContainer,
} from 'components/PhoneNumberStatus/PhoneNumberStatus.styled'
import { Typography } from 'antd'

export const AppMenuContainer = styled.div<{
	collapsed: boolean
	showBanner: boolean | null
}>`
	--menu-transition: ${SIDEBR_TRANSITION};
	--padding-menu: 12px;
	--negative-padding-menu: calc(-1 * var(--padding-menu));

	background-color: ${p => p.theme.colors.white};
	border-right: 1px solid ${p => p.theme.colors.border};
	bottom: 0;
	left: 0;
	overflow: visible;
	position: fixed;
	top: ${({ showBanner }) => (showBanner ? '4.125rem' : 0)};
	transition: width var(--menu-transition);
	width: ${SIDEBR_WIDTH}px;
	z-index: 101;
	padding-top: var(--padding-menu);
	padding-left: var(--padding-menu);
	padding-right: var(--padding-menu);

	${AppMenuItemContainer} {
		overflow: hidden;
		transition: all var(--menu-transition);
		transition-property: padding, width;
	}

	${AppMenuLabel} {
		transition: opacity var(--menu-transition);
	}

	${PhoneNumberStatusContent} {
		min-width: 9.125rem;
		transition: all var(--menu-transition);
		transition-property: opacity, margin-top;
	}

	${PhoneNumberStatusContainer} {
		overflow: hidden;
		transition: all var(--menu-transition);
		transition-property: padding, padding-bottom, padding-top;
	}

	${PhoneNumberStatusHash} {
		transition: all var(--menu-transition);
		transition-property: height, width, padding;
	}

	${({ collapsed }) =>
		collapsed &&
		`
		width: ${SIDEBR_COLLAPSED_WIDTH}px;

		${AppMenuLogo} {
			left: 0.5rem;
			top: 1.25rem;
			width: 3.875rem;
		}

		${AppMenuItemsContainer} {
			padding-left: 0.25rem;
		}

		${AppMenuItemContainer} {
			height: 2.75rem;
			padding: 0.625rem;
			width: 2.75rem;
		}

		${AppMenuLabel} {
			opacity: 0;
		}

		${AppMenuActions} {
			padding-left: 0.25rem;
		}

		${PhoneNumberStatusContainer} {
			padding: 0 1.125rem;
			padding-bottom: 0.75rem;
			padding-top: 1rem;
		}

		${PhoneNumberStatusHash} {
			height: 2.75rem;
			padding: 0.625rem;
			width: 2.75rem;
		}

		${PhoneNumberStatusContent} {
			margin-top: 0.25rem;
			opacity: 0;
		}
	`}

	${atMobile(`
		display: none;
	`)}
`

export const OnboardingBox = styled.div`
	padding-top: 16px;
`

export const OnboardingContainer = styled.div<{
	collapsed: boolean
}>`
	border: 1px solid #dfe0e3;
	border-radius: 16px;
	box-shadow: 0px 3px 2px 0px #0000000a;

	${({ collapsed }) => !collapsed && `padding: 12px;`}
`

/* Necessary to allow `overflow-y: auto` without hiding the create menu popup
 * https://stackoverflow.com/a/41875208 */
export const AppMenuOverflowWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: calc(100% - 135px);
	overflow-y: auto;
	overflow-x: hidden;
`

export const AppMenuItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
	transition: padding-left var(--menu-transition);
`

export const AppMenuLogoContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 1.5px 0px 0px 2px;
`

export const AppMenuLogoText = styled(Typography.Text)`
	font-family: Calps;
	font-size: 20px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0.04em;
	text-align: left;
	margin-bottom: 18px;
`

export const AppMenuLogo = styled.img`
	left: 0.875rem;
	position: absolute;
	top: 0.875rem;
	transition: all var(--menu-transition);
	transition-property: left, top, width;
	width: 5.25rem;
`

export const AppMenuSpacer = styled.div`
	flex-grow: 1;
	min-height: 2rem;
`

export const AppMenuActions = styled.div`
	display: flex;
	flex-shrink: 0;
	justify-content: space-between;
	margin-bottom: 1rem;
	overflow: hidden;
	padding-left: 0.625rem;
	transition: padding var(--menu-transition);
`

export const AppMenuButtonIcon = styled.div<{
	hover?: boolean
	reverse?: boolean
}>`
	display: block;
	height: 1.25rem;
	width: 1.25rem;

	${({ reverse }) => reverse && `transform: rotate(180deg);`}
`

export const AppMenuButtonIconHover = styled(AppMenuButtonIcon)`
	display: none;
`

export const AppMenuButton = styled.button<{
	border?: boolean
	focus?: boolean
	secondary?: boolean
	square?: boolean
}>`
	align-items: center;
	background: none;
	border-radius: ${({ square }) => (square ? '0.5rem' : '50%')};
	${({ border, theme }) =>
		border && `border: 2px solid ${theme.colors.border};`}
	color: ${({ theme, secondary }) =>
		secondary ? theme.colors.fontSecondary : theme.colors.fontMain};
	cursor: pointer;
	display: flex;
	flex-shrink: 0;
	height: 2.75rem;
	justify-content: center;
	padding: 0;
	transition: margin-top var(--menu-transition);
	width: 2.75rem;

	${({ focus, theme }) => focus && `background: ${theme.colors.border};`}

	& + & {
		margin-left: 1rem;
	}

	&:hover {
		background: ${({ theme }) => theme.colors.border};
		color: ${({ theme }) => theme.colors.fontMain};

		${AppMenuButtonIcon} {
			display: none;
		}

		${AppMenuButtonIconHover} {
			display: block;
		}
	}

	svg {
		width: 1.25rem;
		height: 1.25rem;
	}
`

export const CollapseMenuButtonStyled = styled(AppMenuButton)<{
	hide?: boolean
}>`
	display: none;
	transition: opacity var(--menu-transition);

	${({ hide }) =>
		hide &&
		`
		pointer-events: none;
		opacity: 0;
		`}

	${atTabletLarge(`
		display: flex;
	`)}
`

export const AppMenuPhoneNumberStatusContainer = styled.div`
	margin: 0 var(--negative-padding-menu) var(--negative-padding-menu);
`
