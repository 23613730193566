import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import createAsyncReducers from 'utils/create-async-reducers'
import {
	getTags,
	createTag,
	deleteTag,
	createContactGroupTag,
} from './tag.actions'
import { ITag } from 'types/api/tag.model'

export interface IState {
	tag?: ITag | null
	tagList?: ITag[]
	loading: boolean
	loadingCreate: boolean
	loadingDelete: boolean
}

const initialState: IState = {
	tag: null,
	tagList: [],
	loading: false,
	loadingCreate: false,
	loadingDelete: false,
}

const { reducer, actions } = createSlice({
	name: 'tag',
	initialState,
	reducers: {
		setTagsContact: (state, action: PayloadAction<ITag[]>) => {
			state.tagList = action.payload
		},
	},
	extraReducers: builder => {
		createAsyncReducers(builder, getTags, 'loading', (state, action) => {
			state.tagList = action.payload
		})
		createAsyncReducers(builder, createTag, 'loadingCreate')
		createAsyncReducers(builder, createContactGroupTag, 'loadingCreate')
		createAsyncReducers(builder, deleteTag, 'loadingDelete')
	},
})

export default reducer

export const tagActions = {
	...actions,
	getTags,
	createTag,
	deleteTag,
	createContactGroupTag,
}
