import { createAsyncThunk } from '@reduxjs/toolkit'
import authService from 'services/auth'
import {
	UploadProfileImageMutation,
	UploadProfileImageResponse,
} from 'services/auth/auth.types'
import { RootState } from 'store'

export const login = createAsyncThunk('auth/log-in', authService.login)

export const loginWithCookie = createAsyncThunk(
	'auth/log-in-with-cookie',
	authService.loginWithCookie
)

export const approveUser = createAsyncThunk(
	'auth/approve-user',
	authService.approveUser
)

export const resetPassword = createAsyncThunk(
	'auth/reset-password',
	authService.resetPassword
)

export const getAuthByToken = createAsyncThunk(
	'auth/:token',
	authService.getAuthByToken
)

export const getAuthByTokenForOnBoarding = createAsyncThunk(
	'auth/onboarding/:token',
	authService.getAuthByTokenForOnBoarding
)

export const getMe = createAsyncThunk('auth/me', authService.getMe)

export const getSkContactsCount = createAsyncThunk(
	'auth/skContactsCount',
	authService.getSkContactsCount
)

export const triggerContactSync = createAsyncThunk(
	'auth/triggerContactSync',
	authService.triggerContactSync
)

export const updateProfile = createAsyncThunk(
	'auth/update/profile',
	authService.updateProfile
)

export const uploadProfileImage = createAsyncThunk<
	UploadProfileImageResponse,
	UploadProfileImageMutation
>('auth/upload/image', (query, thunkAPI) => {
	const state = thunkAPI.getState() as RootState
	// User id should always exists if this is called
	const userId = state.auth.auth ? state.auth.auth.id : ''
	return authService.uploadProfileImage({
		...query,
		userId,
	})
})

export const getMyTeam = createAsyncThunk(
	'auth/get/myTeam',
	authService.getMyTeam
)

export const addUserCalendarLink = createAsyncThunk(
	'auth/add/user/calendar/link',
	authService.addUserCalendarLink
)

export const forgotPassword = createAsyncThunk(
	'auth/forgot/password',
	authService.forgotPassword
)

export const getPhoneNumbers = createAsyncThunk(
	'auth/get/phoneNumbers',
	authService.getPhoneNumbers
)

export const orderPhoneNumber = createAsyncThunk(
	'auth/order/phoneNumber',
	authService.orderPhoneNumber
)

export const getListVoipServices = createAsyncThunk(
	'auth/get/listVoipServices',
	authService.getListVoipServices
)

export const attachDocument = createAsyncThunk(
	'auth/attachDocument',
	authService.attachDocument
)

export const assignIonLakeNumber = createAsyncThunk(
	'auth/assign/ionlakenumber',
	authService.assignIonLakeNumber
)
export const getIonLakeNumber = createAsyncThunk(
	'auth/get/ionLakeNumber',
	authService.getIonLakeNumber
)
export const updateOrganizationAddress = createAsyncThunk(
	'auth/update/organization-address',
	authService.updateOrganizationAddress
)
export const verifyBrainSession = createAsyncThunk(
	'auth/verify/brain-session',
	authService.verifyBrainSession
)
export const toggleEmailNotifications = createAsyncThunk(
	'auth/user/toggle/email-notifications',
	authService.toggleEmailNotifications
)
export const updatePersonalPhone = createAsyncThunk(
	'auth/user/update/personal-phone',
	authService.updatePersonalPhone
)
