import { createAsyncThunk } from '@reduxjs/toolkit'
import brandService from 'services/brand/brand.service'

import {
	BrandCreateFields,
	BrandUpdateFields,
} from 'services/brand/brand.types'

import { Brand } from 'types/api/brand.model'

export const createBrand = createAsyncThunk<Brand, BrandCreateFields>(
	'brand/create',
	brandService.createBrand
)
export const updateBrand = createAsyncThunk<Brand, BrandUpdateFields>(
	'brand/update',
	brandService.updateBrand
)
export const getBrand = createAsyncThunk<Brand, {}>(
	'brand/get',
	brandService.getBrand
)
