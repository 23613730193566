import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	updateOnboardingStage,
	getOnboardingStages,
} from './onboarding.actions'
import createAsyncReducers from 'utils/create-async-reducers'
import { OnboardingStages } from 'types/api/onboarding-stages.model'

export interface IState {
	onboardingStages: OnboardingStages | null
	onboardingLoading: boolean
	update_onboarding_loading: boolean
}

const initialState: IState = {
	onboardingStages: null,
	onboardingLoading: false,
	update_onboarding_loading: false,
}

const { reducer, actions } = createSlice({
	name: 'onboarding',
	initialState,
	reducers: {
		updateOnboardingStageState: (
			state,
			action: PayloadAction<OnboardingStages>
		) => {
			state.onboardingStages = action.payload
		},
	},
	extraReducers: builder => {
		createAsyncReducers(
			builder,
			getOnboardingStages,
			'onboardingLoading',
			(state, action) => {
				state.onboardingStages = action.payload
			}
		)
		createAsyncReducers(
			builder,
			updateOnboardingStage,
			'update_onboarding_loading',
			(state, action) => {
				state.onboardingStages = action.payload
			}
		)
	},
})

export default reducer
export const onboardingActions = {
	...actions,
	updateOnboardingStage,
	getOnboardingStages,
}
