import { createGlobalStyle } from 'styled-components'
import 'antd/dist/antd.less'
import { atMobile, atTablet, mainFont } from 'utils/styled'

// TODO: Please do not use @import CSS syntax in createGlobalStyle at this time, as the CSSOM APIs we use in production do not handle it well. Instead, we recommend using a library such as react-helmet to inject a typical <link> meta tag to the stylesheet, or simply embedding it manually in your index.html <head> section for a simpler app.
export const GlobalStyles = createGlobalStyle`
    body {
        font-size: 16px;
        ${atMobile(`
            font-size: 14px;
        `)}

        ${atTablet(`
            font-size: 15px;
        `)}
    }
    
    body > #root {
        height: 100vh;
    }

    button {
        border: none;
    }


    /** React date picker styles **/
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        width: 100%;
        height: 58px;
    }

    // .react-datepicker__input-container input {
    //     background: transparent;
    //     outline: none;
    //     border: none;
    //     font-size: 16px;
    //     letter-spacing: -0.36px;
    //     padding: 0px 23px;
    //     border-radius: 7px;
    //     height: 58px;

    //     background: ${({ theme }) =>
			theme.colors.backgroundMain} 0% 0% no-repeat padding-box;
    //     border: 2px solid ${({ theme }) => theme.colors.border};

    //     ::placeholder {
    //         color: ${({ theme }) => theme.colors.fontSecondary};
    //         opacity: 1;
    //     }

    //     &:focus {
    //         background: ${({ theme }) =>
			theme.colors.white} 0% 0% no-repeat padding-box;
    //         ::placeholder {
    //             text-align: right;
    //         }   
    //     }
    // }

    .react-datepicker, 
    .react-datepicker__current-month,
    .react-datepicker__header,
    .react-datepicker__navigation,
    .react-datepicker__day-name, .react-datepicker__day {
        ${mainFont}
        font-size: 1rem;
    }
    .react-datepicker__day--today {
        background-color: #39B54A;
        font-weight: normal;
    }
    .react-datepicker__day--selected {
        background-color: #00A79D;
        font-weight: bold;
    }

    .react-datepicker__day--today,
    .react-datepicker__day--selected {
        color: white;
        border-radius: 15px;
    }

    .react-datepicker__header {
        background: ${({ theme }) =>
					theme.colors.backgroundMain} 0% 0% no-repeat padding-box;
        border: none;
    }

    .react-datepicker {
        border: 2px solid ${({ theme }) => theme.colors.border};
    }

    .tippy-box[data-theme~='sk-theme'] {
        border-radius: 12px;

        .tippy-content {
            border-radius: 12px;
            overflow: hidden;
            padding: 0;
        }
    }

    .linkified {
        opacity: 1;
        transition: opacity 0.2s;
        cursor: pointer;
        text-decoration: underline;
        &:hover {
            opacity: 0.75;
        }
        width: 100%;
        overflow-wrap: break-word;
        color: #0072ff;
    }`
