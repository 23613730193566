import React from 'react'
import styled from 'styled-components'

export const InputRoot = styled.label<{ fullWidth?: boolean }>`
	${({ fullWidth }) => fullWidth && `width: 100%;`}
`

export const InputStyled = styled.input<{
	touched: boolean
	placeholderFontSize: string
	placeholderFontWeight:
		| '100'
		| '200'
		| '300'
		| '400'
		| '500'
		| '600'
		| '700'
		| '900'
		| 'normal'
		| 'bold'
}>`
	color: ${({ theme }) => theme.colors.fontMainInput};
	border: none;
	outline: none;
	background: transparent;
	font-size: ${({ placeholderFontSize }) =>
		placeholderFontSize ? placeholderFontSize : '0.875rem'};
	letter-spacing: -0.36px;
	min-width: 80px;
	width: 100%;
	${({ theme, disabled }) =>
		disabled &&
		`
			color:  ${theme.colors.fontMainInputDisabled};
			user-select: none;
  	`}
	&:focus {
		::placeholder {
			color: ${({ theme }) => theme.colors.fontScondaryPlaceholder};
		}
	}
	::placeholder {
		color: ${({ theme }) => theme.colors.fontPlaceholder};
		font-size: 0.875rem;
		opacity: 1;
		font-weight: ${({ placeholderFontWeight }) =>
			placeholderFontWeight ? placeholderFontWeight : '400'};
	}
`

export const InputContainer = styled.div<{
	centered?: boolean
	isFocus?: boolean
	touched: boolean
	disabled: boolean
	fullWidth: boolean
	error: boolean
	placeholderVisible?: boolean
}>`
	display: flex;
	height: 44px;
	padding: 0px 18px;
	border-radius: 6px;
	align-items: center;
	min-width: 160px;
	${({ fullWidth }) => fullWidth && `width: 100%;`}
	background: ${({ theme }) => theme.colors.white} 0% 0% no-repeat padding-box;
	border: 1px solid
		${({ theme, error }) =>
			error ? theme.colors.error : theme.colors.borderInput};
	${({ error }) =>
		error
			? `
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		border-bottom: 0px;
	`
			: ``}
	&:hover {
		border-color: ${({ theme, error }) =>
			error ? theme.colors.error : theme.colors.fontSecondary};
	}
	&:focus-within {
		border-color: ${({ theme, error }) =>
			error ? theme.colors.error : theme.colors.fontSecondary};
	}
	${({ theme, disabled }) =>
		disabled
			? `
		border-color: ${theme.colors.border};
		&:hover {
			border-color: ${theme.colors.border};
		}
	`
			: ``}
	${({ placeholderVisible }) =>
		placeholderVisible &&
		`
		padding: 0;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		${InputStyled} {
			z-index: 2;
			height: 100%;
			padding: 0 1.125rem;
		}
	`}
	${({ theme, touched }) =>
		!touched &&
		`
    	background: ${theme.colors.white} 0% 0% no-repeat padding-box;
		border-color: ${theme.colors.fontPlaceholder}
  	`}
	${({ theme, disabled }) =>
		disabled &&
		`
			background: ${theme.colors.backgroundInputDisabled} 0% 0% no-repeat padding-box;
			color: ${theme.colors.fontMainInputDisabled};
	`}


	${({ centered, theme, isFocus }) =>
		centered &&
		`
		background-color: ${isFocus ? theme.colors.white : theme.colors.backgroundMain};
		border: 1px solid ${
			isFocus ? theme.colors.fontTertiary : theme.colors.fontPlaceholder
		};
		justify-content: center;
		align-items: center;
		padding: 0 1vw;
		cursor: pointer;
		transition: all 0.25s ease-out;
		&:hover {
			background-color: ${theme.colors.white};
			border-color: ${theme.colors.fontPlaceholder};
		}


		${InputStyled} {
			padding-left: 8px;
			width: ${isFocus ? '100%' : 'auto'};
			cursor: ${isFocus ? 'text' : 'pointer'};
			flex: ${isFocus ? '0 1 auto' : '0 0'};
			::placeholder {
				color: ${isFocus ? theme.colors.fontPlaceholder : theme.colors.fontSecondary};
				font-size: 12px;
				font-weight: ${isFocus ? '400' : '600'};
			}
		}
	`}
`

export const InputContainerError = styled.div`
	height: 45px;
	background: ${({ theme }) => theme.colors.errorInputBackground};
	border: 1px solid ${({ theme }) => theme.colors.error};
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	display: flex;
	align-items: center;
	gap: 10px;
`

export const LeftTextContainer = styled.div`
	width: 100%;
	margin-left: 8px;
	font-weight: 600;
	color: ${({ theme }) => theme.colors.fontSecondary};
	font-size: 0.875rem;
`

export const InputPlaceholder = styled.div<{
	touched: boolean
	isFill: boolean
}>`
	font-size: 1.125rem;
	line-height: 1.25rem;
	color: ${({ theme }) => theme.colors.fontSecondary};
	font-weight: bold;
	position: absolute;
	border: 1px solid ${({ theme }) => theme.colors.borderInput};
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	left: 1.125rem;
	right: 1.125rem;
	z-index: 1;
	${({ touched, isFill }) =>
		(touched || isFill) &&
		`
			font-size: 0.85rem;
			flex: 1 0 auto;
		  position: static;
			padding: 0 1.125rem 0 0;
			justify-content: flex-end;
  `}
`

export const IconStyle = (
	theme: any,
	style?: React.CSSProperties
): React.CSSProperties => ({
	fontSize: '1.125rem',
	color: theme.colors.fontSecondary,
	...style,
})
