import { createSlice } from '@reduxjs/toolkit'
import createAsyncReducers from 'utils/create-async-reducers'
import { FeatureFlags } from 'types/api/feature-flags.model'
import { getFeatureFlags } from './feature-flags.actions'

interface IState {
	featureFlags: FeatureFlags[]
	loading: boolean
}

const initialState: IState = {
	featureFlags: [],
	loading: false,
}

const { reducer, actions } = createSlice({
	name: 'flags',
	initialState,
	reducers: {},
	extraReducers: builder => {
		createAsyncReducers(
			builder,
			getFeatureFlags,
			'loading',
			(state, action) => {
				state.featureFlags = action.payload
			}
		)
	},
})

export default reducer
export const featureFlagsActions = {
	...actions,
	getFeatureFlags,
}
