import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Brand } from 'types/api/brand.model'
import createAsyncReducers from 'utils/create-async-reducers'
import { createBrand, getBrand, updateBrand } from './brand.action'

interface BrandState {
	brand: Brand | null
	brandLoading: boolean
	error: string | null
}

const initialState: BrandState = {
	brand: null,
	brandLoading: false,
	error: null,
}

const { reducer, actions } = createSlice({
	name: 'brand',
	initialState,
	reducers: {},
	extraReducers: builder => {
		createAsyncReducers(
			builder,
			createBrand,
			'brandLoading',
			(state, action) => {
				state.brand = action.payload
			}
		)
		createAsyncReducers(builder, getBrand, 'brandLoading', (state, action) => {
			state.brand = action.payload
		})
		createAsyncReducers(
			builder,
			updateBrand,
			'brandLoading',
			(state, action) => {
				state.brand = action.payload
			}
		)
	},
})

export default reducer

export const brandActions = {
	...actions,
	createBrand,
	getBrand,
	updateBrand,
}
