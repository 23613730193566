import styled from 'styled-components'
import { atMobile } from 'utils/styled'

export const Container = styled.div`
	display: none;

	${atMobile(`
		display: block;
	`)}
`
export const AppMenuMobileIconImg = styled.span``

export const AppMenuMobileImg = styled.img``
