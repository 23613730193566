import React, { Suspense } from 'react'
import { Spin } from 'antd'
import SpinCustom from 'components/Spin/Spin'
import { Provider as StoreProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Scenes from 'scenes'
import Theme from 'modules/Theme'
import { store, persistor } from 'store'
import { GlobalStyles } from './styles'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'react-datepicker/dist/react-datepicker.css'
import { CHARGEBEE_KEY, CHARGEBEE_SITE } from './@constants'
import AppContext from 'modules/App/AppContext'

Spin.setDefaultIndicator(<SpinCustom />)

declare global {
	interface Window {
		Chargebee: any
	}
}

window.Chargebee.init({
	site: CHARGEBEE_SITE,
	publishableKey: CHARGEBEE_KEY,
})

export default function App() {
	return (
		<Theme>
			<StoreProvider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Suspense fallback={null}>
						<GlobalStyles />
						<AppContext>
							<Scenes />
						</AppContext>
					</Suspense>
				</PersistGate>
			</StoreProvider>
		</Theme>
	)
}
