import styled from 'styled-components'
import { atMobile, atSmallMobile } from 'utils/styled'

//PROPS TYPE
import { Colors } from 'modules/Theme/styled'

//COMPONENTS
import { Text } from 'components/Text'

type AppMenuStatusColors = {
	color: Colors
	backgroundColor: Colors
}

const appBnnerStatusColorMap: Record<string, AppMenuStatusColors> = {
	phone_transfer_pending: {
		color: 'white',
		backgroundColor: 'warning',
	},
	phone_transfer_error: {
		color: 'white',
		backgroundColor: 'error',
	},
}

export const AppBannerStatusContainer = styled.div<{ status?: string | null }>`
	top: 0;
	left: 0;
	width: 100%;
	position: fixed;
	height: 4.125rem;
	z-index: 101;
	display: grid;
	grid-template: 1fr / 5.5rem 1fr 5.5rem;
	grid-gap: 0 1rem;
	padding: 0.5rem 1rem;
	align-items: center;

	${({ status, theme }) => `
		background-color: ${
			status
				? theme.colors[appBnnerStatusColorMap[status].backgroundColor]
				: theme.colors.error
		};
	`}

	${atMobile(`
    	 grid-template: 1fr / 1fr;
  `)}
	${atSmallMobile(`
      height:5.6875rem;
  `)}
`
export const AppBannerStatusContent = styled.div``
export const AppBannerStatusText = styled(Text)`
	font-size: 0.875rem;
	text-align: center;
	strong {
		cursor: pointer;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	${atMobile(`
		font-size: 1rem;
  `)}
	display: flex;
	align-items: center;
	width: 100vw;
	justify-content: center;
`
export const AppBannerStatusImage = styled.img`
	height: 2.625rem;
	${atMobile(`
      display: none
  `)}
`
