import { createSlice } from '@reduxjs/toolkit'
import createAsyncReducers from 'utils/create-async-reducers'
import { getTrendingGif, searchGif } from './giphy.actions'
import { GiphyGifs } from 'services/giphy/giphy.types'

export interface IState {
	giphyGifs: GiphyGifs[]
	loading: boolean
}

const initialState: IState = {
	giphyGifs: [],
	loading: false,
}

const { reducer, actions } = createSlice({
	name: 'giphy',
	initialState,
	reducers: {},
	extraReducers: builder => {
		createAsyncReducers(
			builder,
			getTrendingGif,
			'loading',
			(state, actions) => {
				state.giphyGifs = actions.payload
			}
		)
		createAsyncReducers(builder, searchGif, 'loading', (state, actions) => {
			state.giphyGifs = actions.payload
		})
	},
})

export default reducer

export const giphyActions = {
	...actions,
	getTrendingGif,
	searchGif,
}
