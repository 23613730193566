enum STORAGE_KEYS {
	Token = 'AUTH_TOKEN',
}

class CredentialsService {
	getAuthToken() {
		return localStorage.getItem(STORAGE_KEYS.Token);
	}

	saveAuthToken(token: string) {
		return localStorage.setItem(STORAGE_KEYS.Token, token);
	}

	removeAuthToken() {
		return localStorage.removeItem(STORAGE_KEYS.Token);
	}
}

export default new CredentialsService();
