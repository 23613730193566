import { defaultTextStyles } from './Text.styled'

const TEXT_PRESETS = [
	'huge',
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
	'h7',
	'h8',
	'p',
	'small',
	'tiny',
	'link',
	'body1',
] as const
export type TextPresets = typeof TEXT_PRESETS[number]
export function isTextPreset(key: string): key is TextPresets {
	return TEXT_PRESETS.includes(key as TextPresets)
}

const huge = defaultTextStyles('5.5rem', '-1.32px')
const h1 = defaultTextStyles(
	'4rem',
	'-.03em',
	`font-family:Calps!important; font-weight:300!important; line-height: 1.25;`
)
const h2 = defaultTextStyles(
	'3rem',
	'-.025em',
	`font-family:Calps!important; font-weight:400!important; line-height: 1.25;`
)
const h3 = defaultTextStyles(
	'2rem',
	'-.02em',
	`font-family:Calps!important; font-weight:500!important; line-height: 1.25;`
)
const h4 = defaultTextStyles('1.5rem', '-0.01em')
const h5 = defaultTextStyles('1.3rem', '-0.02em')
const h6 = defaultTextStyles('1rem', '-0.32px')
const h7 = defaultTextStyles('1.3rem', '-0.02em')
const h8 = defaultTextStyles(
	'.85rem',
	'0.01em',
	`font-family:Calps!important; font-weight:600!important; text-transform:uppercase; line-height: 1;`
)
const p = defaultTextStyles('1rem', '-0.01em')
const small = defaultTextStyles('0.875rem', '-0.01em')
const tiny = defaultTextStyles('0.75rem', '-0.24px')
const link = defaultTextStyles(
	'0.875rem',
	'-0.28px',
	`
  opacity: 1;
  transition: opacity 0.2s;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    opacity: 0.75;
  }
`
)
const body1 = defaultTextStyles('1.125rem', '-0.01em')

export const textPresets: Record<TextPresets, any> = {
	huge,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	h7,
	h8,
	p,
	small,
	tiny,
	link,
	body1,
}
