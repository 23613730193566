import React, { useCallback } from 'react'

import {
	TabBarContainer,
	TabBarItemContainer,
	TabBarItemIcon,
	TabBarItemLabel,
} from './TabBar.styled'
import { ITabBarProps, ITabBarItemProps } from './TabBar.props'
import { useRouteMatch } from 'react-router-dom'

const TabBarItem = <T extends string>({
	disabled,
	label,
	value,
	href,
	Icon,
	ActiveIcon,
	onPress,
}: ITabBarItemProps<T>) => {
	const isActive = !!useRouteMatch(href)

	const handlePress = useCallback(() => onPress(value), [onPress, value])
	const Image = isActive ? ActiveIcon : Icon

	return (
		<TabBarItemContainer
			$disabled={!!disabled}
			$active={isActive}
			onClick={handlePress}
			to={disabled ? '#' : href}
		>
			<TabBarItemIcon>
				<Image />
			</TabBarItemIcon>
			<TabBarItemLabel>{label}</TabBarItemLabel>
		</TabBarItemContainer>
	)
}

export const TabBar = <T extends string>({
	active,
	items,
	onChange,
}: ITabBarProps<T>) => {
	const renderItem = useCallback(
		item => (
			<TabBarItem<T>
				key={item.value}
				active={active}
				{...item}
				onPress={onChange}
			/>
		),
		[onChange, active]
	)

	return <TabBarContainer>{items.map(renderItem)}</TabBarContainer>
}
