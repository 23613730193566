import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import createAsyncReducers from 'utils/create-async-reducers'
import { getSystemNumber } from './system-number.actions'
import { SystenNumberResponse } from 'services/system-number/system-number.types'

interface SystemNumberState {
	systemNumbersList: SystenNumberResponse[]
	loading: boolean
}

const initialState: SystemNumberState = {
	systemNumbersList: [],
	loading: false,
}

const { reducer, actions } = createSlice({
	name: 'systemnumbers',
	initialState,
	reducers: {},
	extraReducers: builder => {
		createAsyncReducers(
			builder,
			getSystemNumber,
			'loading',
			(state, action: PayloadAction<SystenNumberResponse[]>) => {
				state.systemNumbersList = action.payload
			}
		)
	},
})

export default reducer

export const systemNumberActions = {
	...actions,
	getSystemNumber,
}
