import { gql } from '@apollo/client'
import ApiService from 'services/api'
import { METRICS } from './metric.gpl'
// Models
import { IMetric, IMetricData } from './metric.type'

class MetricService {
	private GET_USER_METRICS = gql`
		query fetchUserMetrics(
			$beginningOfCurrent: ISO8601DateTime!
			$endOfCurrent: ISO8601DateTime!
			$beginningOfLast: ISO8601DateTime!
			$endOfLast: ISO8601DateTime!
		) {
			current: fetchUserMetrics(
				startDate: $beginningOfCurrent
				endDate: $endOfCurrent
			) {
				${METRICS}
			}
			prev: fetchUserMetrics(
				startDate: $beginningOfLast
				endDate: $endOfLast
			) {
				${METRICS}
			}
		}
	`

	getUserMetrics = async (fields: IMetric): Promise<IMetricData> => {
		return await ApiService.client
			.query({
				query: this.GET_USER_METRICS,
				variables: fields,
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data!
			})
	}
}

export default new MetricService()
