import { css } from 'styled-components'
import { ButtonSizes } from './Button.props'

const sizes: Record<
	ButtonSizes,
	Record<'height' | 'fontSize' | 'padding', string>
> = {
	small: {
		height: '34px',
		fontSize: '14px',
		padding: '0 1.25rem',
	},
	medium: {
		height: '44px',
		fontSize: '18px',
		padding: '0 1.75rem',
	},
	large: {
		height: '58px',
		fontSize: '18px',
		padding: '0 1.75rem',
	},
	xlarge: {
		height: '58px',
		fontSize: '18px',
		padding: '0 1.75rem',
	},
}

export const defaultButtonStyle = css<{
	fullWidth: boolean
	size: ButtonSizes
}>`
	${({ fullWidth }) => fullWidth && 'width: 100%;'}

	background: none;
	border: none;
	cursor: pointer;
	font-size: ${({ size }) => sizes[size].fontSize};
	font-weight: 700;
	letter-spacing: -0.5px;
	border-radius: 7px;
	opacity: 1;

	display: flex;
	align-items: center;
	justify-content: center;
	height: ${({ size }) => sizes[size].height};
	padding: ${({ size }) => sizes[size].padding};

	:disabled {
		cursor: not-allowed;
		background: ${({ theme }) => theme.colors.disabled};
		color: ${({ theme }) => theme.colors.borderInput};
	}
`

export const defaultIconStyle = {
	fontSize: '17px',
}
