import styled from 'styled-components'
import { AppMenuButton } from '../AppMenu.styled'
import { Text } from 'components/Text'

export const CreateMenuButtonStyled = styled(AppMenuButton)`
	svg {
		height: 1rem;
		width: 1rem;
	}
`

export const CreateMenuContainer = styled.div`
	width: 21.25rem;
	overflow: hidden;
`

export const CreateMenuItems = styled.div``

export const CreateMenuItem = styled.div`
	padding: 0.375rem;

	& + & {
		border-top: 1px solid ${({ theme }) => theme.colors.border};
	}
	background-color: ${({ theme }) => theme.colors.white};
`

export const CreateMenuItemButton = styled.a`
	align-items: center;
	border-radius: 0.375rem;
	cursor: pointer;
	display: flex;
	padding: 1rem 1vw;
	fill: ${({ theme }) => theme.colors.fontMain};

	&:hover {
		background: ${({ theme }) => theme.colors.accentPrimary};
		color: white;
		fill: white;
	}

	svg:first-of-type {
		margin-right: 1rem;
	}

	svg:nth-of-type(2) {
		margin-left: auto;
	}
`

export const CreateMenuItemBody = styled.div``

export const CreateMenuItemTitle = styled.b<{ only?: boolean }>`
	display: block;
	font-family: 'calpsmedium', sans-serif;
	font-size: 1.625rem;
	line-height: 1.875rem;
	margin-bottom: 0.25rem;
	${({ only }) =>
		only &&
		`
		font-size: 1.125rem;
	    font-family: inherit;
		font-weight: bold;
		margin-bottom: 0;
	`}
`

export const CreateMenuItemText = styled.b`
	font-size: 0.875rem;
	font-weight: 500;
`

export const CreateMenuHeader = styled.div`
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.colors.border};
	display: flex;
	height: 2.75rem;
	justify-content: center;
	position: relative;
	background: ${({ theme }) => theme.colors.white};
`

export const CreateMenuHeaderTitle = styled.b`
	bottom: 1px;
	font-weight: 600;
	font-size: 0.875rem;
	position: relative;
`

export const CreateMenuBackButton = styled.button`
	align-items: center;
	background: none;
	border-radius: 0.375rem;
	color: ${({ theme }) => theme.colors.fontSecondary};
	cursor: pointer;
	display: flex;
	font-size: 0.75rem;
	font-weight: bold;
	height: 2.125rem;
	width: 5.375rem;
	justify-content: center;
	left: 0.375rem;
	padding: 0;
	position: absolute;

	&:hover {
		background: ${({ theme }) => theme.colors.border};
		color: ${({ theme }) => theme.colors.fontMain};
	}

	svg {
		height: 0.75rem;
		margin-right: 0.375rem;
		width: 0.75rem;
	}
`
