import { BRAND } from 'services/brand/brand.gpl'

export const CSP_CAMPAIGN = `
    id
    autoRenewal
    status
    cspBrandId
    cspCampaignId
    description
    helpKeyword
    helpMessage
    messageFlow
    optInKeyword
    optInMessage
    optOutKeyword
    optOutMessage
    sample1
    sample2
    sample3
    sample4
    sample5
    subUseCases
    subscriberHelp
    subscriberOptIn
    subscriberOptOut
    useCase
    vertical
    brand {
        ${BRAND}
    }
    sharingStatus
	vettingStatus
	vettingError
	ATT
	TMO
	CLS
	USC
	INT
    lastUpdatedAt
`
