import { PLAN } from '../plan/plan.gpl'
import { PAYMENT_METHOD_CHARGEBEE } from 'services/gpl/payment-method'

export const ORGANIZATION_USERS = `
  users{
    id
    email
    photo
    token
    firstName
    lastName
    calendarLink
    calendarLinkOptinFlow
    questionnaireAnswers
    phone
    personalPhoneNumber
    phoneTransferStatus
    skVesselId
    createdAt
    createdAt
    contactSync
    lastContactsImported
    receiveNotifications
    userRoles {
      id
      role {
        id
        name
      }
    }
  }
`

export const BILLING_ADDRESS = `
  line1
  line2
  line3
  city
  state
  country
  zip
  validationStatus
`

export const ADDRESS = `
  addressType
  city
  country
  line1
  line2
  line3
  state
  zip
  validationErrorCode
  validationErrorDescription
  validationErrorMessage
  validationStatus
`

export const ORGANIZATION = `
  id
  name
  legalName
  lat
  long
  phone
  address{
    ${ADDRESS}
  }
  billingAddress {
    ${BILLING_ADDRESS}
  }
  contactsCount
  contactsCountCurrentWeek
  messagesCount
  optinFlowEnabled
  currentPlan
  subscriptionCancelDate
  ${ORGANIZATION_USERS}
  allowNewNumber
  optinTriggerKeyword
  optinUserByFirstSms
  optinUserByKeyword
  restrictBulkMsgsPendingOptins
  restrictAllMsgsPendingOptins
  autosendOutboundMsgsPendingOptins
  parentOrganization{
    id
    phone
    logo
    name
    advisorFirstName
	  advisorLastName
  }
  isOptinFlowEditingAllowed
  isOptinSettingsEditingAllowed
  organizationUrl
  logo
  owner {
    id
    firstName
    lastName
    email
  }
  receiveNotifications
  advisorFirstName
	advisorLastName
`
