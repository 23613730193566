import React, { memo, useState } from 'react'
import { translate } from 'i18n'
import { scenes } from 'scenes/scenes'
import { TabBar } from 'components/TabBar'
import { ITabBarItem } from 'components/TabBar/TabBar.props'
import { Container } from './AppMenuMobile.styled'
import { useAppState } from 'store'

import { ReactComponent as ContactsActiveIcon } from 'assets/icons/contacts-people.svg'
import { ReactComponent as ContactsIcon } from 'assets/icons/contacts-people-outline.svg'
import { ReactComponent as MessagesActiveIcon } from 'assets/icons/menu-messages-active.svg'
import { ReactComponent as MessagesIcon } from 'assets/icons/menu-messages.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/menu-settings.svg'

enum Tabs {
	Messages = 'Messages',
	Contacts = 'Contacts',
	Settings = 'Settings',
}

export default memo(() => {
	const [selectedTab, setSelectedTab] = useState(Tabs.Messages)
	const user = useAppState(state => state.auth.user)

	const tabs: ITabBarItem<Tabs>[] = [
		{
			Icon: MessagesIcon,
			ActiveIcon: MessagesActiveIcon,
			label: translate('appMenu.messagesLabel'),
			value: Tabs.Messages,
			href: scenes.messages.url({ screen: 'primary' }),
			disabled: user
				? user.phoneTransferStatus !== 'phone_transfer_success'
				: true,
		},
		{
			Icon: ContactsIcon,
			ActiveIcon: ContactsActiveIcon,
			label: translate('appMenu.contactsLabel'),
			value: Tabs.Contacts,
			href: scenes.contacts.url(),
		},
		{
			Icon: SettingsIcon,
			ActiveIcon: SettingsIcon,
			label: translate('appMenu.settingsLabel'),
			value: Tabs.Settings,
			href: scenes.settings.url,
		},
	]

	return (
		<Container>
			<TabBar<Tabs>
				active={selectedTab}
				items={tabs}
				onChange={setSelectedTab}
			/>
		</Container>
	)
})
