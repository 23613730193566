import styled from 'styled-components'
import { atDesktop } from 'utils/styled'

//PROPS TYPE
import { Colors } from 'modules/Theme/styled'

//COMPONENTS
import { Text } from 'components/Text'
import { Button } from 'components/Button/Button'

export const ImpersonatingStatusContainer = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	position: fixed;
	min-height: 55px;
	height: fit-content;
	z-index: 101;
	display: grid;
	/* padding: 0.5rem 1rem; */

	align-items: center;

	background: ${({ theme }) => theme.colors.warningBackground};
	color: ${({ theme }) => theme.colors.black};
`
export const ImpersonatingStatusContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	${atDesktop(`
		gap: 2px;
	`)}
`

export const ImpersonatingStatusText = styled(Text)`
	font-size: 0.875rem;
	font-weight: 400;
	text-align: center;
	flex-wrap: wrap;
	strong {
		cursor: pointer;
	}
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
`
export const ImpersonatingStatusButton = styled(Button)`
	background: ${({ theme }) => theme.colors.warningPrimary};
	color: ${({ theme }) => theme.colors.black};
	border: none;
	font-size: 0.75rem;
	font-weight: 600;
	height: 30px;
`
