import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IImage, PurgeFile } from 'services/upload-files/upload-files.types'
import {
	uploadImage,
	uploadFile,
	purgeFile,
	attachMessageImage,
	attachSignature,
} from './upload-files.actions'
import toast from 'cogo-toast'
import createAsyncReducers from 'utils/create-async-reducers'

interface IState {
	image: IImage | null
	file: IImage | null
	purge: PurgeFile | null
	loading: boolean
}

const initialState: IState = {
	image: null,
	file: null,
	purge: null,
	loading: false,
}

export const { reducer, actions } = createSlice({
	name: 'uploadFiles',
	initialState,
	reducers: {
		setImage(state, action: PayloadAction<IImage>) {
			state.image = action.payload
		},
	},
	extraReducers: builder => {
		createAsyncReducers(builder, uploadImage, 'loading', (state, action) => {
			state.image = action.payload
			toast.success('The upload image was created successfully', {
				position: 'top-right',
			})
		})
		createAsyncReducers(builder, uploadFile, 'loading', (state, action) => {
			state.file = action.payload
		})
		createAsyncReducers(builder, purgeFile, 'loading', (state, action) => {
			state.purge = action.payload
		})
		createAsyncReducers(
			builder,
			attachMessageImage,
			'loading',
			(state, action) => {}
		)
		createAsyncReducers(
			builder,
			attachSignature,
			'loading',
			(state, action) => {
				toast.success('Signatures attached successfully', {
					position: 'top-right',
				})
			}
		)
	},
})

export default reducer
export const uploadFilesActions = {
	...actions,
	uploadImage,
	uploadFile,
	purgeFile,
	attachMessageImage,
	attachSignature,
}
