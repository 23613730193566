import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { DashboardMenuSvg } from '../AppHeader/styled'
import ConvDash from 'assets/icons/convos-dashboard.svg'

interface DashProps {
	hover: boolean
	svgSrc: any
	svgSrcColored: any
}

const DashLogo: React.FC<DashProps> = ({ hover, svgSrc, svgSrcColored }) => {
	return (
		<div>
			{hover ? (
				<DashboardMenuSvg src={svgSrcColored} />
			) : (
				<DashboardMenuSvg src={svgSrc} />
			)}
		</div>
	)
}

const Logo = styled.img``

export default DashLogo
