import api from 'services/api'
import { gql } from '@apollo/client'
import { UpdateQuestionnaireAnswersMutation } from './questionnaire-answers.types'

export class QuestionnaireAnswersService {
	private UPDATE_QUESTIONNAIRE_ANSWERS = gql`
		mutation UpdateQuestionnaireAnswers(
			$input: UpdateQuestionnaireAnswersInput!
		) {
			updateQuestionnaireAnswers(input: $input)
		}
	`
	updateQuestionnaireAnswers = async (
		input: UpdateQuestionnaireAnswersMutation
	): Promise<Record<string, unknown>> => {
		return api.client
			.mutate<
				Record<string, unknown>,
				{ input: UpdateQuestionnaireAnswersMutation }
			>({
				mutation: this.UPDATE_QUESTIONNAIRE_ANSWERS,
				variables: { input },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data
			})
	}
}

export default new QuestionnaireAnswersService()
