import { createSlice } from '@reduxjs/toolkit'
import createAsyncReducers from 'utils/create-async-reducers'
import { getInvoices } from './invoices.actions'
import { Invoice } from 'types/api/invoice.model'

export interface IState {
	invoice?: Invoice | null
	invoiceList?: Invoice[]
	loading: boolean
}

const initialState: IState = {
	invoice: null,
	invoiceList: [],
	loading: false,
}

const { reducer, actions } = createSlice({
	name: 'payment-sources',
	initialState,
	reducers: {},
	extraReducers: builder => {
		createAsyncReducers(builder, getInvoices, 'loading', (state, action) => {
			state.invoiceList = action.payload
		})
	},
})

export default reducer

export const invoicesActions = {
	...actions,
	getInvoices,
}
