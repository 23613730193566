import styled from 'styled-components'
import { Text } from 'components/Text'
import { Button } from 'components/Button/Button'
import SVG from 'react-inlinesvg'

export const AutomatedMessagesHeader = styled.div`
	padding: 3vh 3vw;
	background: ${({ theme }) => theme.colors.white};
	min-height: 20vh;
`

export const AutomatedMessagesSubTitle = styled(Text)`
	font-size: 1rem;
	line-height: 1.25rem;
	color: ${({ theme }) => theme.colors.fontMain};
	margin: 1.5rem 0;
	max-width: 34rem;
`

export const AutomatedMessagesContainer = styled.div`
	background: ${({ theme }) => theme.colors.white};
	height: 100%;
	overflow-y: fixed;
`

export const AutomatedMessagesContent = styled.div`
	width: 100%;
	height: 100%;
	align-items: flex-start;
	gap: 0;
`
export const AutomatedMessagesLogo = styled.img`
	width: 4.5rem;
	max-width: 4.5rem;
	margin-right: 1.5rem;
`
export const AutomatedMessagesItemContainer = styled.button`
	cursor: pointer;
	display: flex;
	padding: 3rem 3vw;
	border: solid 1px ${({ theme }) => theme.colors.border};
	width: 100%;
	justify-content: space-between;
	transition: 0.3s;
	background: ${({ theme }) => theme.colors.backgroundMain};
	&:hover {
		background: ${({ theme }) => theme.colors.white};
		box-shadow: 0px 5px 20px -15px rgba(0, 0, 0, 0.3);
	}
`

export const AutomatedMessagesItemTitle = styled(Text)`
	font-size: 1.625rem;
	line-height: 1.625rem;
	margin: 0.25rem 0 0.75rem;
`
export const AutomatedMessagesItemSubTitle = styled(Text)`
	font-size: 1rem;
	line-height: 1.4rem;
	color: ${({ theme }) => theme.colors.fontMain};
	max-width: 32.75rem;
	margin: 0 0 1rem;
`
export const AutomatedMessagesApproved = styled.div`
	display: flex;
	align-items: center;
	gap: 0 1rem;
`
export const AutomatedMessagesApprovedTag = styled(Text)`
	border: none;
	padding: 0.5rem 0.875rem 0.45rem;
	border-radius: 2rem;
	font-size: 0.75rem;
	line-height: 0.75rem;
	font-weight: 700;
	font-family: Calps !important;
	letter-spacing: 0.75px;
	user-select: none;
	background-color: ${({ theme }) => theme.colors.fontMain};
	height: auto;
	color: ${({ theme }) => theme.colors.white};
	display: inline-block;
`

export const AutomatedMessagesLastModified = styled(Text)`
	font-size: 0.75rem;
	line-height: 0.75rem;
	color: ${({ theme }) => theme.colors.fontSecondary};
`
export const AutomatedMessagesActive = styled(Text)<{ $active?: boolean }>`
	font-size: 0.75rem;
	line-height: 0.75rem;
	color: ${({ theme, $active }) =>
		$active ? theme.colors.fontMain : theme.colors.fontSecondary};
	display: flex;
	align-items: center;
	& > strong {
		align-self: center;
	}
`

export const AutomatedMessagesActiveDot = styled.div<{ $active?: boolean }>`
	border-radius: 15px;
	width: 8px;
	height: 8px;
	background-color: ${({ theme, $active }) =>
		$active ? theme.colors.activeDotColor : theme.colors.fontMainInputDisabled};
	margin-right: 5px;
`

export const AutomatedMessagesMonth = styled.span`
	font-size: 0.75rem;
	line-height: 0.75rem;
	color: ${({ theme }) => theme.colors.fontSecondary};
	font-weight: 600;
	text-transform: capitalize;
`
export const AutomatedMessagesTime = styled(AutomatedMessagesMonth)`
	text-transform: normal;
`

export const AutomatedMessagesInnerButton = styled(Button)`
	font-size: 18px;
	background-color: transparent;
	border: none;
	height: 100%;
	width: 100%;
	padding: 0;
	justify-content: left;
	align-items: flex-start;
	box-shadow: none;
	&:hover {
		opacity: 1;
	}
`
export const AutomatedMessagesEditButton = styled(Button)`
	font-size: 18px;
	padding: 17px;
	&:hover {
		color: #00a79d;
		opacity: 1;
		svg {
			path {
				stroke: ${({ theme }) => theme.colors.accentPrimary};
			}
		}
	}
	box-shadow: none;
`

export const AutomatedMessagesEditIconSvg = styled(SVG)`
	margin: 0 0.625rem 0 0;
`
export const AutomatedMessagesEditIcon = styled.img`
	margin-right: 11px;
	&:hover {
		color: #00a79d;
	}
`

export const EditButtonContainer = styled.div`
	padding: 0.5rem 5vw;
`
