import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'

const PageLoaderContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

const PageLoader = () => (
	<PageLoaderContainer>
		<Spin />
	</PageLoaderContainer>
)

export default PageLoader
