export function formatUSNumber(phone: string): string {
	const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/)
	if (match) return match[1] + '.' + match[2] + '.' + match[3]
	return phone
}

export function cleanAndformatUSNumber(phone: string): string {
	let digits = phone.replace(/\D/g, '')
	if (digits?.length === 10) {
		const formattedNumber = digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3')
		return formattedNumber
	} else {
		return phone
	}
}

export function formatPhoneNumber(phone: string): string {
	if (typeof phone !== 'string') return ''
	if (phone === '') return ''
	const auxPhone = phone.replace(/[^\d+]/g, '')
	const includeCodePhone = /\+/g.test(auxPhone) // +1 000-000-0000
	let regex = /(\+\d)/g

	// Build Regex
	if (includeCodePhone) {
		if (auxPhone.length === 12) {
			regex = /(\+\d{1,1})/g // +1
		} else if (auxPhone.length === 13) {
			regex = /(\+\d{1,2})/g // +12
		} else if (auxPhone.length === 14) {
			regex = /(\+\d{1,3})/g // +123
		} else if (auxPhone.length > 15) {
			regex = /(\+\d-\d{1,3})/g // +1-123
		}
	}

	// only allows 0-9 inputs
	const replaceCode = phone.replace(regex, '')
	const currentValue = replaceCode.replace(/[^\d]/g, '')
	const cvLength = currentValue.length

	// returns: "x", "xx", "xxx"
	if (cvLength < 4) return currentValue

	// returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
	if (cvLength < 7)
		return `${currentValue.slice(0, 3)}.${currentValue.slice(3)}`

	// returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
	return `${currentValue.slice(0, 3)}.${currentValue.slice(
		3,
		6
	)}.${currentValue.slice(6, 10)}`
}

export function validatePhone(input: string): boolean {
	let digits = input?.replace(/\D/g, '')
	return digits?.length === 10
}
