export const IMAGE = `
    blobId
    clientMutationId
    headers
    signedBlobId
    url
`

export const FILE = `
    blobId
    clientMutationId
    headers
    signedBlobId
    url
`

export const ATTACH_IMAGE = `
    clientMutationId
    message {
        id
        photo
    }
`
