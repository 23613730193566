import { AppRoute } from 'types'

enum APP_ROUTES_KEYS {
	RedirectRoute = 'REDIRECT_ROUTE',
}

class AppRoutesService {
	getRedirectRoute(): AppRoute | undefined {
		return localStorage.getItem(APP_ROUTES_KEYS.RedirectRoute)
			? (JSON.parse(
					localStorage.getItem(APP_ROUTES_KEYS.RedirectRoute) as string
			  ) as AppRoute)
			: undefined
	}

	saveRedirectRoute(route: AppRoute) {
		return localStorage.setItem(
			APP_ROUTES_KEYS.RedirectRoute,
			JSON.stringify(route)
		)
	}

	removeRedirectRoute() {
		return localStorage.removeItem(APP_ROUTES_KEYS.RedirectRoute)
	}
}

export default new AppRoutesService()
