export const INVOICES = `
  card
  billingAddress
  paid_at
  plan
  status
  sub_total
  total
  tax
`
