import { gql } from '@apollo/client'
import ApiService from 'services/api'
import { GetFeatureFlagsResponse } from './feature-flags.types'

export class FeatureFlagsService {
	private GET_FEATURE_FLAGS = gql`
		query getFeaturedFlags {
			getFeaturedFlags {
				name
				enabled
			}
		}
	`

	getFeaturedFlags = async (): Promise<GetFeatureFlagsResponse[]> => {
		return await ApiService.client
			.query<{ getFeaturedFlags: GetFeatureFlagsResponse[] }>({
				query: this.GET_FEATURE_FLAGS,
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response.data && response.data.getFeaturedFlags
			})
	}
}

export default new FeatureFlagsService()
