import React, { ReactElement, useState, useEffect } from 'react'
import {
	HeroAreaContainer,
	HeroAreaSubTitle,
	HeroAreaIcon,
} from './HeroArea.styled'
import { Text } from 'components/Text'
import { CSSProperties } from 'styled-components'

type HeroAreaProps = {
	title: string
	TitleIcon?: string
	subtitle: string | ReactElement
	rightComponent?: ReactElement
	secondarySubtitle?: string
	buttonRow?: ReactElement
	backButton?: ReactElement
	fixedBar?: ReactElement
	style?: CSSProperties
}

export const HeroArea = ({
	title,
	TitleIcon,
	subtitle,
	rightComponent,
	secondarySubtitle,
	buttonRow,
	backButton,
	fixedBar,
	style,
	...props
}: HeroAreaProps) => {
	const [width, setWidth] = useState(window.innerWidth)

	useEffect(() => {
		window.addEventListener('resize', () => setWidth(window.innerWidth))
		return () =>
			window.removeEventListener('resize', () => setWidth(window.innerWidth))
	}, [width])

	return (
		<HeroAreaContainer {...props} style={style}>
			<div>
				<Text fontFamily="calpsLightFamily" preset="h1">
					{title}
				</Text>
				{TitleIcon && <HeroAreaIcon src={TitleIcon} />}
				{width > 750 && <HeroAreaSubTitle>{subtitle}</HeroAreaSubTitle>}
				{secondarySubtitle && width > 750 && (
					<HeroAreaSubTitle>{secondarySubtitle}</HeroAreaSubTitle>
				)}
			</div>
			<>{rightComponent}</>
			<>{buttonRow}</>
			<>{backButton}</>
			<>{fixedBar}</>
		</HeroAreaContainer>
	)
}
