import React, { useState, useContext, forwardRef } from 'react'
import SVG from 'react-inlinesvg'
import { translate } from 'i18n'
import { Text } from 'components/Text'
import { ThemeContext } from 'styled-components'
import { InputProps } from './Input.props'
import AlertRedIcon from 'assets/icons/alert-red-icon.svg'
import CheckRoundIcon from 'assets/icons/check-round-icon.svg'

import {
	InputRoot,
	InputContainer,
	InputStyled,
	InputPlaceholder,
	IconStyle,
	InputContainerError,
	LeftTextContainer,
} from './Input.styled'
import EyeHideIcon from 'assets/icons/eye-hide.svg'
import EyeShowIcon from 'assets/icons/eye-show.svg'
import { Button } from 'components/Button/Button'
import { baseTheme } from 'modules/Theme/styled'

export const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			centered = false,
			placeholder = '',
			focusPlaceholder,
			placeholderFontSize = '14px',
			placeholderFontWeight = '400',
			placeholderTx,
			initialValue = '',
			placeholderBehavior = 'default',
			onChange,
			onFocus,
			onTextChange,
			disabled = false,
			defaultValue,
			className,
			style,
			valid = false,
			error,

			RightIcon,
			rightIconStyle,

			LeftIcon,
			leftIconStyle,

			LeftText,

			touch = false,
			fullWidth = false,
			value,
			autoComplete,
			type,
			RightButton,
			CustomRightButton,
			RightButtonDisabled = false,
			RightButtonText,
			onClickRightButton,
			...props
		},
		forwardedRef
	) => {
		const placeholderText = placeholderTx
			? translate(placeholderTx)
			: placeholder

		const theme = useContext(ThemeContext)
		const [text, setText] = useState('')
		const [isFocus, setIsFocus] = useState<boolean>(false)
		const [touched, setTouched] = useState<boolean>(touch)
		const [showPassword, setShowPassword] = useState<boolean>(false)
		const [centeredPlaceholder, setCenteredPlaceholder] = useState<string>(
			placeholderText
		)

		const handleFocus = () => {
			setIsFocus(true)

			if (centered && focusPlaceholder) {
				setCenteredPlaceholder(focusPlaceholder)
			}

			if (onFocus) {
				onFocus()
			}
		}
		const handleBlur = () => {
			if (text === '') {
				setIsFocus(false)
			}

			if (centered && focusPlaceholder) {
				setCenteredPlaceholder(placeholderText)
			}
		}

		const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
			const text = evt.currentTarget.value
			if (centered) setText(text)
			if (onChange) onChange(evt)
			if (onTextChange) onTextChange(text)
		}

		const rIconStyle = IconStyle(theme, { ...rightIconStyle, marginLeft: 5 })
		const lIconStyle = IconStyle(theme, { ...leftIconStyle, marginRight: 5 })

		const placeholderVisible = placeholderBehavior === 'visible' ? true : false

		return (
			<InputRoot fullWidth={fullWidth}>
				<InputContainer
					fullWidth={fullWidth}
					touched={touched}
					disabled={disabled}
					style={style}
					className={className}
					onFocus={() => setTouched(true)}
					error={!!error}
					placeholderVisible={placeholderVisible}
					centered={centered}
					isFocus={isFocus}
				>
					{LeftIcon && <LeftIcon style={lIconStyle} />}
					{LeftText && <LeftTextContainer>{LeftText}</LeftTextContainer>}
					<InputStyled
						{...props}
						type={showPassword ? 'text' : type}
						value={value !== undefined ? value : undefined}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						defaultValue={defaultValue}
						ref={forwardedRef}
						disabled={disabled}
						placeholder={
							placeholderVisible
								? ''
								: centered
								? centeredPlaceholder
								: placeholderText
						}
						placeholderFontSize={placeholderFontSize}
						placeholderFontWeight={placeholderFontWeight}
						autoComplete={autoComplete}
						touched={RightIcon || LeftIcon ? false : touched}
					/>
					{}
					{placeholderVisible && (
						<InputPlaceholder touched={touched} isFill={!!defaultValue}>
							{placeholderText}
						</InputPlaceholder>
					)}

					{RightIcon && <RightIcon style={rIconStyle} />}
					{type === 'password' && (
						<div style={{ display: 'flex' }}>
							<SVG
								src={showPassword ? EyeHideIcon : EyeShowIcon}
								style={{ cursor: 'pointer' }}
								onClick={() => setShowPassword(currentValue => !currentValue)}
							/>
						</div>
					)}
					{valid && (
						<div>
							<SVG src={CheckRoundIcon} />
						</div>
					)}

					{CustomRightButton && RightButtonText && (
						<Button
							disabled={RightButtonDisabled}
							style={{
								backgroundColor: 'transparent',
								padding: '12px 0px',
								height: '40px',
								border: 'none',

								color: baseTheme.colors.borderSelect,
								boxShadow: 'none',
								fontSize: '16px',
								fontWeight: '700',
							}}
							text={RightButtonText}
							onClick={() => onClickRightButton && onClickRightButton()}
						/>
					)}

					{RightButton && RightButtonText && (
						<Button
							disabled={RightButtonDisabled}
							style={{
								backgroundColor: RightButtonDisabled ? '#e7e7e7' : '#00a79d',
								height: '44px',
								color: RightButtonDisabled
									? baseTheme.colors.fontMainInputDisabled
									: baseTheme.colors.white,
								minWidth: '120px',
								boxShadow: 'none',
								fontSize: 14,
							}}
							text={RightButtonText}
							onClick={() => onClickRightButton && onClickRightButton()}
						/>
					)}
				</InputContainer>
				{error && (
					<InputContainerError>
						<SVG src={AlertRedIcon} style={{ marginLeft: 10 }} />
						<Text color="fontMain" preset="small">
							{error}
						</Text>
					</InputContainerError>
				)}
			</InputRoot>
		)
	}
)
