const {
	REACT_APP_BASE_URL,
	REACT_APP_PUBLIC_URL,
	REACT_APP_BASE_API_VERSION,
	REACT_APP_ENVIRONMENT,
	REACT_APP_BASE_GIPHY_URL,
	REACT_APP_BASE_GIPHY_VERSION,
	REACT_APP_BASE_GIPHY_API_KEY,
	REACT_APP_MESSAGES_POLL_INTERVAL,
	REACT_APP_SENTRY_DSN,
	REACT_APP_GOOGLE_API_KEY,
	REACT_APP_ATTACHMENTS_BASE_URL,
	REACT_APP_CONTACT_SYNC_FLAG,
	REACT_APP_CHARGEBEE_SITE,
	REACT_APP_CHARGEBEE_KEY,
	REACT_APP_CAMPAIGN_URL,
	REACT_APP_WEBSITE_URL,
	REACT_APP_MARKETING_URL,
	REACT_APP_SK_AUTH_URL,
	REACT_APP_CAMPAIGNS_RESET_PASSWORD,
	REACT_APP_SK_LOGOUT_ROUTE,
	REACT_APP_RS_WRITE_KEY,
	REACT_APP_RS_DATA_PLANE_URL,
} = process.env

export {
	REACT_APP_BASE_URL as BASE_URL,
	REACT_APP_PUBLIC_URL as PUBLIC_URL,
	REACT_APP_BASE_API_VERSION as BASE_API_VERSION,
	REACT_APP_BASE_GIPHY_URL as BASE_GIPHY_API_URL,
	REACT_APP_BASE_GIPHY_VERSION as BASE_GIPHY_API_VERSION,
	REACT_APP_BASE_GIPHY_API_KEY as BASE_GIPHY_API_API_KEY,
	REACT_APP_ENVIRONMENT as ENVIRONMENT,
	REACT_APP_MESSAGES_POLL_INTERVAL as MESSAGES_POLL_INTERVAL,
	REACT_APP_SENTRY_DSN as SENTRY_DSN,
	REACT_APP_GOOGLE_API_KEY as GOOGLE_API_KEY,
	REACT_APP_ATTACHMENTS_BASE_URL as ATTACHMENTS_BASE_URL,
	REACT_APP_CONTACT_SYNC_FLAG as CONTACT_SYNC_FLAG,
	REACT_APP_CHARGEBEE_SITE as BASE_CHARGEBEE_SITE,
	REACT_APP_CHARGEBEE_KEY as BASE_CHARGEBEE_KEY,
	REACT_APP_CAMPAIGN_URL as CAMPAIGN_URL,
	REACT_APP_WEBSITE_URL as WEBSITE_URL,
	REACT_APP_MARKETING_URL as MARKETING_URL,
	REACT_APP_SK_AUTH_URL as SK_AUTH_URL,
	REACT_APP_CAMPAIGNS_RESET_PASSWORD as CAMPAIGNS_RESET_PASSWORD,
	REACT_APP_SK_LOGOUT_ROUTE as SK_LOGOUT_ROUTE,
	REACT_APP_RS_WRITE_KEY as RS_WRITE_KEY,
	REACT_APP_RS_DATA_PLANE_URL as RS_DATA_PLANE_URL,
}
