import {
	configureStore,
	ThunkAction,
	Action,
	combineReducers,
	getDefaultMiddleware,
	ThunkDispatch,
	createListenerMiddleware,
} from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import {
	isProductionBuild,
	PERSIST_TIMEOUT,
	CACHE_VERSION,
	PERSIST_KEY,
	API_URL,
} from '@constants'
import rootSaga from 'store/sagas'
import apiService from 'services/api'
import auth from 'store/slices/auth/index'
import onboarding from 'store/slices/onboarding'
import flags from 'store/slices/feature-flags/index'
import signUp from 'store/slices/sign-up'
import contact from 'store/slices/contact'
import uploadFiles from 'store/slices/upload-files/index'
import campaign from 'store/slices/campaign/index'
import brand from 'store/slices/brand/'
import cspCampaign from 'store/slices/csp-campaign/'
import messages from 'store/slices/messages'
import tag from 'store/slices/tag'
import user from 'store/slices/user'
import metric from 'store/slices/metric'
import payments from 'store/slices/payments'
import organization from 'store/slices/organization'
import questionnaireAnswers from 'store/slices/questionnaire-answers'
import giphy from 'store/slices/giphy'
import systemNumber from 'store/slices/system-number'
import widget from 'store/slices/widget'
import invoices from 'store/slices/invoices'
import plan from 'store/slices/plan'
import subscription from 'store/slices/subscription'
import gettingStartedFlow from 'store/slices/getting-started-flow'
import { useDispatch, useSelector } from 'react-redux'

export type RootState = ReturnType<typeof rootReducer>

const appReducer = combineReducers({
	auth,
	signUp,
	contact,
	campaign,
	brand,
	cspCampaign,
	messages,
	uploadFiles,
	tag,
	user,
	metric,
	payments,
	organization,
	questionnaireAnswers,
	giphy,
	systemNumber,
	widget,
	invoices,
	plan,
	subscription,
	flags,
	onboarding,
	gettingStartedFlow,
})

const deleteCookies = async () => {
	try {
		const response = await fetch(`${API_URL}/auth/logout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		})

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`)
		}

		const responseData = await response.json()

		console.log('Response:', responseData)
	} catch (error) {
		console.error('Error making POST request:', error)
	}
}

const rootReducer = (state: any, action: any) => {
	if (action.type === 'LOG_OUT') {
		deleteCookies()
		state = undefined
		localStorage.clear()
		apiService.client.stop()
		apiService.client.cache.reset()
		apiService.client.clearStore()
	}
	return appReducer(state, action)
}

export const mainPersistConfig = {
	storage,
	key: PERSIST_KEY,
	timeout: PERSIST_TIMEOUT,
	keyPrefix: CACHE_VERSION,
	whitelist: ['auth', 'brand', 'cspCampaign', 'campaign'], //todo: add brand and cspCampaign on Dev/Prod
}

const persistedReducer = persistReducer(mainPersistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
	type: 'auth/me/fulfilled',
	effect: (action, { dispatch }) => {
		// @ts-ignore
		if (!action?.payload) {
			dispatch({ type: 'LOG_OUT' })
		}
	},
})

const middleware = [
	...getDefaultMiddleware({
		immutableCheck: false,
		serializableCheck: false,
	}),
	sagaMiddleware,
	listenerMiddleware.middleware,
]

if (!isProductionBuild) {
	const logger = createLogger()
	middleware.push(logger)
}

export const store = configureStore({
	reducer: persistedReducer,
	devTools: !isProductionBuild,
	middleware,
})

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)

export type IStoreState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	IStoreState,
	unknown,
	Action<string>
>

export type AppDispatch = ThunkDispatch<IStoreState, unknown, Action<string>>
export const useAppState = <Selected = unknown>(
	selector: (state: RootState) => Selected,
	equalityFn?: (left: Selected, right: Selected) => boolean
) => useSelector(selector, equalityFn)
export const useAppDispatch = () => useDispatch<AppDispatch>()
