import { GIPHY_API_URL, GIPHY_API_VERSION, GIPHY_API_API_KEY } from '@constants'
// Types
import { GiphyGifs } from './giphy.types'

const URL_GIPHY_API = `${GIPHY_API_URL}${GIPHY_API_VERSION}/gifs/`
const URL_GIPHY_API_KEY = GIPHY_API_API_KEY

class GiphyService {
	getTrendingGif = async (limit: number = 10): Promise<GiphyGifs[]> => {
		const url = `${URL_GIPHY_API}trending?limit=${limit}&api_key=${URL_GIPHY_API_KEY}`

		return await fetch(url, {
			method: 'GET',
		})
			.then(res => res.json())
			.then(({ data = [] }) => data)
			.catch(() => {
				throw new Error()
			})
	}

	searchGif = async (search: string): Promise<GiphyGifs[]> => {
		const url = `${URL_GIPHY_API}search?q=${search.replace(
			/\s/g,
			'+'
		)}&api_key=${URL_GIPHY_API_KEY}`

		return await fetch(url, {
			method: 'GET',
		})
			.then(res => res.json())
			.then(({ data = [] }) => data)
			.catch(() => {
				throw new Error()
			})
	}
}

export default new GiphyService()
