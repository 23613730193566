import { gql } from '@apollo/client'
import ApiService from 'services/api'
// Models
import {
	CreateTagMutation,
	DeleteTagFieldsMutation,
	CreateContactGroupTagMutation,
} from './tag.types'
// Types
import { ITag, ContactGroupTag } from 'types/api/tag.model'
// GPL
import { TAG, COTACT_GROUP_TAG } from './tag.gpl'

class TagService {
	private GET_TAGS = gql`
		query ListTags {
			listTags {
				${TAG}
			}
		}
	`

	private CREATE_TAG = gql`
		mutation createTag($input: CreateTagInput!) {
			createTag(input: $input) {
				${TAG}
			}
		}
	`

	private CREATE_CONTACT_GROUP_TAG = gql`
			mutation createContactGroupTag($input: CreateContactGroupTagInput!) {
				createContactGroupTag(input: $input) {
					${COTACT_GROUP_TAG}
				}
			}
	`

	private DELETE_TAG = gql`
		mutation deleteTag($input: DeleteTagInput!){
			deleteTag(input: $input){
					${TAG}
			}
		}
	`

	getTags = async (): Promise<ITag[]> => {
		return await ApiService.client
			.query({
				query: this.GET_TAGS,
			})
			.then(res => {
				console.log(`CHECKING ~ getTags= ~ res:`, res)

				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.listTags
			})
	}

	createTag = async (body: CreateTagMutation): Promise<ITag> => {
		return await ApiService.client
			.mutate<{ createTag: ITag }, { input: CreateTagMutation }>({
				mutation: this.CREATE_TAG,
				variables: { input: body },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.createTag
			})
	}

	createContactGroupTag = async (
		body: CreateContactGroupTagMutation
	): Promise<ContactGroupTag> => {
		return await ApiService.client
			.mutate<
				{ createContactGroupTag: ContactGroupTag },
				{ input: CreateContactGroupTagMutation }
			>({
				mutation: this.CREATE_CONTACT_GROUP_TAG,
				variables: { input: body },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.createContactGroupTag
			})
	}

	deleteTag = async (body: DeleteTagFieldsMutation): Promise<ITag> => {
		return await ApiService.client
			.mutate<{ deleteTag: ITag }, { input: DeleteTagFieldsMutation }>({
				mutation: this.DELETE_TAG,
				variables: { input: body },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.deleteTag
			})
	}
}

export default new TagService()
