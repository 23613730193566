import { gql } from '@apollo/client'
import ApiService from 'services/api'
import { Brand } from 'types/api/brand.model'
import { BrandCreateFields, BrandUpdateFields } from './brand.types'
import { BRAND } from './brand.gpl'

export class BrandService {
	private GET_BRANDS = gql`
        query GetBrand {
            getBrand {
               ${BRAND}
            }
        }
    `

	private CREATE_BRAND = gql`
        mutation CreateBrand($input: CreateBrandInput!) {
            createBrand(input: $input) {
                ${BRAND}
            }
        }
    `
	private UPDATE_BRAND = gql`
        mutation UpdateBrand($input: UpdateBrandInput!) {
            updateBrand(input: $input) {
                ${BRAND}
            }
        }
    `

	createBrand = async (fields: BrandCreateFields): Promise<Brand> => {
		return await ApiService.client
			.mutate<{ createBrand: Brand }, { input: BrandCreateFields }>({
				mutation: this.CREATE_BRAND,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.createBrand
			})
	}

	updateBrand = async (fields: BrandUpdateFields): Promise<Brand> => {
		return await ApiService.client
			.mutate<{ updateBrand: Brand }, { input: BrandCreateFields }>({
				mutation: this.UPDATE_BRAND,
				variables: { input: fields },
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data && res.data.updateBrand
			})
	}

	getBrand = async (): Promise<Brand> => {
		return await ApiService.client
			.query({
				query: this.GET_BRANDS,
				variables: {},
			})
			.then(response => {
				if (!response || response.errors || !response.data) {
					throw new Error()
				}
				return response.data && response.data.getBrand
			})
	}
}

export default new BrandService()
