import { createAsyncThunk } from '@reduxjs/toolkit'

import widgetService from 'services/widgets'
import {
	CreateWidgetMethodMutation,
	UpdateWidgetMethodMutation,
} from 'services/widgets/widget.types'
import { Widget } from 'types/api/widget.model'

export const getWidgets = createAsyncThunk(
	'widgets/get',
	widgetService.getWidgets
)

export const createWidget = createAsyncThunk<
	Widget,
	CreateWidgetMethodMutation
>('widgets/post', widgetService.createWidgetMethod)

export const updateWidget = createAsyncThunk<
	Widget,
	UpdateWidgetMethodMutation
>('widgets/update', widgetService.updateWidget)
