import { gql } from '@apollo/client'
import ApiService from 'services/api'
// Types
import {
	Subscription,
	SubscriptionEstimate,
} from 'types/api/subscription.model'
import {
	CreateSubscriptionMutation,
	CancelSubscriptionMutation,
	UpdateSubscriptionMutation,
} from './subscription.types'
// GPL
import { SUBSCRIPTION, SUBSCRIPTION_ESTIMATE } from './subscription.gpl'

class SubcriptionService {
	private GET_SUBSCRIPTION = gql`
		query {
			subscription {
				${SUBSCRIPTION}
			}
		}
	`
	private CREATE_SUBSCRIPTION = gql`
		mutation subscription($input: SubscriptionInput!) {
			subscription(input: $input) {
				${SUBSCRIPTION}
			}
		}
	`

	private UPDATE_SUBSCRIPTION = gql`
		mutation updateSubscription($input: UpdateSubscriptionInput!) {
			updateSubscription(input: $input) {
				${SUBSCRIPTION}
			}
		}
	`

	private CANCEL_SUBSCRIPTION = gql`
		mutation cancelSubscription($input: CancelSubscriptionInput!) {
			cancelSubscription(input: $input)
		}
	`

	private GET_SUBSCRIPTION_ESTIMATE = gql`
		query($candidatePlanId: String!) {
			subscriptionEstimate(candidatePlanId: $candidatePlanId) {
				${SUBSCRIPTION_ESTIMATE}
			}
		}
	`

	getSubscription = async (): Promise<Subscription> => {
		return await ApiService.client
			.query({
				query: this.GET_SUBSCRIPTION,
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.subscription
			})
	}

	createSubscription = async (
		body: CreateSubscriptionMutation
	): Promise<Subscription> => {
		return await ApiService.client
			.mutate<
				{ subscription: Subscription },
				{ input: CreateSubscriptionMutation }
			>({
				mutation: this.CREATE_SUBSCRIPTION,
				variables: { input: body },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.subscription
			})
	}

	updateSubscription = async (
		body: UpdateSubscriptionMutation
	): Promise<Subscription> => {
		return await ApiService.client
			.mutate<
				{ updateSubscription: Subscription },
				{ input: UpdateSubscriptionMutation }
			>({
				mutation: this.UPDATE_SUBSCRIPTION,
				variables: { input: body },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.updateSubscription
			})
	}

	cancelSubscription = async (
		body: CancelSubscriptionMutation
	): Promise<Boolean> => {
		return await ApiService.client
			.mutate<
				{ cancelSubscription: Subscription },
				{ input: CancelSubscriptionMutation }
			>({
				mutation: this.CANCEL_SUBSCRIPTION,
				variables: { input: body },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return true
			})
	}

	getSubscriptionEstimate = async ({
		candidatePlanId,
	}: {
		candidatePlanId: String
	}): Promise<SubscriptionEstimate> => {
		return await ApiService.client
			.query({
				query: this.GET_SUBSCRIPTION_ESTIMATE,
				variables: { candidatePlanId },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.subscriptionEstimate
			})
	}
}

export default new SubcriptionService()
