import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import {
	AppMenuItemContainer,
	AppMenuLabel,
	AppMenuItemIcon,
	AppMenuItemLastIcon,
	AppMenuParentContainer,
} from './AppMenuItem.styled'
import { AppMenuItemProps } from './AppMenuItem.props'
import { ReactComponent as DownChevronInActive } from 'assets/icons/downChevron.svg'
import { ReactComponent as DownChevronActive } from 'assets/icons/leftnav-tab-arrow-active.svg'

const AppMenuItem = ({
	ActiveIcon,
	Icon,
	label,
	disabled,
	href,
	unreadMessage,
	onClick,
	children,
	child,
	StepCompleteIcon,
	stepComplete = false,
	isSetupFlow = false,
}: AppMenuItemProps) => {
	const match = useRouteMatch(children?.map(child => child.href) || [href])
	const isActive = !!match

	const badge = label === 'Messages' && unreadMessage

	return (
		<AppMenuItemContainer
			$isActive={isActive}
			$disabled={!!disabled}
			to={href}
			className={isActive ? 'active' : 'inactive'}
			onClick={() => {
				if (onClick) onClick()
			}}
			$hasChildren={children && children.length > 0}
			$child={child}
			$isSetupFlow={isSetupFlow}
		>
			<AppMenuParentContainer>
				<AppMenuItemIcon badge={badge}>
					{stepComplete ? (
						StepCompleteIcon && <StepCompleteIcon />
					) : isActive ? (
						<ActiveIcon />
					) : (
						<Icon />
					)}
				</AppMenuItemIcon>
				<AppMenuLabel child={child}>{label}</AppMenuLabel>
			</AppMenuParentContainer>
			{children && children.length > 0 && (
				<AppMenuItemLastIcon>
					{isActive ? <DownChevronActive /> : <DownChevronInActive />}
				</AppMenuItemLastIcon>
			)}
		</AppMenuItemContainer>
	)
}

export default AppMenuItem
