import { createSlice } from '@reduxjs/toolkit'
import { updateQuestionnaireAnswers } from './questionnaire-answers.actions'
import createAsyncReducers from 'utils/create-async-reducers'

export interface IState {
	loading: boolean
}

const initialState: IState = {
	loading: false,
}

const { reducer, actions } = createSlice({
	name: 'questionnaire-answers',
	initialState,
	reducers: {},
	extraReducers: builder => {
		createAsyncReducers(builder, updateQuestionnaireAnswers, 'loading')
	},
})

export default reducer
export const questionnaireAnswersActions = {
	...actions,
	updateQuestionnaireAnswers,
}
