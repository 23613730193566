import styled from 'styled-components'
import { mainFont } from 'utils/styled'
import { Link } from 'react-router-dom'

export const TabBarContainer = styled.div`
	background-color: white;
	background-color: ${({ theme }) => theme.colors.backgroundMain};
	border-top: 1px solid ${({ theme }) => theme.colors.border};
	bottom: 0;
	display: flex;
	height: calc(3.75rem + env(safe-area-inset-bottom));
	justify-content: space-between;
	left: 0;
	padding-bottom: env(safe-area-inset-bottom, 0px);
	position: fixed;
	right: 0;
	z-index: 99;
`

export const TabBarItemContainer = styled(Link)<{
	$disabled: boolean
	$active: boolean
}>`
	align-items: center;
	color: ${({ $active, theme }) =>
		$active ? theme.colors.accentPrimary : theme.colors.fontSecondary};
	cursor: pointer;
	display: flex;
	flex-direction: column;
	flex: auto;
	height: 100%;
	justify-content: center;
	transition: none;

	&:active,
	&:hover {
		color: ${({ $active, theme }) =>
			$active ? theme.colors.accentPrimary : theme.colors.fontSecondary};
	}

	${({ $disabled, theme }) =>
		$disabled &&
		`
		color: ${theme.colors.disabled};
		cursor: default;

		&:hover,
		&:active {
			color: ${theme.colors.disabled};
		}
	`}
`

export const TabBarItemIcon = styled.div`
	height: 1.75rem;

	svg {
		height: 1.75rem;
		width: auto;
	}
`

export const TabBarItemLabel = styled.span`
	${mainFont};
	font-size: 12px;
	font-weight: bold;
	line-height: 1.3;
`
