import { gql } from '@apollo/client'
import ApiService from 'services/api'
// Types
import { PlanSub } from 'types/api/plan.model'
// GPL
import { PLAN } from './plan.gpl'

class PlanService {
	private GET_PLANS = gql`
		query {
			listPlans {
				${PLAN}
			}
		}
	`

	getPlans = async (): Promise<PlanSub[]> => {
		return await ApiService.client
			.query({
				query: this.GET_PLANS,
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.listPlans
			})
	}
}

export default new PlanService()
