import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import createAsyncReducers from 'utils/create-async-reducers'
import { getPlans } from './plan.actions'
import { PlanSub } from 'types/api/plan.model'

export interface IState {
	plan?: PlanSub
	planList?: PlanSub[]
	candidatePlan?: PlanSub
	loading: boolean
}

const initialState: IState = {
	plan: undefined,
	planList: [],
	loading: false,
	candidatePlan: undefined,
}

const { reducer, actions } = createSlice({
	name: 'plan',
	initialState,
	reducers: {
		setCandidatePlan: (state, action: PayloadAction<PlanSub>) => {
			state.candidatePlan = action.payload
		},
	},
	extraReducers: builder => {
		createAsyncReducers(builder, getPlans, 'loading', (state, action) => {
			state.planList = action.payload
		})
	},
})

export default reducer
export const planActions = {
	...actions,
	getPlans,
}
