import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type SignUpFields = 'firstName' | 'lastName' | 'phoneNumber'

export interface SignUpState {
	firstName: string
	lastName: string
	phoneNumber: string
}

const initialState: SignUpState = {
	phoneNumber: '',
	firstName: '',
	lastName: '',
}

type SetType = { key: SignUpFields; value: string }

export const signUpSlice = createSlice({
	name: 'signup',
	initialState,
	reducers: {
		set: (state, action: PayloadAction<SetType>) => {
			state[action.payload.key] = action.payload.value
		},
	},
})

export default signUpSlice.reducer

export const signUpActions = {
	...signUpSlice.actions,
}
