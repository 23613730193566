import * as React from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { translate } from 'i18n'

// Button
import { buttonPresets, isButtonPreset } from './Button.presets'
import { ThemeContext } from 'styled-components'
import { ButtonProps } from './Button.props'
import { Spin, Tooltip } from 'antd'
import rightArrow from 'assets/icons/right-arrow.svg'
import disabledArrow from 'assets/icons/right-arrow-disabled.svg'
import ConfettiExplosion from 'react-confetti-explosion'

export const Button = React.forwardRef(
	(
		{
			onClick,

			preset = 'primary',
			tx,
			text,
			disabled = false,
			loading = false,
			size = 'medium',

			RightIcon,
			rightIconStyle,

			LeftIcon,
			leftIconStyle,

			mainIcon,

			fullWidth = false,

			children,
			opacity = 1,
			tooltip,
			tooltipDisabled,

			isExploding = false,
			...rest
		}: ButtonProps,
		ref
	) => {
		const theme = React.useContext(ThemeContext)
		const currentPreset = isButtonPreset(preset)
			? buttonPresets[preset]
			: buttonPresets.primary
		const Button = currentPreset.button
		const content = tx ? translate(tx) : text

		const RIGHT_ICON_STYLE = currentPreset.rightIcon(
			theme,
			disabled,
			rightIconStyle
		)
		const LEFT_ICON_STYLE = currentPreset.leftIcon(
			theme,
			disabled,
			leftIconStyle
		)

		if (tooltip || tooltipDisabled)
			return (
				<Tooltip title={disabled ? tooltipDisabled || tooltip : tooltip}>
					<div>
						<Button
							ref={ref}
							{...rest}
							disabled={disabled || loading}
							onClick={onClick}
							fullWidth={fullWidth}
							size={size}
							opacity={opacity}
						>
							{LeftIcon && <LeftIcon style={LEFT_ICON_STYLE} />}
							{loading ? <Spin /> : content}
							{RightIcon && <RightIcon style={RIGHT_ICON_STYLE} />}
							{mainIcon && children}
						</Button>
					</div>
				</Tooltip>
			)
		else
			return (
				<>
					<Button
						ref={ref}
						{...rest}
						disabled={disabled || loading}
						onClick={onClick}
						fullWidth={fullWidth}
						size={size}
						opacity={opacity}
					>
						{LeftIcon && <LeftIcon style={LEFT_ICON_STYLE} />}
						{loading ? <Spin /> : content}
						{RightIcon && <RightIcon style={RIGHT_ICON_STYLE} />}
						{mainIcon && children}
						{isExploding && (
							<ConfettiExplosion force={0.6} duration={2500} zIndex={999} />
						)}
					</Button>
				</>
			)
	}
)

export function NextButton(props: ButtonProps) {
	return (
		<Button
			RightIcon={() => (
				<img
					alt={props.alt || 'This is an image'}
					style={{ marginLeft: 13 }}
					src={props.disabled ? disabledArrow : rightArrow}
				/>
			)}
			tx="general.next"
			{...props}
			preset={'accent'}
		/>
	)
}

export function NextButtonInvitedUser(props: ButtonProps) {
	return <Button tx="signUp.nextButton.invitedUserFinish" {...props} />
}

export function BackButton(props: ButtonProps) {
	return <Button LeftIcon={ArrowLeftOutlined} tx="general.back" {...props} />
}

export function IconButton(props: ButtonProps) {
	return <Button mainIcon={true} {...props} />
}
