import React from 'react'

//PROPS TYPE
import { PhoneNumberStatusProps } from './PhoneNumberStatus.props'
import { Colors } from 'modules/Theme/styled'

import { ReactComponent as ErrorBadge } from 'assets/icons/badge-error.svg'
import { ReactComponent as WarningBadge } from 'assets/icons/badge-warning.svg'
import { ReactComponent as SuccessBadge } from 'assets/icons/badge-success.svg'

//COMPONENTS
import {
	PhoneNumberStatusContainer,
	PhoneNumberStatusHash,
	PhoneNumberStatusContent,
	PhoneNumberStatusText,
	PhoneNumberStatusVerification,
} from './PhoneNumberStatus.styled'

import { ReactComponent as HashIcon } from 'assets/icons/hash.svg'

type AppMenuStatusType = {
	color: Colors
	backgroundColor: Colors
	icon: JSX.Element
	text: string
}

const appMenuStatusTypeMap: Record<string, AppMenuStatusType> = {
	phone_transfer_success: {
		color: 'accentPrimary',
		backgroundColor: 'accentBackground',
		icon: <SuccessBadge />,
		text: 'Phone number active',
	},
	phone_transfer_pending: {
		color: 'warningPrimary',
		backgroundColor: 'warningBackground',
		icon: <WarningBadge />,
		text: 'Phone number activation in progress',
	},
	phone_transfer_error: {
		color: 'errorPrimary',
		backgroundColor: 'errorBackground',
		icon: <ErrorBadge />,
		text: 'Phone number activation unsuccessful',
	},
}

const PhoneNumberStatus = ({ status, phoneNumber }: PhoneNumberStatusProps) => {
	const statusValue = status ? status : 'phone_transfer_error'

	const statusType = appMenuStatusTypeMap[statusValue]

	return (
		<PhoneNumberStatusContainer
			background={statusType.backgroundColor}
			color={statusType.color}
		>
			<PhoneNumberStatusHash>
				<HashIcon />
			</PhoneNumberStatusHash>
			<PhoneNumberStatusContent>
				{phoneNumber && (
					<PhoneNumberStatusText>{phoneNumber}</PhoneNumberStatusText>
				)}
				<PhoneNumberStatusVerification>
					{statusType.text} {statusType.icon}
				</PhoneNumberStatusVerification>
			</PhoneNumberStatusContent>
		</PhoneNumberStatusContainer>
	)
}

export default PhoneNumberStatus
