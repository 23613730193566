import { createAsyncThunk } from '@reduxjs/toolkit'
import uploadFilesService from 'services/upload-files'
import {
	AttachMessageImageFields,
	AttachSignatureFields,
	IAttachMessageImage,
	IImage,
	UploadImageMutation,
} from 'services/upload-files/upload-files.types'
import { Organization } from 'types/api/organization.model'

export const uploadImage = createAsyncThunk<IImage, UploadImageMutation>(
	'upload-files/upload-image',
	uploadFilesService.uploadImage
)

export const uploadFile = createAsyncThunk<IImage, UploadImageMutation>(
	'upload-files/upload-file',
	uploadFilesService.uploadFile
)

export const purgeFile = createAsyncThunk(
	'upload-files/purge-file',
	uploadFilesService.purgeFile
)

export const attachMessageImage = createAsyncThunk<
	IAttachMessageImage,
	AttachMessageImageFields
>('upload-files/attach-message-image', uploadFilesService.attachMessageImage)

export const attachSignature = createAsyncThunk<
	Organization,
	AttachSignatureFields
>('upload-files/attach-signature', uploadFilesService.attachSignature)
