import React, { useEffect, useState } from 'react'
import {
	AutomatedMessagesHeader,
	AutomatedMessagesContainer,
} from './AutomatedMessages.styled'
import AutomatedMessagesItem from './AutomatedMessagesItem'
import { HeroArea } from 'components/HeroArea'
import inboundlightningicon from 'assets/icons/inbound-opt-in.svg'
import outboundlightningicon from 'assets/icons/outbound-opt-in.svg'
import { useHistory } from 'react-router'
import { scenes } from 'scenes/scenes'
import { Helmet } from 'react-helmet'
import { CampaignOptinFlowMessage } from 'types/api/campaign.model'
import { useAppDispatch, useAppState } from 'store'
import { authActions } from 'store/slices/auth'
import { campaignActions } from 'store/slices/campaign'

const AutomatedMessagesScene = () => {
	const history = useHistory()
	const dispatch = useAppDispatch()
	const user = useAppState(state => state.auth.user)
	const [activeWelcomeOptIn, setActiveWelcomeOptIn] = useState<boolean>(false)
	const { optinFlowOutboundMessages } = useAppState(state => state.campaign)
	const goToWelcomeOptIn = () => history.push(scenes.welcomeOptIn.url)
	const goToWelcomeOptOut = () => history.push(scenes.WelcomeOptOut.url)

	const [lastModifiedOneWay, setLastModifiedOneWay] = useState<string | null>(
		null
	)
	const [lastModifiedOutBound, setLastModifiedOutBound] = useState<
		string | null
	>(null)
	const [lastModifiedInBound, setLastModifiedInBound] = useState<string | null>(
		null
	)

	useEffect(() => {
		if (optinFlowOutboundMessages.length > 0) {
			const optinFlowMessagesOutBound: CampaignOptinFlowMessage[] = optinFlowOutboundMessages
				.filter(
					optinFlowMessage =>
						optinFlowMessage.updatedAt !== null &&
						optinFlowMessage.messageType.includes('outbound')
				)
				.sort((a: CampaignOptinFlowMessage, b: CampaignOptinFlowMessage) => {
					return (
						new Date(b.updatedAt as string).getTime() -
						new Date(a.updatedAt as string).getTime()
					)
				})
			const optinFlowMessageInBound: CampaignOptinFlowMessage[] = optinFlowOutboundMessages
				.filter(
					optinFlowMessage =>
						optinFlowMessage.updatedAt !== null &&
						!optinFlowMessage.messageType.includes('outbound')
				)
				.sort((a: CampaignOptinFlowMessage, b: CampaignOptinFlowMessage) => {
					return (
						new Date(b.updatedAt as string).getTime() -
						new Date(a.updatedAt as string).getTime()
					)
				})

			const optinFlowMessageOneWay: CampaignOptinFlowMessage[] = optinFlowOutboundMessages
				.filter(
					optinFlowMessage =>
						optinFlowMessage.updatedAt &&
						optinFlowMessage.messageType.includes('one-way')
				)
				.sort((a: CampaignOptinFlowMessage, b: CampaignOptinFlowMessage) => {
					return (
						new Date(b.updatedAt as string).getTime() -
						new Date(a.updatedAt as string).getTime()
					)
				})

			optinFlowMessagesOutBound.length > 0
				? setLastModifiedOutBound(
						optinFlowMessagesOutBound[0].updatedAt as string
				  )
				: setLastModifiedOutBound(null)

			optinFlowMessageInBound.length > 0
				? setLastModifiedInBound(optinFlowMessageInBound[0].updatedAt as string)
				: setLastModifiedInBound(null)

			optinFlowMessageOneWay.length > 0
				? setLastModifiedOneWay(optinFlowMessageOneWay[0].updatedAt as string)
				: setLastModifiedOneWay(null)
		}
	}, [optinFlowOutboundMessages])

	useEffect(() => {
		if (user?.organizations?.[0]) {
			setActiveWelcomeOptIn(user.organizations[0].optinFlowEnabled)
		}
	}, [user])

	useEffect(() => {
		dispatch(authActions.getMe())
	}, [history, dispatch])

	useEffect(() => {
		dispatch(campaignActions.getCamapignOptinFlowInboundMessages())
		dispatch(campaignActions.getCamapignOptinFlowOutboundMessages())
	}, [dispatch])

	return (
		<AutomatedMessagesContainer>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Automations | Convos</title>
			</Helmet>
			<AutomatedMessagesHeader>
				<HeroArea
					title="Automated Messages"
					subtitle="Maintain your personal touch at scale with powerful automations that
					let you easily break off into two-way conversation."
				/>
			</AutomatedMessagesHeader>
			{/* UNCOMMENT ONCE THE ONE-WAY SCENE IS CREATED */}
			{/* <AutomatedMessagesItem
				title="When you send a campaign to non opted-in contacts · One-Way"
				description="A compliant disclosure message for when you attempt to send non opted-in contacts their first campaign."
				lastModified={lastModifiedOneWay}
				active={activeWelcomeOneWay}
				icon={oneWayicon}
				onClick={goToWelcomeOneWay}
			/> */}
			<AutomatedMessagesItem
				title="When non opted-in contacts text you · Inbound"
				description="A sequence of messages to automate compliance for when a new contact texts you."
				lastModified={lastModifiedInBound}
				active={activeWelcomeOptIn}
				icon={inboundlightningicon}
				onClick={goToWelcomeOptIn}
			/>
			<AutomatedMessagesItem
				title="When you send out the opt-in link · Outbound"
				description="A sequence of messages to automate compliance for when you text new contacts."
				lastModified={lastModifiedOutBound}
				icon={outboundlightningicon}
				onClick={goToWelcomeOptOut}
			/>
		</AutomatedMessagesContainer>
	)
}

export default AutomatedMessagesScene
