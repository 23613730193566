import { CONTACT, GROUP_CONTACT } from '../gpl/contact'

export const TAG = `
    id
    name
    campaignAudience
    campaignType
    campaignGoal
    organization {
        id
        name
    }
`

export const COTACT_GROUP_TAG = `
    contact {${CONTACT}}
    id
    tag {${TAG}}
    contactGroup {${GROUP_CONTACT}}
`
