import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	createCampaign,
	updateCampaign,
	getCampaign,
	getCampaings,
	fetchCampaignConversations,
	listCampaignConversations,
	getCampaingsCollections,
	getCamapignOptinFlowOutboundMessages,
	createCamapignOptinFlowOutboundMessage,
	updateCamapignOptinFlowOutboundMessage,
	deleteCamapignOptinFlowOutboundMessage,
	sendCampaign,
	attachCampaignImage,
	getCampaignMetrics,
	getCampaignMetricsIndividual,
	duplicateCampaign,
	createCampaignRecipient,
	getCampaignConversation,
	listBroadcastResponses,
	startOptinFlowCampaign,
	optInContactCampaign,
	listTemplates,
	listGoals,
	createCampaignTemplate,
	createKeywordCampaign,
	newKeywordCampaign,
	getCamapignOptinFlowInboundMessages,
	deleteCamapignOptinFlowInboundMessage,
	updateCamapignOptinFlowInboundMessage,
	createCamapignOptinFlowInboundMessage,
	createKeywordCampaignTemplate,
	archiveCampaign,
} from './campaign.actions'
import createAsyncReducers from 'utils/create-async-reducers'
import {
	Campaign,
	Template,
	CampaignOptinFlowMessage,
	CampaignConversation,
	CampaignOptinFlowInboundMessage,
} from 'types/api/campaign.model'
import { CampaignMetrics } from 'types/api/campaign-metrics.model'
import { IGoal } from 'types/api/campaign'
import { ConversationList, Message } from 'types/api/message.model'

interface IState {
	campaign: Campaign | null
	campaignCreatedId: string | null
	campaignDuplicatedId: string | null
	campaignList: Campaign[]
	templateList: Template[]
	goalList: IGoal[]
	collectionCampaigns: Campaign[]
	collectionCampaignsLoading: boolean
	automatedCampaigns: Campaign[]
	automatedCampaignsLoading: boolean
	campaignMetrics: CampaignMetrics | null
	campaignLoading: boolean
	templateListLoading: boolean
	createCampaignTemplateLoading: boolean
	keywordCampaignTemplate: Template | null
	createKeywordCampaignTemplateLoading: boolean
	campaignTemplate: Template | null
	selectedTemplate: Template | null
	goalListLoading: boolean
	campaignCarouselLoading: boolean
	campaignUpdateLoading: boolean
	campaignMetricsLoading: boolean
	duplicateCampaignLoading: boolean
	listCampaignConversationsLoading: boolean
	listCampaignConversations: ConversationList
	campaignConversationLoading: boolean
	campaignTypeEdit?: 'collection' | 'automated'
	optinFlowOutboundMessages: CampaignOptinFlowMessage[]
	optinFlowOrgInboundMessages: CampaignOptinFlowInboundMessage[]
	optinFlowInboundMessagesLoading: boolean
	optinFlowOutboundMessagesLoading: boolean
	optinFlowMessageLoading: boolean
	startOptinFlowCampaignLoading: boolean
	enabledOptinFlowCampaign: boolean
	optinContactCampaignLoading: boolean
	polledCampaign?: Campaign
	campaignConversation: CampaignConversation | null
	buttonCampaignLoading: boolean
}

const initialState: IState = {
	campaign: null,
	campaignCreatedId: null,
	campaignDuplicatedId: null,
	campaignList: [],
	templateList: [],
	goalList: [],
	collectionCampaigns: [],
	collectionCampaignsLoading: false,
	automatedCampaigns: [],
	automatedCampaignsLoading: false,
	campaignMetrics: null,
	campaignLoading: false,
	templateListLoading: false,
	createCampaignTemplateLoading: false,
	campaignTemplate: null,
	createKeywordCampaignTemplateLoading: false,
	keywordCampaignTemplate: null,
	selectedTemplate: null,
	goalListLoading: false,
	campaignCarouselLoading: false,
	campaignUpdateLoading: false,
	campaignMetricsLoading: false,
	duplicateCampaignLoading: false,
	listCampaignConversationsLoading: false,
	listCampaignConversations: { conversations: [], totalConversations: 0 },
	campaignConversationLoading: false,
	optinFlowOutboundMessages: [],
	optinFlowOrgInboundMessages: [],
	optinFlowInboundMessagesLoading: false,
	optinFlowOutboundMessagesLoading: false,
	optinFlowMessageLoading: false,
	startOptinFlowCampaignLoading: false,
	enabledOptinFlowCampaign: false,
	optinContactCampaignLoading: false,
	campaignConversation: null,
	buttonCampaignLoading: false,
}

const { reducer, actions } = createSlice({
	name: 'campaign',
	initialState,
	reducers: {
		setPolledCampaign: (state, action: PayloadAction<Campaign>) => {
			state.polledCampaign = action.payload
		},
		setCampaign(state, action: PayloadAction<Campaign>) {
			state.campaign = action.payload
		},
		setCampaignTemplate(state, action: PayloadAction<Template>) {
			state.selectedTemplate = action.payload
		},
		setCampaignList(state, action: PayloadAction<Campaign[]>) {
			state.campaignList = action.payload
		},
		setEnabledOptinFlowCampaign(state, action) {
			state.enabledOptinFlowCampaign = action.payload
		},
		setCampaignTypeEdit(
			state,
			action: PayloadAction<'collection' | 'automated'>
		) {
			state.campaignTypeEdit = action.payload
		},
		clearCurrentCampaign(state) {
			// Make sure to clear campaign before going to createCampaign page anew
			state.campaign = null
		},
		clearSelectedTemplate(state) {
			state.selectedTemplate = null
		},
		clearCurrentCampaignCreatedId(state) {
			state.campaignCreatedId = null
		},
		clearCurrentCampaignDuplicatedId(state) {
			state.campaignDuplicatedId = null
		},
		setBroadcastResponsesInCampaignConversation(
			state,
			action: PayloadAction<Message[]>
		) {
			if (state.campaignConversation) {
				state.campaignConversation = {
					...state.campaignConversation,
					messages: [...state.campaignConversation.messages, ...action.payload],
				}
			}
		},
	},
	extraReducers: builder => {
		createAsyncReducers(
			builder,
			createCampaign,
			'campaignLoading',
			(state, action) => {
				const campaigns = state.campaignList
					? [...state.campaignList, action.payload]
					: [action.payload]
				state.campaignList = campaigns as Campaign[]
				state.campaignCreatedId = action.payload.id
			}
		)
		createAsyncReducers(
			builder,
			createKeywordCampaign,
			'campaignLoading',
			(state, action) => {
				const campaigns = state.campaignList
					? [...state.campaignList, action.payload]
					: [action.payload]
				state.campaignList = campaigns as Campaign[]
				state.campaignCreatedId = action.payload.id
				state.campaign = action.payload
			}
		)
		createAsyncReducers(
			builder,
			newKeywordCampaign,
			'campaignLoading',
			(state, action) => {
				const campaigns = state.campaignList
					? [...state.campaignList, action.payload]
					: [action.payload]
				state.campaignList = campaigns as Campaign[]
				state.campaignCreatedId = action.payload.id
				state.campaign = action.payload
			}
		)
		createAsyncReducers(
			builder,
			createCamapignOptinFlowOutboundMessage,
			'optinFlowMessageLoading',
			(state, action) => {
				const optinFlowMessages = state.optinFlowOutboundMessages.map(
					optinFlowMessage => {
						if (optinFlowMessage.messageType === action.payload.messageType) {
							return action.payload
						}
						return optinFlowMessage
					}
				)

				state.optinFlowOutboundMessages = optinFlowMessages
			}
		)
		createAsyncReducers(
			builder,
			updateCamapignOptinFlowOutboundMessage,
			'optinFlowMessageLoading',
			(state, action) => {
				const optinFlowMessages = state.optinFlowOutboundMessages.map(
					optinFlowMessage => {
						if (optinFlowMessage.messageType === action.payload.messageType) {
							return action.payload
						}
						return optinFlowMessage
					}
				)

				state.optinFlowOutboundMessages = optinFlowMessages
			}
		)
		createAsyncReducers(
			builder,
			deleteCamapignOptinFlowOutboundMessage,
			'optinFlowMessageLoading',
			(state, action) => {
				const optinFlowMessages = state.optinFlowOutboundMessages.map(
					optinFlowMessage => {
						if (optinFlowMessage.messageType === action.payload.messageType) {
							return { ...action.payload, id: null }
						}
						return optinFlowMessage
					}
				)

				state.optinFlowOutboundMessages = optinFlowMessages
			}
		)

		createAsyncReducers(
			builder,
			createCamapignOptinFlowInboundMessage,
			'optinFlowMessageLoading',
			(state, action) => {
				const optinFlowMessages = state.optinFlowOrgInboundMessages.map(
					optinFlowMessage => {
						if (optinFlowMessage.messageType === action.payload.messageType) {
							return action.payload
						}
						return optinFlowMessage
					}
				)

				state.optinFlowOrgInboundMessages = optinFlowMessages
			}
		)
		createAsyncReducers(
			builder,
			updateCamapignOptinFlowInboundMessage,
			'optinFlowMessageLoading',
			(state, action) => {
				const optinFlowMessages = state.optinFlowOrgInboundMessages.map(
					optinFlowMessage => {
						if (optinFlowMessage.messageType === action.payload.messageType) {
							return action.payload
						}
						return optinFlowMessage
					}
				)

				state.optinFlowOrgInboundMessages = optinFlowMessages
			}
		)

		createAsyncReducers(
			builder,
			deleteCamapignOptinFlowInboundMessage,
			'optinFlowMessageLoading',
			(state, action) => {
				const optinFlowMessages = state.optinFlowOrgInboundMessages.map(
					optinFlowMessage => {
						if (optinFlowMessage.messageType === action.payload.messageType) {
							return { ...action.payload, id: null }
						}
						return optinFlowMessage
					}
				)

				state.optinFlowOrgInboundMessages = optinFlowMessages
			}
		)
		// createAsyncReducers(builder, createCampaignRecipient, 'campaignLoading')
		createAsyncReducers(builder, updateCampaign, 'campaignUpdateLoading')
		createAsyncReducers(
			builder,
			getCampaign,
			'buttonCampaignLoading',
			(state, action) => {
				state.campaign = action.payload
			}
		)
		createAsyncReducers(
			builder,
			getCampaings,
			'campaignLoading',
			(state, action) => {
				state.campaignList = action.payload
			}
		)
		createAsyncReducers(
			builder,
			getCampaingsCollections,
			'collectionCampaignsLoading',
			(state, action: PayloadAction<Campaign[]>) => {
				state.collectionCampaigns = action.payload
			}
		)
		createAsyncReducers(
			builder,
			getCamapignOptinFlowOutboundMessages,
			'optinFlowOutboundMessagesLoading',
			(state, action: PayloadAction<CampaignOptinFlowMessage[]>) => {
				state.optinFlowOutboundMessages = action.payload
			}
		)
		createAsyncReducers(
			builder,
			getCamapignOptinFlowInboundMessages,
			'optinFlowInboundMessagesLoading',
			(state, action: PayloadAction<CampaignOptinFlowInboundMessage[]>) => {
				state.optinFlowOrgInboundMessages = action.payload
			}
		)
		createAsyncReducers(
			builder,
			listCampaignConversations,
			'listCampaignConversationsLoading',
			(state, action: PayloadAction<ConversationList>) => {
				state.listCampaignConversations = {
					...state.listCampaignConversations,
					...action.payload,
				}
			}
		)
		createAsyncReducers(
			builder,
			getCampaignConversation,
			'campaignConversationLoading',
			(state, action: PayloadAction<CampaignConversation>) => {
				state.campaignConversation = action.payload
			}
		)

		createAsyncReducers(
			builder,
			optInContactCampaign,
			'optinContactCampaignLoading'
		)
		createAsyncReducers(
			builder,
			getCampaignMetrics,
			'campaignMetricsLoading',
			(state, action) => {
				state.campaignMetrics = action.payload
			}
		)
		createAsyncReducers(
			builder,
			getCampaignMetricsIndividual,
			'campaignMetricsLoading'
		)
		createAsyncReducers(
			builder,
			duplicateCampaign,
			'duplicateCampaignLoading',
			(state, actions) => {
				state.campaignDuplicatedId = actions.payload.id
			}
		)
		createAsyncReducers(
			builder,
			listTemplates,
			'templateListLoading',
			(state, actions) => {
				state.templateList = actions.payload
			}
		)
		createAsyncReducers(
			builder,
			listGoals,
			'goalListLoading',
			(state, actions) => {
				state.goalList = actions.payload
			}
		)

		createAsyncReducers(
			builder,
			createCampaignTemplate,
			'createCampaignTemplateLoading',
			(state, actions) => {
				state.campaignTemplate = actions.payload
			}
		)
		createAsyncReducers(
			builder,
			createKeywordCampaignTemplate,
			'createKeywordCampaignTemplateLoading',
			(state, actions) => {
				state.keywordCampaignTemplate = actions.payload
			}
		)
		createAsyncReducers(
			builder,
			archiveCampaign,
			'buttonCampaignLoading',
			(state, actions) => {
				state.campaign = actions.payload
			}
		)
	},
})

export default reducer

export const campaignActions = {
	...actions,
	createCampaign,
	createKeywordCampaign,
	newKeywordCampaign,
	createCampaignRecipient,
	updateCampaign,
	getCampaign,
	getCampaings,
	listTemplates,
	listGoals,
	createCampaignTemplate,
	createKeywordCampaignTemplate,
	sendCampaign,
	getCampaingsCollections,
	attachCampaignImage,
	getCampaignMetrics,
	getCampaignMetricsIndividual,
	duplicateCampaign,
	fetchCampaignConversations,
	listCampaignConversations,
	getCampaignConversation,
	listBroadcastResponses,
	getCamapignOptinFlowOutboundMessages,
	createCamapignOptinFlowOutboundMessage,
	updateCamapignOptinFlowOutboundMessage,
	deleteCamapignOptinFlowOutboundMessage,
	getCamapignOptinFlowInboundMessages,
	createCamapignOptinFlowInboundMessage,
	updateCamapignOptinFlowInboundMessage,
	deleteCamapignOptinFlowInboundMessage,
	startOptinFlowCampaign,
	optInContactCampaign,
	archiveCampaign,
}
