import React from 'react'
import Tippy from '@tippyjs/react'
import { translate } from 'i18n'
import { useHistory } from 'react-router-dom'

import {
	CreateMenuItem,
	CreateMenuContainer,
	CreateMenuItemButton,
	CreateMenuItemTitle,
	CreateMenuItemText,
	CreateMenuItems,
	CreateMenuHeader,
	CreateMenuBackButton,
	CreateMenuHeaderTitle,
	CreateMenuItemBody,
	CreateMenuButtonStyled,
} from './CreateMenuButton.styled'
import { scenes } from 'scenes/scenes'
import { ReactComponent as BackIcon } from 'assets/icons/back-arrow.svg'
import { ReactComponent as ExpandIcon } from 'assets/icons/expand_chevrons.svg'
import { ReactComponent as FlagIcon } from 'assets/icons/campaigns-flag.svg'
import { ReactComponent as MessageIcon } from 'assets/icons/message-bubble.svg'
import { ReactComponent as PeopleIcon } from 'assets/icons/contacts-people.svg'
import { ReactComponent as PersonIcon } from 'assets/icons/contacts-person.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/create-menu-icon.svg'

enum Popovers {
	CreateMenu = 'CreateMenu',
	NewCampaign = 'NewCampaign',
}

const CreateMenuButton = () => {
	const [isPopoverVisible, setIsPopoverVisible] = React.useState(false)
	const [popover, setPopover] = React.useState(Popovers.CreateMenu)
	const history = useHistory()

	const handleItemClick = (to: string) => {
		setIsPopoverVisible(false)
		history.push(to)
	}

	const renderPopover =
		popover === Popovers.CreateMenu ? (
			<CreateMenuPopover
				onNavigate={handleItemClick}
				onNewCampaign={() => setPopover(Popovers.NewCampaign)}
			/>
		) : null

	return (
		<Tippy
			arrow
			content={renderPopover}
			interactive
			placement="right-end"
			theme="light sk-theme"
			onClickOutside={() => setIsPopoverVisible(false)}
			visible={isPopoverVisible}
		>
			<CreateMenuButtonStyled
				border
				focus={isPopoverVisible}
				onClick={() => setIsPopoverVisible(true)}
			>
				<PlusIcon />
			</CreateMenuButtonStyled>
		</Tippy>
	)
}

interface CreateMenuPopoverProps {
	onNavigate: (to: string) => void
	onNewCampaign: () => void
}

export const CreateMenuPopover = ({
	onNavigate,
	onNewCampaign,
}: CreateMenuPopoverProps) => {
	return (
		<CreateMenuContainer>
			<CreateMenuItem
				onClick={() =>
					onNavigate(scenes.messages.url({ screen: 'primary', id: 'new' }))
				}
			>
				<CreateMenuItemButton>
					<MessageIcon />
					<CreateMenuItemTitle only>
						{translate('createMenu.newMessage')}
					</CreateMenuItemTitle>
				</CreateMenuItemButton>
			</CreateMenuItem>
			<CreateMenuItem>
				<CreateMenuItemButton
					onClick={() => onNavigate(scenes.campaignCreate.url)}
				>
					<FlagIcon />
					<CreateMenuItemTitle only>
						{translate('createMenu.newCampaign')}
					</CreateMenuItemTitle>
				</CreateMenuItemButton>
			</CreateMenuItem>
			<CreateMenuItem onClick={() => onNavigate(scenes.contacts.url('import'))}>
				<CreateMenuItemButton>
					<PeopleIcon />
					<CreateMenuItemTitle only>
						{translate('createMenu.importContacts')}
					</CreateMenuItemTitle>
				</CreateMenuItemButton>
			</CreateMenuItem>
			<CreateMenuItem onClick={() => onNavigate(scenes.contacts.url('add'))}>
				<CreateMenuItemButton>
					<PersonIcon />
					<CreateMenuItemTitle only>
						{translate('createMenu.newContact')}
					</CreateMenuItemTitle>
				</CreateMenuItemButton>
			</CreateMenuItem>
		</CreateMenuContainer>
	)
}

export default CreateMenuButton
