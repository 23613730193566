import React, { useEffect, useState } from 'react'
import { useAppState } from 'store'
import {
	MessageMeterItemContainer,
	MessageMeterGroup,
} from './MessageMeter.styled'
import { authActions } from 'store/slices/auth'
import { useAppDispatch } from 'store'
import { Text } from 'components/Text'
import moment from 'moment'
import { ReactComponent as ContactsIcon } from 'assets/icons/contacts-people.svg'
import { ReactComponent as MessageIcon } from 'assets/icons/message-bubble.svg'
import { AppHeaderDivider } from 'modules/App/AppHeader/styled'

export default function MessageMeter() {
	const dispatch = useAppDispatch()
	const { user } = useAppState(state => state.auth)
	const { subcription } = useAppState(state => state.subscription)

	const organization = user && user.organizations ? user.organizations[0] : null
	const contactsCount = organization ? organization.contactsCount : null

	useEffect(() => {
		const messagePlanLimit = subcription?.plan?.messageLimit || 0
		if (messagePlanLimit && organization && organization.messagesCount) {
			if (organization.messagesCount > messagePlanLimit) {
				dispatch(authActions.setIsExceeded(true))
			} else {
				dispatch(authActions.setIsExceeded(false))
			}
		}
	}, [organization, user, dispatch, subcription])

	return (
		<MessageMeterGroup>
			<>
				<MessageMeterItemContainer>
					<ContactsIcon />
					<Text color="fontSecondary" weight="bold" preset="tiny">
						{contactsCount ? contactsCount.toString() : '0'}
					</Text>
				</MessageMeterItemContainer>
				<AppHeaderDivider type="vertical" />
			</>
			<MessageMeterItemContainer>
				<MessageIcon />
				<Text color="fontSecondary" weight="600" preset="tiny">
					<strong>
						{organization && organization.messagesCount
							? organization.messagesCount.toString()
							: '0'}
					</strong>{' '}
					in {moment().format('MMM')}
				</Text>
				<span> </span>
				<Text color="fontSecondary" preset="tiny"></Text>
			</MessageMeterItemContainer>
		</MessageMeterGroup>
	)
}
