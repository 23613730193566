import i18n, { TranslateOptions } from 'i18n-js';
import memoize from 'lodash/memoize';

enum Locales {
	EN = 'en',
}

i18n.translations = {
	[Locales.EN]: require('./locales/en.json'),
};

// TODO: identify user lang
i18n.locale = 'en';

export const translate = memoize(
	(key: string, config?: TranslateOptions) => i18n.t(key, config),
	(key: string, config?: TranslateOptions) => (config ? key + JSON.stringify(config) : key),
);
