import merge from 'lodash/merge'
import { THEME } from './enums'

const COLORS = [
	'black',
	'white',
	'fontMain',
	'fontMainInput',
	'fontMainInputDisabled',
	'fontSecondary',
	'fontTertiary',
	'fontPlaceholder',
	'fontScondaryPlaceholder',
	'border',
	'borderInput',
	'borderInputDisabled',
	'borderInputMessage',
	'borderInputSolid',
	'borderDark',
	'accentPrimary',
	'accentPrimaryHover',
	'accentPrimaryPressDown',
	'accentPrimaryOpacity',
	'accentText',
	'accentBackground',
	'backgroundMain',
	'backgroundInputDisabled',
	'activeDotColor',
	'icon',
	'focus',
	'success',
	'error',
	'errorInputBackground',
	'errorPrimary',
	'errorBackground',
	'warning',
	'warningPrimary',
	'warningBackground',
	'cancelationBackground',
	'disabled',
	'danger',
	'dangerHover',
	'dangerClick',
	'borderInput',
	'solidGreen',
	'placeholderInput',
	'bubbleSender',
	'bubbleCampaign',
	'bubbleBackground',
	'bubbleText',
	'placeHolder',
	'borderSelect',
	'greyMain',
	'Blackcurrant',
	'activeDangerBackground',
	'Zircon',
	'Manatee',
	'Quartz',
	'Nero',
	'WhiteLilac',
	'Comet',
] as const
export type Colors = typeof COLORS[number]
export function isColor(key: string): key is Colors {
	return COLORS.includes(key as Colors)
}

const colors: Record<Colors, string> = {
	black: 'black',
	white: 'white',
	fontMain: '#231f20',
	fontMainInput: '#231F20',
	fontMainInputDisabled: '#BDBBBC',
	fontSecondary: '#9c9a9b',
	fontTertiary: '#231F20',
	fontPlaceholder: '#9c9a9b',
	fontScondaryPlaceholder: '#9c9a9b',
	border: '#f0eff0',
	borderInput: '#BDBBBC',
	borderInputDisabled: '#f0eff0',
	borderInputSolid: '#9C9A9B',
	borderInputMessage: '#e0e0e0',
	borderDark: '#DBDADB',
	accentPrimary: '#00A79D',
	accentPrimaryHover: '#008A82',
	accentPrimaryPressDown: '#007B74',
	accentPrimaryOpacity: '#BAE7E4',
	accentText: '#00A79D',
	accentBackground: '#F4FAFA',
	backgroundMain: '#F8F8F8',
	backgroundInputDisabled: '#F8F8F8',
	activeDotColor: '#39B54B',
	icon: '#BDBBBC',
	focus: '#00A79D',
	success: '#8EC53D',
	error: '#EF3B39',
	errorInputBackground: '#ffe2e2',
	errorPrimary: '#ef3b39',
	errorBackground: '#fae4e4',
	warning: '#ffcb00',
	warningPrimary: '#ffcf33',
	warningBackground: '#fff0c1',
	cancelationBackground: '#FFF9E8',
	disabled: '#f0eff0',
	danger: '#FFDFDF',
	dangerHover: '#EF3B39',
	dangerClick: '#CF2C2B',
	placeholderInput: '#9c9a9b',
	bubbleSender: '#E8F6F6',
	bubbleCampaign: '#E9E9EB',
	solidGreen: '#f8f8f8',
	bubbleBackground: '#e8f4d8',
	bubbleText: '#8dc63f',
	placeHolder: '#EFEFF1',
	borderSelect: '#CFD0D6',
	greyMain: '#1B1D21',
	Blackcurrant: '#808191',
	activeDangerBackground: '#FDE6E6',
	Zircon: '#DFE0E3',
	Manatee: '#90919F',
	Quartz: '#E6EFF8',
	Nero: '#1E1E1E',
	WhiteLilac: '#F7F7F8',
	Comet: '#60606D',
}

//Type and Const List for Fonts
export const FONTS = [
	'secondary',
	'main',
	'calpsLightFamily',
	'calpsBold',
	'zoojaRegular',
	'Calps',
] as const
export type Font = typeof FONTS[number]

//Fonts for Text Properties
export const fontsMapped: Record<Font, string> = {
	secondary: 'Source Sans Pro',
	main: 'Source Sans Pro',
	calpsLightFamily: 'calpslight',
	calpsBold: 'calpsbold',
	zoojaRegular: 'zoojaregular',
	Calps: 'Calps',
}

export const fonts = {
	main: 'Source Sans Pro',
	rounded: 'SF Pro Rounded',
	calpsBold: 'calpsbold',
	calpsLightFamily: 'calpslight',
}

export const baseTheme = {
	colors,
	fonts,
}

const lightTheme = { ...baseTheme }

const darkTheme = merge({}, baseTheme, {
	// TODO: implement dark theme
})

export const themes = {
	[THEME.Light]: lightTheme,
	[THEME.Dark]: darkTheme,
}

export interface IBaseTheme extends Readonly<typeof baseTheme> {}

declare module 'styled-components' {
	export interface DefaultTheme extends IBaseTheme {}
}
