import React from 'react'
import {
	AutomatedMessagesItemContainer,
	AutomatedMessagesContent,
	AutomatedMessagesLogo,
	AutomatedMessagesItemTitle,
	AutomatedMessagesItemSubTitle,
	AutomatedMessagesApproved,
	AutomatedMessagesApprovedTag,
	AutomatedMessagesLastModified,
	AutomatedMessagesTime,
	AutomatedMessagesEditButton,
	AutomatedMessagesEditIconSvg,
	AutomatedMessagesInnerButton,
	AutomatedMessagesActive,
	AutomatedMessagesActiveDot,
	EditButtonContainer,
} from './AutomatedMessages.styled'
import expandicon from 'assets/icons/expand-icon.svg'
import moment from 'moment'

type AutomatedMessagesItemProps = {
	title: string
	description: string
	lastModified: string | null
	active?: boolean
	icon: string
	onClick?: () => void
}

const AutomatedMessagesItem = ({
	icon,
	title,
	description,
	lastModified,
	active,
	onClick,
}: AutomatedMessagesItemProps) => {
	return (
		<AutomatedMessagesItemContainer onClick={onClick}>
			<AutomatedMessagesContent>
				<AutomatedMessagesInnerButton
					onClick={onClick}
					fullWidth={true}
					mainIcon={true}
					size="xlarge"
					children={
						<>
							<AutomatedMessagesLogo src={icon} />
							<div>
								<AutomatedMessagesItemTitle preset="h3">
									{title}
								</AutomatedMessagesItemTitle>
								<AutomatedMessagesItemSubTitle>
									{description}
								</AutomatedMessagesItemSubTitle>
								<AutomatedMessagesApproved>
									<AutomatedMessagesApprovedTag>
										FINRA REVIEWED
									</AutomatedMessagesApprovedTag>
									<AutomatedMessagesLastModified>
										{lastModified ? (
											<>
												Last Modified{' '}
												<strong>
													{moment(lastModified).format('MMM Do')}{' '}
												</strong>{' '}
												at{' '}
												<AutomatedMessagesTime>
													{moment(lastModified)
														.format('h:mm a')
														.toLocaleUpperCase()}
												</AutomatedMessagesTime>
											</>
										) : (
											'Not modified yet'
										)}
									</AutomatedMessagesLastModified>
									{active !== undefined && (
										<AutomatedMessagesActive $active={active}>
											<>
												<AutomatedMessagesActiveDot $active={active} />
												<strong>{active ? 'Active' : 'Inactive'}</strong>
											</>
										</AutomatedMessagesActive>
									)}
								</AutomatedMessagesApproved>
							</div>
						</>
					}
				/>
			</AutomatedMessagesContent>
			<EditButtonContainer>
				<AutomatedMessagesEditButton
					preset="secondary"
					type="default"
					size="large"
					LeftIcon={() => <AutomatedMessagesEditIconSvg src={expandicon} />}
					text="Edit"
					onClick={onClick}
				/>
			</EditButtonContainer>
		</AutomatedMessagesItemContainer>
	)
}

export default AutomatedMessagesItem
