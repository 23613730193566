import {
	BASE_URL,
	BASE_GIPHY_API_URL,
	BASE_GIPHY_API_VERSION,
	BASE_GIPHY_API_API_KEY,
	ENVIRONMENT,
	MESSAGES_POLL_INTERVAL,
	BASE_CHARGEBEE_SITE,
	BASE_CHARGEBEE_KEY,
} from '@constants/envs'

export const isProductionBuild = process.env.NODE_ENV === 'production'

export const CACHE_VERSION = '1'
export const PERSIST_KEY = `conversations-${ENVIRONMENT}`
export const PERSIST_TIMEOUT = 9000000000000000
export const PERSIST_STORAGE_KEY = `${CACHE_VERSION}${PERSIST_KEY}`

export const API_URL = `${BASE_URL}`

export const CHARGEBEE_SITE = `${BASE_CHARGEBEE_SITE}`
export const CHARGEBEE_KEY = `${BASE_CHARGEBEE_KEY}`

export const GIPHY_API_URL = `${BASE_GIPHY_API_URL}`
export const GIPHY_API_VERSION = `${BASE_GIPHY_API_VERSION}`
export const GIPHY_API_API_KEY = `${BASE_GIPHY_API_API_KEY}`

export const MESSAGES_POLL_INTERVAL_MS = Number(MESSAGES_POLL_INTERVAL) ?? 7000
