import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import createAsyncReducers from 'utils/create-async-reducers'

import { Widget } from 'types/api/widget.model'

import { getWidgets, createWidget, updateWidget } from './widget.actions'

interface WidgetState {
	loading: boolean
	listWidgets: Widget[] | null
	isActionCollapsed: boolean
}

const initialState: WidgetState = {
	loading: false,
	listWidgets: null,
	isActionCollapsed: false,
}

const { reducer, actions } = createSlice({
	name: 'widget',
	initialState,
	reducers: {
		updateActionCollapsed: (state, action: PayloadAction<boolean>) => {
			state.isActionCollapsed = action.payload
		},
	},
	extraReducers: builder => {
		createAsyncReducers(builder, getWidgets, 'loading', (state, action) => {
			state.listWidgets = action.payload
		})
		createAsyncReducers(builder, createWidget, 'loading')
		createAsyncReducers(builder, updateWidget, 'loading')
	},
})

export default reducer

export const widgetActions = {
	...actions,
	getWidgets,
	createWidget,
	updateWidget,
}
