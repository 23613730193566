import api from 'services/api'
import { gql } from '@apollo/client'
import {
	UpdateOnboardingStageInputType,
	OnboardingStageResponseType,
} from './onboarding.types'
import { ONBOARDING_STAGES } from 'services/gpl/onboarding-stages'

export class OnboardingService {
	private GET_ONBOARDING_STAGES = gql`
		query getOnboardingStages{
			getOnboardingStages{
				${ONBOARDING_STAGES}
			}
		}
	`

	private UPDATE_ONBOARDING_STAGE = gql`
		mutation updateOnboardingStages($input: UpdateOnboardingStagesInput!) {
			updateOnboardingStages(input: $input) {
				${ONBOARDING_STAGES}
			}
		}
	`

	getOnboardingStages = async (fields: {}): Promise<OnboardingStageResponseType> => {
		return await api.client
			.query({
				query: this.GET_ONBOARDING_STAGES,
				variables: { ...fields },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}
				return response && response.data && response.data.getOnboardingStages
			})
	}

	updateOnboardingStage = async (
		input: UpdateOnboardingStageInputType
	): Promise<OnboardingStageResponseType> => {
		return await api.client
			.mutate<
				{ updateOnboardingStages: OnboardingStageResponseType },
				{ input: UpdateOnboardingStageInputType }
			>({
				mutation: this.UPDATE_ONBOARDING_STAGE,
				variables: { input },
			})
			.then(response => {
				if (!response || !response.data) {
					throw new Error()
				}

				return response.data.updateOnboardingStages
			})
	}
}

export default new OnboardingService()
