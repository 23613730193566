import React from 'react'
// Modules
import { translate } from 'i18n'
import { TextPresets, textPresets } from './Text.presets'
import { Colors, Font } from 'modules/Theme/styled'

export interface TextProps extends React.HTMLAttributes<HTMLDivElement> {
	text?: string
	tx?: string
	children?: React.ReactNode
	preset?: TextPresets
	align?: 'center' | 'right' | 'left'
	weight?:
		| '200'
		| '300'
		| '400'
		| '500'
		| '600'
		| '700'
		| '900'
		| 'normal'
		| 'bold'
	className?: string
	color?: Colors | string
	gradient?: boolean
	italic?: boolean
	inline?: boolean
	textTransform?: 'capitalize' | 'uppercase' | 'lowercase'
	fontFamily?: Font | string
}

export const Text = ({
	tx,
	text = '',
	children,
	preset = 'h6',
	align = 'left',
	weight = 'normal',
	className,
	color = 'fontMain',
	gradient,
	italic,
	inline,
	textTransform,
	fontFamily = 'main',
	...rest
}: TextProps) => {
	const content = children ? children : tx ? translate(tx) : text
	const Text = textPresets[preset]
	return (
		<Text
			className={className}
			align={align}
			weight={weight}
			fontFamily={fontFamily}
			color={color}
			gradient={gradient}
			italic={italic}
			inline={inline}
			textTransform={textTransform}
			{...rest}
		>
			{content}
		</Text>
	)
}
