export const NOTE_CONTACT = `
    id
    note
    createdAt
`
export const TAG_CONTACT = `
    id
    name
    numContacts
    organization {
        id
        name
    }
    isAdminDerived
`
export const GROUP_CONTACT = `
    id
    name
    numContacts
    organization {
        id
        name
    }
    isAdminDerived
`

export const CONTACT = `
    id
    email
    firstName
    lastName
    phone
    authToken
    updatedAt
    createdAt
    status
    lastMessage {
        id
        message
        createdAt
    }
    organization {
        id
        tags {
            id
            name
        }
        contactGroups {
            id
            name
        }
    }
    optOutDate
    conversationCount
    notes {${NOTE_CONTACT}}
    tags {${TAG_CONTACT}}
    contactGroups {${GROUP_CONTACT}}
    conversation {
        id
        contact{
            firstName
        }
        lastMessageCreatedAt
    }
`
