import React from 'react'
import Icon from '@ant-design/icons'

interface CustomIconComponentProps {
	size: string | number
	width: string | number
	height: string | number
	fill: string
	viewBox?: string
	className?: string
	style?: React.CSSProperties
}

const ConvosLogoSVG = () => (
	<svg
		width="26"
		height="26"
		viewBox="0 0 26 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.65697 2.03065C5.82075 3.11802 2.86554 6.23197 1.91309 10.1052C1.09294 13.4335 1.73848 16.6003 3.32853 19.1111L2.00834 23.4447C1.93162 23.9262 2.33905 24.3469 2.82321 24.2834L7.05363 22.778C9.55114 24.2992 12.6757 24.8919 15.9484 24.0744C19.3534 23.2225 23.0017 19.7355 23.9621 16.3596C26.4967 7.46485 18.5491 -0.490677 9.65433 2.03065H9.65697Z"
			fill="#B0B0BA"
		/>
		<path
			d="M13.0011 25.4977C10.8793 25.4977 8.80507 24.9712 6.94517 23.9394L3.06925 25.3178L2.95814 25.3337C2.39197 25.4078 1.84167 25.2146 1.44482 24.8046C1.04797 24.3945 0.870704 23.8389 0.963302 23.2754L0.995049 23.1352L2.17766 19.254C0.54264 16.4337 0.0822919 13.1134 0.886576 9.85124C1.93691 5.58378 5.18843 2.19732 9.36859 1.01206C13.9191 -0.279027 18.5835 0.900938 21.8456 4.16569C25.1077 7.43045 26.2797 12.0974 24.9807 16.6506C23.9224 20.3651 19.9857 24.1564 16.205 25.1009C15.1388 25.3681 14.0647 25.5004 13.0037 25.5004L13.0011 25.4977ZM7.17269 21.6112L7.60394 21.8732C10.0009 23.3336 12.8741 23.7516 15.6917 23.0452C18.7634 22.278 22.0863 19.082 22.9462 16.0686C24.0309 12.2667 23.0599 8.37496 20.3481 5.6605C17.6363 2.94605 13.7498 1.96979 9.94535 3.04658C6.49276 4.02548 3.81004 6.82724 2.93962 10.3566C2.23587 13.2192 2.69092 16.1268 4.22012 18.5423L4.4794 18.9523L3.24387 23.0082L7.17269 21.6086V21.6112Z"
			fill="#1B1D21"
		/>
		<path
			d="M7.58806 14.6875C8.48667 14.6875 9.21514 13.9591 9.21514 13.0604C9.21514 12.1618 8.48667 11.4333 7.58806 11.4333C6.68944 11.4333 5.96097 12.1618 5.96097 13.0604C5.96097 13.9591 6.68944 14.6875 7.58806 14.6875Z"
			fill="#1B1D21"
		/>
		<path
			d="M13.144 14.6875C14.0426 14.6875 14.7711 13.9591 14.7711 13.0604C14.7711 12.1618 14.0426 11.4333 13.144 11.4333C12.2453 11.4333 11.5169 12.1618 11.5169 13.0604C11.5169 13.9591 12.2453 14.6875 13.144 14.6875Z"
			fill="#1B1D21"
		/>
		<path
			d="M18.6999 14.6875C19.5985 14.6875 20.327 13.9591 20.327 13.0604C20.327 12.1618 19.5985 11.4333 18.6999 11.4333C17.8013 11.4333 17.0728 12.1618 17.0728 13.0604C17.0728 13.9591 17.8013 14.6875 18.6999 14.6875Z"
			fill="#1B1D21"
		/>
	</svg>
)

const ConvosLogo = ({ size, ...props }: Partial<CustomIconComponentProps>) => (
	<Icon
		component={ConvosLogoSVG}
		{...props}
		width={size || props.width}
		height={size || props.height}
	/>
)

export default ConvosLogo
