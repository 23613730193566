import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import SVG from 'react-inlinesvg'
import SearchIcon from 'assets/icons/search.svg'
import ConvDash from 'assets/icons/convos-dashboard.svg'
import MenuMarketing from 'assets/icons/menu-marketing.svg'
import MenuMarketingInactive from 'assets/icons/menu-marketing-inactive.svg'
import CampDash from 'assets/icons/campaigns-dashboard.svg'
import WebDash from 'assets/icons/website-dashboard.svg'
import MarketDash from 'assets/icons/marketing-dashboard.svg'
import CampDashRed from 'assets/icons/campaigns-dashboard-red.svg'
import WebDashRed from 'assets/icons/website-dashboard-red.svg'
import MarketDashRed from 'assets/icons/marketing-dashboard-red.svg'
import toast from 'cogo-toast'
import { useHistory } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import { authActions } from 'store/slices/auth'
import {
	Container,
	AppHeaderSearchBarContainer,
	AppHeaderSearchBarInput,
	AppHeaderSearchBarMessagesSelect,
	AppHeaderSearchBarMessagesSelectDescription,
	AppHeaderSearchBarMessagesSelectOption,
	AppHeaderSearchBarMessagesSelectOptionContact,
	AppHeaderSearchBarMessagesSelectOptionSeparator,
	AppHeaderSearchBarMessagesSelectOptionMessage,
	AppHeaderUserMenuContainer,
	AppHeaderUserContainer,
	AppHeaderUserDropDown,
	AppHeaderUserDropDownList,
	AppHeaderDivider,
	AppHeaderUserMenuAvatar,
	MenuSvg,
	AvatarRow,
	MenuDivider,
	InfoContainer,
	UserName,
	UserEmail,
	UserImage,
	SettingsLink,
	FormControlCopy,
	OrgContainer,
	MainOrgName,
	ParentOrgName,
	Billing,
	Optin,
	MenuFooter,
	Signout,
	Privacy,
	MenuRow,
	ConvosContainer,
	ConvosText,
	DashboardMenuSvg,
	DashboardContainer,
	DashboardRow,
	ConvosTextSimple,
} from './styled'
import { useAppDispatch, useAppState } from '../../../store'
import { User } from 'types/api/user.model'
import useOutsideClick from 'hooks/useOutsideClick'
import { messagesActions } from 'store/slices/messages'
import { campaignActions } from 'store/slices/campaign'
import { Message } from 'types/api/message.model'
import MessageMeter from 'components/MessageMeter/MessageMeter'
import messagesService from 'services/messages'
import { Campaign } from 'types/api/campaign.model'
import campaignService from 'services/campaign/campaign.service'
import { formatUSNumber } from 'utils/format-number'
import DashLogo from '../DashLogo'
import ArrowLogo from '../ArrowLogo'
import { SK_AUTH_URL } from '@constants/envs'
import * as CONSTANTS from '@constants/envs'
import { useAppContext } from '../AppContext'
import { API_URL } from '@constants'
import { SingAvatarBox } from 'scenes/SignUp/SignUp.styled'

interface IProps {
	currentUser?: User | null
	expanded: boolean
}

const { CAMPAIGN_URL, WEBSITE_URL, MARKETING_URL } = CONSTANTS

export default memo(({ currentUser, expanded }: IProps) => {
	const dispatch = useAppDispatch()
	const history = useHistory()

	const {
		optinFlowOutboundMessages,
		optinFlowOrgInboundMessages,
	} = useAppState(state => state.campaign)

	// Conversation fetching parameters
	const CONVERSATION_BOX_HEIGHT = 54 // px
	const INBOX_CONVERSATIONS_MINIMUM = Math.ceil(
		window.innerHeight / CONVERSATION_BOX_HEIGHT
	)
	// fetch conversations initially in the past # weeks
	const CONVOS_MIN_FETCH_INTERVAL = 3
	// continue fetching additional conversations in # weeks, if number of initial convos fetched is below minimum
	const CONVOS_ADDITIONAL_FETCH_INCREMENT = 2

	const { inboxConversations, messagesList, loadingMessagesList } = useAppState(
		state => state.messages
	)

	const { user } = useAppState(state => state.auth)
	const { isUnifiedDashboard, refreshFeatureFlags } = useAppContext()
	const [name, setName] = useState<string>('')
	const [optinMessages, setOptinMessages] = useState<boolean>(false)
	const [searchListMessage, setSearchListMessage] = useState<Message[]>([])
	const [countUnReadMessage, setCountUnReadMessage] = useState<number>(0)
	const [initialMessagesFetch, setInitialMessagesFetch] = useState<boolean>(
		false
	)
	const [isCompaignHover, setIsCompaignHover] = useState(false)
	const [isWebsiteHover, setIsWebsiteHover] = useState(false)
	const [isMarketHover, setIsMarketHover] = useState(false)
	const [isMenuHover, setIsMenuHover] = useState(false)

	const ref = useRef<HTMLDivElement>(null)
	const { open, handleClose, handleOpen } = useOutsideClick(ref)

	const refDashboard = useRef<HTMLDivElement>(null)
	const {
		open: openDashMenu,
		handleClose: handleDashMenuClose,
		handleOpen: handleDashMenuOpen,
	} = useOutsideClick(refDashboard)

	const searchMessageRef = useRef<HTMLDivElement>(null)
	const userRole = user?.userRoles[0]?.role.name
	const isOrgOwner = userRole === 'organization_owner'
	const shouldShowMarketingHubDropdown = user?.shouldShowMarketingHubDropdown
	const canAccessHub = user?.canAccessMarketingHub
	const isMembershipActive = user?.isMembershipActive
	const isWebsitesActive = user?.isWebsitesActive
	const isMarketingHubLogin = user?.isMarketingHubLogin

	const userLogo =
		user &&
		user.organizations &&
		user.organizations[0] &&
		user?.organizations?.[0]?.logo

	const {
		open: outsideSearchMessage,
		handleClose: handleSearchMessageClose,
		handleOpen: handleSearchMessageOpen,
	} = useOutsideClick(searchMessageRef)

	useEffect(() => {
		if (!optinMessages) {
			setOptinMessages(true)
			dispatch(campaignActions.getCamapignOptinFlowOutboundMessages())
		}
	}, [dispatch, optinFlowOutboundMessages, optinMessages])

	useEffect(() => {
		if (!optinMessages) {
			setOptinMessages(true)
			dispatch(campaignActions.getCamapignOptinFlowInboundMessages())
		}
	}, [dispatch, optinFlowOrgInboundMessages, optinMessages])

	const fetchInitialConversations = useCallback(
		(startDate: Date, endDate: Date) => {
			dispatch(
				messagesActions.fetchConversations({
					startDate,
					endDate: endDate,
					limit: 10,
					offset: 0,
				})
			).catch(err => toast.error(err.message))
		},
		[dispatch]
	)

	useEffect(() => {
		if (!initialMessagesFetch && user) {
			setInitialMessagesFetch(true)
			const endDate = new Date()
			const startDate = moment(endDate)
				.subtract(CONVOS_MIN_FETCH_INTERVAL, 'days')
				.toDate()

			fetchInitialConversations(startDate, endDate)
		}
	}, [dispatch, fetchInitialConversations, initialMessagesFetch, user])

	useEffect(() => {
		const next = (data: { message: Message }) => {
			dispatch(messagesActions.setPolledMessage(data.message))
			data && data.message.outbound && dispatch(authActions.getMe())
		}
		const pollingConversations = messagesService.pollContactMessage(next)
		return () => {
			pollingConversations.unsubscribe()
		}
	}, [dispatch, inboxConversations])

	useEffect(() => {
		const next = (data: { campaign: Campaign }) => {
			toast.success(`Campaign ${data.campaign.name} sent successfully`, {
				position: 'top-right',
			})
			dispatch(campaignActions.setPolledCampaign(data.campaign))
		}
		const pollingCampaign = campaignService.pollCampaign(next)
		return () => {
			pollingCampaign.unsubscribe()
		}
	}, [dispatch, inboxConversations])

	useEffect(() => {
		let _countUnReadMessage = 0
		inboxConversations &&
			inboxConversations.forEach(messages => {
				const _unReadMessage =
					messages.messages?.filter(message => !(message && message.read)) || []
				_countUnReadMessage += _unReadMessage.length
			})
		setCountUnReadMessage(_countUnReadMessage)
	}, [inboxConversations])

	useEffect(() => {
		if (user && user.organizations && user.organizations[0]) {
			dispatch(
				campaignActions.setEnabledOptinFlowCampaign(
					user.organizations[0].optinFlowEnabled
				)
			)
		}
	}, [user, dispatch])

	const deleteCookies = async () => {
		try {
			const response = await fetch(`${API_URL}/auth/logout`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			})

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`)
			}

			// Parse the response JSON if needed
			const responseData = await response.json()

			// Handle the response data here
			console.log('Response:', responseData)
		} catch (error) {
			console.error('Error making POST request:', error)
		}
	}

	const handleLogOut = async () => {
		handleClose()
		await deleteCookies()
		dispatch({ type: 'LOG_OUT' })
		await refreshFeatureFlags()
		if ((isUnifiedDashboard || isMarketingHubLogin) && canAccessHub) {
			window.location.replace(`${SK_AUTH_URL}/logout`)
		}
	}

	const { firstName = '', lastName = '' } = currentUser || {}
	const initials =
		firstName && lastName
			? [firstName, lastName].map(n => n.slice(0, 1)).join('')
			: 'NN'

	const initialsOrganization =
		user &&
		user.organizations &&
		user.organizations[0] &&
		user.organizations[0].name
			? user.organizations[0].name.split(' ').length >= 2
				? `${user.organizations[0].name
						.split(' ')[0]
						.substr(0, 1)}${user.organizations[0].name
						.split(' ')[1]
						.substr(0, 1)}`
				: `${user.organizations[0].name.split(' ')[0].substr(0, 2)}`
			: ''

	useEffect(() => {
		setSearchListMessage(messagesList)
	}, [messagesList])

	const handleSearchListMessage = (
		evt: React.ChangeEvent<HTMLInputElement>
	) => {
		const search = evt.target.value

		if (search !== '') {
			const delayDebounceFn = setTimeout(() => {
				dispatch(
					messagesActions.searchListMessage({
						query: search,
						limit: 10,
						offset: 0,
					})
				)
			}, 600)

			return () => clearTimeout(delayDebounceFn)
		} else {
			setSearchListMessage([])
		}
	}

	const handleSelectedMessage = (id: string) => {
		if (id) {
			dispatch(messagesActions.setDefaultChatId(id))
			history.push(`/messages/primary/${id}`)
			handleSearchMessageClose()
		}
	}

	const navigateToSettings = () => {
		history.push('/settings')
	}

	const handleOptin = () => {
		history.push('/settings/optIn')
	}

	const handleBilling = () => {
		history.push('/settings/billing')
		setTimeout(() => {
			window.scrollTo(0, document.body.scrollHeight)
		}, 500)
	}

	const handlePrivacy = () => {
		var url = 'https://snappykraken.com/privacy-policy'
		window.open(url, '_blank')
	}

	return (
		<Container expanded={expanded}>
			<MessageMeter />
			<AppHeaderSearchBarContainer ref={searchMessageRef}>
				<AppHeaderSearchBarInput
					LeftIcon={() => {
						return <SVG src={SearchIcon} />
					}}
					RightIcon={loadingMessagesList ? LoadingOutlined : ''}
					onChange={handleSearchListMessage}
					onFocus={handleSearchMessageOpen}
					placeholder="Search Convos"
					focusPlaceholder="Type what you want to search for"
					centered
				/>
				{outsideSearchMessage && (
					<AppHeaderSearchBarMessagesSelect>
						<AppHeaderSearchBarMessagesSelectDescription
							style={{
								marginBottom:
									searchListMessage && searchListMessage.length > 0
										? '12px'
										: 0,
							}}
						>
							{searchListMessage && searchListMessage.length > 0 ? (
								<>
									You’re searching in <strong>conversations & messages</strong>
									...
								</>
							) : (
								<>
									Continue typing to search your{' '}
									<strong>conversations & messages</strong>...
								</>
							)}
						</AppHeaderSearchBarMessagesSelectDescription>
						{searchListMessage &&
							searchListMessage.length > 0 &&
							searchListMessage.map((message, index) => (
								<AppHeaderSearchBarMessagesSelectOption
									key={index}
									onClick={() =>
										handleSelectedMessage(message.conversationId || '')
									}
								>
									<SVG src={SearchIcon} />
									{message.message && (
										<AppHeaderSearchBarMessagesSelectOptionMessage
											text={message.message.split(' ').splice(0, 3).join(' ')}
										/>
									)}
									<AppHeaderSearchBarMessagesSelectOptionSeparator>
										in
									</AppHeaderSearchBarMessagesSelectOptionSeparator>
									<AppHeaderSearchBarMessagesSelectOptionContact>
										<strong>
											{message.contact &&
												`${message.contact.firstName} ${message.contact.lastName} `}
										</strong>

										{message.contact &&
											message.contact.phone &&
											` ${formatUSNumber(message.contact.phone)}`}
									</AppHeaderSearchBarMessagesSelectOptionContact>
								</AppHeaderSearchBarMessagesSelectOption>
							))}
					</AppHeaderSearchBarMessagesSelect>
				)}
			</AppHeaderSearchBarContainer>

			<MenuRow>
				{(isUnifiedDashboard || isMarketingHubLogin) &&
					isOrgOwner &&
					shouldShowMarketingHubDropdown && (
						<AppHeaderUserContainer>
							<AppHeaderDivider type="vertical" />
							<AppHeaderUserMenuContainer onClick={handleDashMenuOpen}>
								<MenuSvg
									onMouseEnter={() => {
										setIsMenuHover(true)
									}}
									onMouseLeave={() => {
										setIsMenuHover(false)
									}}
									src={
										isMenuHover || openDashMenu
											? MenuMarketing
											: MenuMarketingInactive
									}
									style={{ marginRight: 16 }}
								/>

								<AppHeaderUserDropDown
									ref={refDashboard}
									open={openDashMenu}
									style={{ marginRight: 150 }}
								>
									<AppHeaderUserDropDownList open={openDashMenu} width={360}>
										<ConvosContainer>
											<ConvosTextSimple text="You’re in" />
											<DashboardMenuSvg
												src={ConvDash}
												style={{ marginLeft: 10, marginRight: 8 }}
											/>
											<ConvosTextSimple
												text="Convos"
												style={{ fontWeight: '600' }}
											/>
										</ConvosContainer>

										<MenuDivider type="horizontal" />

										{canAccessHub && (
											<>
												<DashboardContainer
													hoverColor="#FDE6E6"
													onClick={() => window.open(MARKETING_URL, '_blank')}
													onMouseEnter={() => {
														setIsMarketHover(true)
													}}
													onMouseLeave={() => {
														setIsMarketHover(false)
													}}
												>
													<DashboardRow>
														<div
															style={{ marginTop: 5, width: 36, height: 36 }}
														>
															<DashLogo
																hover={isMarketHover}
																svgSrc={MarketDash}
																svgSrcColored={MarketDashRed}
															/>
														</div>
														<div>
															<ConvosText
																text="Marketing Hub"
																style={{
																	fontWeight: '700',
																	marginLeft: 9,
																	color: isMarketHover ? '#1B1D21' : '#60606D',
																}}
															/>
														</div>
													</DashboardRow>

													<div>
														<ArrowLogo hover={isMarketHover} />
													</div>
												</DashboardContainer>
												<MenuDivider type="horizontal" />
											</>
										)}

										{isMembershipActive && (
											<>
												<DashboardContainer
													hoverColor="#FDE6E6"
													onClick={() => window.open(CAMPAIGN_URL, '_blank')}
													onMouseEnter={() => {
														setIsCompaignHover(true)
													}}
													onMouseLeave={() => {
														setIsCompaignHover(false)
													}}
												>
													<DashboardRow>
														<div
															style={{ marginTop: 5, width: 36, height: 36 }}
														>
															<DashLogo
																hover={isCompaignHover}
																svgSrc={CampDash}
																svgSrcColored={CampDashRed}
															/>
														</div>
														<div>
															<ConvosText
																text="Campaigns"
																style={{
																	fontWeight: '700',
																	marginLeft: 9,
																	color: isCompaignHover
																		? '#1B1D21'
																		: '#60606D',
																}}
															/>
														</div>
													</DashboardRow>

													<div>
														<ArrowLogo hover={isCompaignHover} />
													</div>
												</DashboardContainer>
												<MenuDivider type="horizontal" />
											</>
										)}

										{isWebsitesActive && (
											<>
												<DashboardContainer
													hoverColor="#FFF9E5"
													onClick={() => window.open(WEBSITE_URL, '_blank')}
													onMouseEnter={() => {
														setIsWebsiteHover(true)
													}}
													onMouseLeave={() => {
														setIsWebsiteHover(false)
													}}
												>
													<DashboardRow>
														<div
															style={{ marginTop: 5, width: 36, height: 36 }}
														>
															<DashLogo
																hover={isWebsiteHover}
																svgSrc={WebDash}
																svgSrcColored={WebDashRed}
															/>
														</div>
														<div>
															<ConvosText
																text="Websites"
																style={{
																	fontWeight: '700',
																	marginLeft: 9,
																	color: isWebsiteHover ? '#1B1D21' : '#60606D',
																}}
															/>
														</div>
													</DashboardRow>

													<div>
														<ArrowLogo hover={isWebsiteHover} />
													</div>
												</DashboardContainer>
											</>
										)}
									</AppHeaderUserDropDownList>
								</AppHeaderUserDropDown>
							</AppHeaderUserMenuContainer>
						</AppHeaderUserContainer>
					)}
				<AppHeaderUserContainer>
					<AppHeaderDivider type="vertical" />

					<div
						style={{
							position: 'relative',
							width: 50,
							height: 34,
						}}
					>
						<AppHeaderUserMenuContainer
							onClick={handleOpen}
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								zIndex: 2,
							}}
						>
							<AppHeaderUserMenuAvatar>
								{currentUser && currentUser.photo ? (
									<img alt="user" src={currentUser.photo} />
								) : (
									<span>{initials}</span>
								)}
							</AppHeaderUserMenuAvatar>
							<AppHeaderUserDropDown ref={ref} open={open}>
								{/* <PolygonSvg src={polygonIcon} /> */}
								<AppHeaderUserDropDownList open={open} width={270}>
									<AvatarRow>
										<UserImage>
											{currentUser && currentUser.photo ? (
												<img alt="user" src={currentUser.photo} />
											) : (
												<span>{initials}</span>
											)}
										</UserImage>
										<InfoContainer>
											<UserName
												text={
													(currentUser?.firstName ?? '') +
													' ' +
													(currentUser?.lastName ?? '')
												}
											/>
											<UserEmail text={currentUser?.email} />
											<FormControlCopy onClick={navigateToSettings}>
												<SettingsLink>Settings</SettingsLink>
											</FormControlCopy>
										</InfoContainer>
									</AvatarRow>
									<MenuDivider type="horizontal" />
									<OrgContainer>
										{currentUser && currentUser.organizations && (
											<MainOrgName
												text={currentUser?.organizations[0]?.name || ''}
											/>
										)}
										{currentUser &&
											currentUser?.organizations &&
											!!currentUser.organizations[0]?.parentOrganization && (
												<ParentOrgName
													text={
														currentUser?.organizations[0]?.parentOrganization
															?.name || ''
													}
												/>
											)}
									</OrgContainer>
									<MenuDivider type="horizontal" />
									<OrgContainer>
										{/* <FormControlCopy onClick={handleBilling}>
										<Billing text="Account & Billing" />
									</FormControlCopy> */}
										<FormControlCopy onClick={handleOptin}>
											<Optin text="Opt-in Settings" />
										</FormControlCopy>
									</OrgContainer>
									<MenuDivider type="horizontal" />
									<MenuFooter>
										<FormControlCopy onClick={handleLogOut}>
											<Signout text="Sign out" />
										</FormControlCopy>
										<FormControlCopy onClick={handlePrivacy}>
											<Privacy text="Privacy policy" />
										</FormControlCopy>
									</MenuFooter>
								</AppHeaderUserDropDownList>
							</AppHeaderUserDropDown>
						</AppHeaderUserMenuContainer>
						<AppHeaderUserMenuAvatar
							style={{
								position: 'absolute',
								top: 0,
								left: 29,
								zIndex: 1,
							}}
						>
							{!!userLogo ? (
								<img alt="organization logo" src={userLogo || ''} />
							) : (
								<span>{initialsOrganization}</span>
							)}
						</AppHeaderUserMenuAvatar>
					</div>
				</AppHeaderUserContainer>
			</MenuRow>
		</Container>
	)
})
