import { gql } from '@apollo/client'
import ApiService from 'services/api'
import { GetConversationLinkMutation } from './conversation.types'
import { CONVERSATION_WITHOUT_LIMIT } from 'services/messages/messages.gpl'
import { Conversation } from 'types/api/message.model'

export class ConversationService {
	private GetConversationFromLink = gql`
		mutation getConversationFromLink($input: GetConversationFromLinkInput!) {
			getConversationFromLink(input: $input){
				isNew
				conversation {
					${CONVERSATION_WITHOUT_LIMIT}
				}
			}
		}
	`

	getConversationLink = async (
		fields: GetConversationLinkMutation
	): Promise<{
		isNew: boolean
		conversation: Conversation
	}> => {
		return await ApiService.client
			.mutate<
				{
					getConversationFromLink: {
						isNew: boolean
						conversation: Conversation
					}
				},
				GetConversationLinkMutation
			>({
				mutation: this.GetConversationFromLink,
				variables: fields,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data!.getConversationFromLink
			})
	}
}

export default new ConversationService()
