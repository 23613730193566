import styled from 'styled-components'

//COMPONENTS
import { Colors } from 'modules/Theme/styled'

export const PhoneNumberStatusHash = styled.div`
	align-items: center;
	border-radius: 50%;
	display: flex;
	flex-shrink: 0;
	font-size: 1.75rem;
	height: 2.125rem;
	justify-content: center;
	line-height: 1.75rem;
	margin-right: 0.5rem;
	width: 2.125rem;

	svg {
		height: 1rem;
		width: 1rem;
	}
`

export const PhoneNumberStatusContainer = styled.div<{
	background: Colors
	color: Colors
}>`
	border-top: 1px solid ${({ theme }) => theme.colors.border};
	display: flex;
	margin: 0 auto;
	padding: 1.25rem;
	padding-left: 1.875rem;
	width: 100%;

	${PhoneNumberStatusHash} {
		background-color: ${({ background, theme }) =>
			background ? theme.colors[background] : theme.colors.error};
		color: ${({ color, theme }) =>
			color ? theme.colors[color] : theme.colors.error};
	}
`

export const PhoneNumberStatusContent = styled.div``

export const PhoneNumberStatusText = styled.div`
	color: ${({ theme }) => theme.colors.fontMain};
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.125rem;
`
export const PhoneNumberStatusVerification = styled.div`
	color: ${({ theme }) => theme.colors.fontSecondary};
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: -0.02em;
	line-height: 0.875rem;
	margin-top: 1px;

	svg {
		margin-left: 0.125rem;
		vertical-align: middle;
	}
`
