import React, { useState } from 'react'
interface ArrowLogo {
	hover: boolean
}

const ArrowLogo: React.FC<ArrowLogo> = ({ hover }) => {
	return (
		<>
			<svg
				width="10"
				height="10"
				viewBox="0 0 10 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M2.45455 1H9M9 1V7.54545M9 1L2.45455 7.54545L1 9"
					stroke={hover ? 'black' : '#B0B0BA'}
					stroke-width="2"
					stroke-linecap="round"
				/>
			</svg>
		</>
	)
}

export default ArrowLogo
