import { PLAN } from '../plan/plan.gpl'
import { PAYMENT_METHOD_CHARGEBEE } from '../gpl/payment-method'

export const SUBSCRIPTION = `
  created
  plan {
  	${PLAN}
	}
  renews
  status
  paymentMethod {
  	${PAYMENT_METHOD_CHARGEBEE}
	}
`

export const SUBSCRIPTION_ESTIMATE = `
  baseCost
  taxCost
  taxRate
  totalCost
  renewalDate
`
