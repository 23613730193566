import api from 'services/api'
import { gql } from '@apollo/client'
import { WIDGET } from 'services/gpl/widget'
import { Widget } from 'types/api/widget.model'
import {
	CreateWidgetMethodMutation,
	UpdateWidgetMethodMutation,
} from './widget.types'

export class WidgetService {
	private GET_WIDGETS = gql`
		query ListWidgets {
			listWidgets {
				${WIDGET}
			}
		}	
	`

	getWidgets = async (): Promise<Widget[]> => {
		return api.client
			.query({
				query: this.GET_WIDGETS,
			})
			.then(res => {
				if (!res || !res.data) {
					throw new Error()
				}
				return res.data.listWidgets
			})
	}

	private CREATE_WIDGET = gql`
        mutation CreateWidget($input: CreateWidgetInput!) {
            createWidget(input: $input) {
                ${WIDGET}
            }
        }	
    `

	createWidgetMethod = async (
		field: CreateWidgetMethodMutation
	): Promise<Widget> => {
		return await api.client
			.mutate({
				mutation: this.CREATE_WIDGET,
				variables: { input: field },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.createWidget
			})
	}

	private UPDATE_WIDGET = gql`
        mutation UpdateWidget($input: UpdateWidgetInput!) {
            updateWidget(input: $input) {
                ${WIDGET}
            }
        }	
    `

	updateWidget = async (field: UpdateWidgetMethodMutation): Promise<Widget> => {
		return await api.client
			.mutate<{ updateWidget: Widget }, { input: UpdateWidgetMethodMutation }>({
				mutation: this.UPDATE_WIDGET,
				variables: { input: field },
			})
			.then(res => {
				if (!res || res.errors || !res.data) {
					throw new Error()
				}
				return res.data && res.data.updateWidget
			})
	}
}

export default new WidgetService()
