import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getUser, getUserByToken, updateQuestionaireStep } from './user.actions'
import createAsyncReducers from 'utils/create-async-reducers'
import { User } from 'types/api/user.model'
import { Organization } from 'types/api/organization.model'

export interface IState {
	user: User | null
	loading: boolean
}

const initialState: IState = {
	user: null,
	loading: false,
}

const { reducer, actions } = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateUserOrganization: (state, action: PayloadAction<Organization>) => {
			const user = state.user as User
			const updatedUser = { ...user, organization: action.payload }
			state.user = updatedUser
		},
	},
	extraReducers: builder => {
		createAsyncReducers(builder, getUserByToken, 'loading')
		createAsyncReducers(builder, getUser, 'loading', (state, action) => {
			state.user = action.payload
		})
		createAsyncReducers(
			builder,
			updateQuestionaireStep,
			'loading',
			(state, action) => {
				state.user = action.payload
			}
		)
	},
})

export default reducer
export const userActions = {
	...actions,
	getUserByToken,
	getUser,
	updateQuestionaireStep,
}
